import firebase from 'firebase';
import { useEffect, useState } from 'react';

import { CompanyData } from '../../features/MojaTablica/Account/Identity/LinkCompany/company.models';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

export const useGetCompanyData = (
  uid: string
): { companyData: CompanyData | null; loadingData: boolean; error: firebase.FirebaseError | null } => {
  const [companyData, setCompanyData] = useState<CompanyData | null>(null);
  const [loadingData, setLoadingData] = useState(true);
  const [error, setError] = useState<firebase.FirebaseError | null>(null);
  const [companyId, setCompanyId] = useState<string | null>(null);

  if (!uid) {
    return;
  }

  // get companyId from user.companyId
  useEffect(() => {
    const fetchCompanyId = async () => {
      try {
        const user = await firestore.collection(FirestoreCollection.USERS).doc(uid).get();
        setCompanyId(user.data()?.companyId);
      } catch (error: any | firebase.FirebaseError) {
        setError(error);
      }
    };

    fetchCompanyId();
  }, [uid]);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const companyData = await firestore.collection(FirestoreCollection.COMPANIES).doc(companyId).get();
        setCompanyData(companyData.data() as CompanyData);
      } catch (error: any | firebase.FirebaseError) {
        setError(error);
      } finally {
        setLoadingData(false);
      }
    };

    fetchCompanyData();
  }, [companyId]);

  return { companyData, loadingData, error };
};

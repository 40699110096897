import { Button, Form, Input } from 'antd';
import { Formik } from 'formik';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Typography } from '../../../components/Typography/Typography';
import { ForgotPasswordFormValues } from '../auth.model';
import { useAuth } from '../AuthContext';
import { AdditionalLink } from '../styles';

import { useOnSubmit } from './hooks/useOnSubmit.hool';
import { useValidation } from './hooks/useValidation.hook';

export const ForgotPassword: React.FC = () => {
  const validation = useValidation();
  const initialValues: ForgotPasswordFormValues = { email: '' };
  const onSubmit = useOnSubmit();
  const { t } = useTranslation();
  const { showSignInModal } = useAuth();

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validation} onSubmit={onSubmit}>
        {({ isSubmitting, isValid, handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
          <Form layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              label={t('AUTH.FORGOT_PASSWORD.INPUT_EMAIL_PLACEHOLDER')}
              validateStatus={touched.email && errors.email ? 'error' : ''}
              help={touched.email && errors.email ? errors.email : ''}>
              <Input
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t('AUTH.FORGOT_PASSWORD.INPUT_EMAIL_PLACEHOLDER')}
              />
            </Form.Item>

            <Form.Item>
              <Button block type="primary" htmlType="submit" disabled={isSubmitting || !isValid} loading={isSubmitting}>
                {t('AUTH.FORGOT_PASSWORD.BUTTON_SUBMIT')}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Formik>
      <AdditionalLink>
        <Trans
          className="text-blue-500"
          i18nKey="AUTH.FORGOT_PASSWORD.SIGN_IN"
          components={{
            link1: (
              <Typography.Anchor
                onClick={(e) => {
                  e.preventDefault();
                  showSignInModal();
                }}
              />
            ),
          }}
        />
      </AdditionalLink>
    </>
  );
};

// firebaseService.ts
import { firebaseFunctions, firebaseStorage, firestore } from '../../../../../firebase/firebase';
import { FirebaseCallableFunctions, FirestoreCollection } from '../../../../../firebase/firebase.models';

export const getDownloadUrl = async (uid: string, fileName: string): Promise<string> => {
  const downloadUrl = await firebaseStorage.ref(`/users/${uid}/${fileName}`).getDownloadURL();
  return downloadUrl;
};

export const fetchUserData = async (uid: string): Promise<any> => {
  const userDoc = firestore.collection(FirestoreCollection.USERS).doc(uid);
  const snapshot = await userDoc.get();
  return snapshot.data();
};

export const uploadFile = async (file: File, path: string): Promise<string> => {
  const imageRef = firebaseStorage.ref(path);
  const snapshot = await imageRef.put(file);
  return await snapshot.ref.getDownloadURL();
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const notifyAdmin = async (data: any): Promise<void> => {
  const onUploadSignature = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_UPLOAD_SIGNATURE);
  await onUploadSignature(data);
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next'; // Import the translation hook

import { SelectOption } from '../../../../models/main.model';

export function useSourrundingType(): SelectOption[] {
  const { t } = useTranslation(); // Add translation

  return useMemo(
    () => [
      { label: t('SURROUNDING.CITY_CENTER'), value: 'Zabudowa zwarta śródmiejska' },
      { label: t('SURROUNDING.NEIGHBORHOOD'), value: 'Zabudowa sąsiednia' },
      { label: t('SURROUNDING.SUBURBAN'), value: 'Zabudowa podmiejska' },
      { label: t('SURROUNDING.UNDEVELOPED'), value: 'Działki niezabudowane' },
      { label: t('SURROUNDING.CONSTRUCTION'), value: 'Budowa' },
      { label: t('SURROUNDING.FOREST'), value: 'Las' },
      { label: t('SURROUNDING.LAKE'), value: 'Jezioro, woda' },
      { label: t('SURROUNDING.SEA'), value: 'Morze' },
      { label: t('SURROUNDING.MOUNTAINS'), value: 'Góry' },
    ],
    [t]
  );
}

import { firebaseFunctions } from '../../../../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../../../../firebase/firebase.models';
import { Company, CompanyData } from '../company.models';

// Function to update company data and call the Firebase function
export async function onAddCompany(userId: string, company: Company): Promise<CompanyData | undefined> {
  const onAddCompany = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_ADD_COMPANY);
  try {
    company.nip = company.nip.toString();
    company.nazwa = company.nazwa.toUpperCase();
    company.ulica = company.ulica.toUpperCase();
    company.miejscowosc = company.miejscowosc.toUpperCase();
    company.kraj = company.kraj.toUpperCase();
    company.kodPocztowy = company.kodPocztowy.toUpperCase();
    company.nrDomu = company.nrDomu.toUpperCase();
    const resp = await onAddCompany({
      userId: userId,
      company: company,
    });
    return resp.data?.companyData;
  } catch (error: any) {
    throw new Error('Error calling cloud function to add company: ' + error.message);
  }
}

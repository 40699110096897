import { useMemo } from 'react';

import { SelectOption } from '../../../../models/main.model';

export function useProvinceOptions(): SelectOption[] {
  //   const { t } = useTranslation();
  return useMemo(
    () =>
      [
        'dolnośląskie',
        'kujawsko-pomorskie',
        'lubelskie',
        'lubuskie',
        'łódzkie',
        'małopolskie',
        'mazowieckie',
        'podkarpackie',
        'podlaskie',
        'pomorskie',
        'śląskie',
        'świętokrzyskie',
        'warmińsko-mazurskie',
        'wielkopolskie',
        'zachodniopomorskie',
      ].map((item) => ({
        label: item,
        value: item,
      })),
    []
  );
}

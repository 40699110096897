import { Alert, Space } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { LeaseAgreement } from '../../../models/offers.model';
import { useNonRentFees } from '../../OfferAdd/components/FormOptions/useNonRentFees.hook';

interface Props {
  leaseAgreement: LeaseAgreement;
}

export const InfoAboutNonRentFees: React.FC<Props> = ({ leaseAgreement }) => {
  const { t } = useTranslation();
  const nonRentFeeOptions = useNonRentFees(); // Get translated non-rent fees options

  // Function to find the translated label for nonRentFees
  const getNonRentFeeLabel = (nonRentFee: string) => {
    const option = nonRentFeeOptions.find((opt) => opt.value === nonRentFee);
    return option ? option.label : nonRentFee;
  };

  return (
    <Space direction="vertical" size={12} style={{ marginBottom: 16 }}>
      <Alert
        type="info"
        message={`${t('NON_RENT_FEES.INFO')}: ${getNonRentFeeLabel(leaseAgreement.nonRentFees)}${
          leaseAgreement.nonRentFees === 'Ryczałtowe' ? ` ${leaseAgreement.lumpSum} ${t('NON_RENT_FEES.PER_SQM')}` : ''
        }${
          leaseAgreement.nonRentFees === 'Licznikowe'
            ? leaseAgreement.averageMonthlyCostsAnnualizedKnown
              ? ` ${t('NON_RENT_FEES.AVERAGE_COSTS')}: ${leaseAgreement.averageMonthlyCostsAnnualized} PLN (${t(
                  'NON_RENT_FEES.GROSS'
                )})`
              : ` ${t('NON_RENT_FEES.UNKNOWN_ANNUAL_COSTS')}`
            : ''
        }`}
      />
    </Space>
  );
};

import firebase from 'firebase';

import { firebaseFunctions, firestore } from '../../firebase/firebase';
import { FirebaseCallableFunctions, FirestoreCollection } from '../../firebase/firebase.models';
import { Offer } from '../../models/offers.model';
import { ProtocolData } from '../../models/protocol.model';
import { createPDFs } from '../OfferAdd/Documents/createPDFs';
import { ConfigType } from '../OfferAdd/Documents/documents.models';

interface Props {
  offerId: string;
  values: ProtocolData;
  currentUser: firebase.User;
}

export const handleOnProtocolSubmit = async ({
  offerId,
  values,
  currentUser,
}: Props): Promise<{ success: boolean; description: string }> => {
  values.submittedAt = new Date().toLocaleString();
  values.protocolSubmitterUid = currentUser.uid;

  const safeValues = replaceUndefinedWithBrak(values);

  console.log(safeValues);

  try {
    const docRef = firestore.collection(FirestoreCollection.OFFERS).doc(offerId);
    await docRef.update({ protocolData: safeValues });
    const updatedDoc = await docRef.get();
    if (updatedDoc.exists) {
      const offer = updatedDoc.data() as Offer;
      const protocolLink = await createPDFs(offer, ConfigType.PROTOCOL);
      const onProtocolAttached = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_PROTOCOL_ATTACHED);
      await onProtocolAttached({ offer, protocolLink });
      return { success: true, description: 'SUCCESS' };
    } else {
      return { success: false, description: 'CAN_NOT_UPDATE' };
    }
  } catch (error) {
    console.error(error);
    return { success: false, description: 'CAN_NOT_SAVE' };
  }
};

// nie da się wykonać funkcji update jeśli są wartości undefined
function replaceUndefinedWithBrak(values) {
  // Create a shallow copy to avoid mutating the original object
  const updatedValues = { ...values };

  // Iterate over the object keys
  Object.keys(updatedValues).forEach((key) => {
    if (updatedValues[key] === undefined) {
      updatedValues[key] = 'Brak';
    }
  });

  return updatedValues;
}

import { firebaseFunctions, firestore } from '../../../firebase/firebase';
import { FirebaseCallableFunctions, FirestoreCollection } from '../../../firebase/firebase.models';
import { ReceptionForm, Verification } from '../../../models/offers.model';

// Asynchroniczna funkcja do weryfikacji użytkownika
async function isUserVerified(data: ReceptionForm): Promise<boolean> {
  const userRef = firestore
    .collection(FirestoreCollection.USERS)
    .where('firstName', '==', data.firstName)
    .where('lastName', '==', data.lastName)
    .where('email', '==', data.email)
    .where('verification.identity', '==', Verification.ACCEPTED);

  const querySnapshot = await userRef.get();
  return !querySnapshot.empty;
}

// Pomocnicza funkcja do wywoływania funkcji Firebase
async function sendNotification(functionName: string, data: any, offerId: string): Promise<void> {
  const callable = firebaseFunctions.httpsCallable(functionName);
  await callable({ ...data, offerId });
}

// Główna funkcja do aktualizacji danych odbioru
export const useUpdateReceptionOfThePremise = async (
  data: ReceptionForm,
  id: string,
  landlord?: boolean
): Promise<[boolean, string]> => {
  if (data.date) {
    data.date = data.date.toLocaleString('pl-PL', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }
  if (data.hour) {
    data.hour = data.hour.toLocaleString('pl-PL', { hour: '2-digit', minute: '2-digit', hour12: false });
  }

  const isVerified = await isUserVerified(data);

  if (!isVerified) {
    return [false, 'user not verified'];
  }

  try {
    // Określenie, którą funkcję i jakie aktualizacje należy wykonać
    const functionName = landlord
      ? FirebaseCallableFunctions.ON_RECEPTION_TO_LANDLORD
      : FirebaseCallableFunctions.ON_RECEPTION_TO_TENANT;
    const updateData = landlord
      ? { email: data.email, firstName: data.firstName, lastName: data.lastName }
      : {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          date: data.date,
          hour: data.hour,
        };

    // Aktualizacja Firestore i wysyłanie notyfikacji
    await sendNotification(functionName, updateData, id);

    return [true, 'Success'];
  } catch (error: any) {
    console.error(`Error in ${landlord ? 'landlord' : 'tenant'} reception process:`, error);
    return [false, `Failed to update reception details: ${error.message || error.toString()}`];
  }
};

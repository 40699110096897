import { Collapse } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizedLabelWithMultipleLinks } from '../../OfferAdd/utils/LocalizedLabelWithMultipleLinks';

import { Title } from './FAQ';

const { Panel } = Collapse;

export const Documents: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Umowy i dokumenty */}
      <div>
        <Title>{t('FAQ.DOCUMENTS.TITLE')}</Title>
        <Collapse>
          <Panel header={t('FAQ.DOCUMENTS.DOWNLOAD_COPY_HEADER')} key="23">
            <p>
              {t('FAQ.DOCUMENTS.DOWNLOAD_COPY_TEXT', {
                interpolation: { escapeValue: false },
              })}
              <LocalizedLabelWithMultipleLinks
                i18nKey="FAQ.DOCUMENTS.DOWNLOAD_COPY_LINK"
                linkPaths={{
                  '1': '/moja-tablica/my-offers/unsigned-offers',
                }}
              />
            </p>
          </Panel>
          <Panel header={t('FAQ.DOCUMENTS.SIGN_DOCUMENTS_HEADER')} key="24">
            <p>{t('FAQ.DOCUMENTS.SIGN_DOCUMENTS_TEXT')}</p>
          </Panel>
          <Panel header={t('FAQ.DOCUMENTS.CANNOT_OPEN_DOCUMENT_HEADER')} key="25">
            <p>{t('FAQ.DOCUMENTS.CANNOT_OPEN_DOCUMENT_TEXT')}</p>
          </Panel>
        </Collapse>
      </div>
    </>
  );
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next'; // Import the translation hook

import { SelectOption } from '../../../../models/main.model';

export function useDriveType(): SelectOption[] {
  const { t } = useTranslation(); // Add translation

  return useMemo(
    () => [
      { label: t('DRIVE.PAVED'), value: 'Droga utwardzona' },
      { label: t('DRIVE.DIRT'), value: 'Droga polna' },
      { label: t('DRIVE.ASPHALT'), value: 'Droga asfaltowa' },
      { label: t('DRIVE.CONCRETE_PAVERS'), value: 'Kostka betonowa' },
      { label: t('DRIVE.OTHER'), value: 'inne' },
    ],
    [t]
  );
}

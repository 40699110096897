import styled from 'styled-components';

const Common = styled.div<{ dark?: boolean }>`
  ${({ dark }) => dark && `color: rgba(255, 255, 255, 0.9) !important;`}
`;

const generateHeader = (tag: string, responsiveSize: string, mediumSize: string, fixedSize: string) =>
  styled(Common).attrs({ as: tag })`
    font-size: ${responsiveSize}; /* Responsive font size for small screens */
    line-height: 110%;
    color: ${({ theme }) => theme.textHeaders};

    @media (min-width: 480px) and (max-width: 767px) {
      font-size: ${mediumSize}; /* Mid-range size */
    }

    @media (min-width: 768px) {
      font-size: ${fixedSize}; /* Fixed size for larger screens */
    }
  `;

const H1 = generateHeader('h1', 'calc(35px + 1.5vw)', 'calc(40px + 2vw)', '80px');
const H2 = generateHeader('h2', 'calc(20px + 1vw)', 'calc(25px + 1.5vw)', '40px');
const H3 = generateHeader('h3', 'calc(18px + 1vw)', 'calc(22px + 1vw)', '30px');
const H4 = generateHeader('h4', 'calc(17px + 1vw)', 'calc(20px + 1vw)', '25px');
const H5 = generateHeader('h5', 'calc(16px + 1vw)', 'calc(18px + 1vw)', '20px');
const H6 = generateHeader('h6', 'calc(15px)', 'calc(17px + 1vw)', '17px');

const Anchor = styled.a`
  cursor: pointer;
`;
const Body1 = styled.span``;
const Body2 = styled.span`
  color: rgb(8, 24, 75);
`;

export const Typography = {
  Anchor,
  Body1,
  Body2,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
};

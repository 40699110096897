import { Progress, Space } from 'antd';
import React, { useEffect, useState } from 'react';

import { OfferCheck } from '../../firebase/hooks/updateOfferCheck.hook';

// Mapa kroków progresu do procentów
const progressStepsProject: Partial<Record<OfferCheck, number>> = {
  [OfferCheck.VERIFYING_DATA]: 10,
  [OfferCheck.VERIFYING_IMAGES]: 30,
  [OfferCheck.GENERATING_PROJECT_OFFER]: 60,
  [OfferCheck.SENDING_PROJECT_OFFER]: 90,
  [OfferCheck.DONE]: 100,
};

const progressStepsOffer: Partial<Record<OfferCheck, number>> = {
  [OfferCheck.CHECKING_NETWORK]: 20,
  [OfferCheck.DOWNLOADING_DATA]: 30,
  [OfferCheck.SIGNING_OFFER]: 50,
  [OfferCheck.SAVE_REPORT]: 60,
  [OfferCheck.SENDING_OFFER]: 90,
  [OfferCheck.DONE]: 100,
};

const progressStepsSignVerify: Partial<Record<OfferCheck, number>> = {
  [OfferCheck.CHECKING_NETWORK]: 20,
  [OfferCheck.DOWNLOADING_DATA]: 30,
  [OfferCheck.RESERVATION]: 50,
  [OfferCheck.PREPARING_DOCUMENTS]: 70,
  [OfferCheck.SENDING_OFFER]: 90,
  [OfferCheck.DONE]: 100,
};

const progressStepsSignSubmit: Partial<Record<OfferCheck, number>> = {
  [OfferCheck.CHECKING_NETWORK]: 20,
  [OfferCheck.DOWNLOADING_DATA]: 30,
  [OfferCheck.SIGNING_CONTRACT]: 50,
  [OfferCheck.SAVE_REPORT]: 70,
  [OfferCheck.SENDING_CONTRACT]: 90,
  [OfferCheck.DONE]: 100,
};

export enum ProgressConfigType {
  PROJECT = 'project',
  OFFER = 'offer',
  SIGN_VERIFY = 'signVerify',
  SIGN_SUBMIT = 'signSubmit',
}

const OfferProgress: React.FC<{ offerCheck: OfferCheck; progressConfigType: ProgressConfigType }> = ({
  offerCheck,
  progressConfigType,
}) => {
  const [percent, setPercent] = useState<number>(0);

  const updatePercent = (): void => {
    switch (progressConfigType) {
      case ProgressConfigType.PROJECT:
        return setPercent(progressStepsProject[offerCheck] || 0);
      case ProgressConfigType.OFFER:
        return setPercent(progressStepsOffer[offerCheck] || 0);
      case ProgressConfigType.SIGN_VERIFY:
        return setPercent(progressStepsSignVerify[offerCheck] || 0);
      case ProgressConfigType.SIGN_SUBMIT:
        return setPercent(progressStepsSignSubmit[offerCheck] || 0);
    }
  };
  useEffect(() => {
    updatePercent();
  }, [offerCheck]);

  const getSteps = (configType: ProgressConfigType): number => {
    switch (configType) {
      case ProgressConfigType.PROJECT:
        return Object.keys(progressStepsProject).length;
      case ProgressConfigType.OFFER:
        return Object.keys(progressStepsOffer).length;
      case ProgressConfigType.SIGN_VERIFY:
        return Object.keys(progressStepsSignVerify).length;
      case ProgressConfigType.SIGN_SUBMIT:
        return Object.keys(progressStepsSignSubmit).length;
      default:
        return 0;
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {offerCheck !== OfferCheck.DONE && (
        <Progress size={[40, 30]} percent={percent} steps={getSteps(progressConfigType) + 1} />
      )}
    </Space>
  );
};

export default OfferProgress;

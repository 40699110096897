import { FormikHelpers } from 'formik/dist/types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ToastVariant } from '../../../../components/Toast/toast.model';
import { toastService } from '../../../../components/Toast/toast.service';
import { SignInFormValues } from '../../auth.model';
import { useAuth } from '../../AuthContext';

export function useOnSubmit(
  onSuccess: () => void
): (values: SignInFormValues, formikHelpers: FormikHelpers<SignInFormValues>) => Promise<any> {
  const { signIn } = useAuth();
  const { t } = useTranslation();

  return useCallback((values) => {
    return signIn(values)
      .catch((e) => {
        toastService.show(e.message, t('ERROR'), { variant: ToastVariant.ERROR });
      })
      .then(() => onSuccess?.());
  }, []);
}

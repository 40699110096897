import { ToastVariant } from '../../components/Toast/toast.model';
import { toastService } from '../../components/Toast/toast.service';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

export enum OfferCheck {
  VERIFYING_IMAGES = 'VERIFYING_IMAGES',
  VERIFYING_DATA = 'VERIFYING_DATA',
  GENERATING_PROJECT_OFFER = 'GENERATING_PROJECT_OFFER',
  SENDING_PROJECT_OFFER = 'SENDING_PROJECT_OFFER',
  DONE = 'DONE',

  CHECKING_NETWORK = 'CHECKING_NETWORK',
  DOWNLOADING_DATA = 'DOWNLOADING_DATA',
  SIGNING_OFFER = 'SIGNING_OFFER',
  SAVE_REPORT = 'SAVE_REPORT',
  SENDING_OFFER = 'SENDING_OFFER',

  RESERVATION = 'RESERVATION',
  PREPARING_DOCUMENTS = 'PREPARING_DOCUMENTS',

  SIGNING_CONTRACT = 'SIGNING_CONTRACT',
  SENDING_CONTRACT = 'SENDING_CONTRACT',
}

export async function useUpdateOfferCheck(offerId: string, state: OfferCheck): Promise<void> {
  try {
    const docRef = firestore.collection(FirestoreCollection.OFFERS).doc(offerId);
    docRef
      .update({ offerCheck: state })

      .catch((e) => {
        toastService.show(e, 'Błąd!', { variant: ToastVariant.ERROR });
      });
  } catch (error) {
    toastService.show('Nie udało się przejść do następnego kroku', 'Błąd!', { variant: ToastVariant.ERROR });
  }
  return Promise.resolve();
}

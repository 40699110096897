import { Col, Layout, Row, Typography } from 'antd';
import React from 'react';
const { Header, Content, Footer } = Layout;
const { Title } = Typography;

export const AdminApp: React.FC = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ backgroundColor: '#001529', padding: '10px 20px' }}>
        <Title level={3} style={{ color: '#fff', margin: 0 }}>
          Admin Dashboard
        </Title>
      </Header>
      <Content style={{ padding: '50px' }}>
        <Row justify="center">
          <Col xs={24} sm={16} md={12} lg={8}>
            {children}
          </Col>
        </Row>
      </Content>
      <Footer style={{ textAlign: 'center' }}>© {new Date().getFullYear()} najmuje.com</Footer>
    </Layout>
  );
};

export function copyTextToClipboard(text: string, onSuccess?: () => void, onError?: () => void): void {
  if (!navigator.clipboard) {
    console.error('Clipboard API not supported');
    if (onError) onError();
    return;
  }

  navigator.clipboard
    .writeText(text)
    .then(() => {
      if (onSuccess) onSuccess();
    })
    .catch((error) => {
      console.error('Failed to write to clipboard:', error);
      if (onError) onError();
    });
}

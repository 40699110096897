import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../models/main.model';

export function useBuildingType(): SelectOption[] {
  const { t, i18n } = useTranslation();

  const language = i18n.language || 'pl';

  const options = useMemo(
    () => [
      { label: t('BUILDING_TYPE.OFFICE_BUILDING'), value: 'Biurowiec' },
      { label: t('BUILDING_TYPE.OFFICE_RETAIL'), value: 'Obiekt biurowo-handlowy' },
      { label: t('BUILDING_TYPE.OFFICE_WAREHOUSE'), value: 'Obiekt biurowo-magazynowy' },
      { label: t('BUILDING_TYPE.TENEMENT_OFFICE'), value: 'Kamienica biurowa' },
      { label: t('BUILDING_TYPE.FACTORY'), value: 'Zakład produkcyjny' },
      { label: t('BUILDING_TYPE.WAREHOUSE'), value: 'Hala magazynowa' },
      { label: t('BUILDING_TYPE.GARAGE_WORKSHOP'), value: 'Garaż/warsztat' },
      { label: t('BUILDING_TYPE.CELLAR'), value: 'Piwnica' },
      { label: t('BUILDING_TYPE.OTHER'), value: 'Inny' },
      { label: t('BUILDING_TYPE.PAVILION'), value: 'Pawilon wolnostojący' },
      { label: t('BUILDING_TYPE.SHOPPING_CENTER'), value: 'Centrum handlowe' },
      { label: t('BUILDING_TYPE.SHOPPING_GALLERY'), value: 'Galeria handlowa' },
      { label: t('BUILDING_TYPE.TENEMENT_RETAIL'), value: 'Kamienica handlowa' },
      { label: t('BUILDING_TYPE.TENEMENT_RESIDENTIAL'), value: 'Kamienica mieszkalna' },
      { label: t('BUILDING_TYPE.BLOCK_OF_FLATS'), value: 'Blok mieszkalny' },
      { label: t('BUILDING_TYPE.APARTMENT'), value: 'Apartamentowiec' },
      { label: t('BUILDING_TYPE.DETACHED_HOUSE'), value: 'Dom wolnostojący' },
      { label: t('BUILDING_TYPE.SEMI_DETACHED'), value: 'Bliźniak' },
      { label: t('BUILDING_TYPE.TERRACED'), value: 'Segment' },
    ],
    [t]
  );

  // Sortowanie opcji na podstawie języka
  const sortedOptions = useMemo(() => {
    return options.sort((a, b) => {
      const aLabel = language === 'pl' ? a.value : a.label; // Używaj wartości lub etykiety w zależności od języka
      const bLabel = language === 'pl' ? b.value : b.label;
      return aLabel.localeCompare(bLabel); // Porównaj alfabetycznie
    });
  }, [options, language]);

  return sortedOptions;

  // return useMemo(
  //   () => [
  //     { label: t('BUILDING_TYPE.OFFICE_BUILDING'), value: 'Biurowiec' },
  //     { label: t('BUILDING_TYPE.OFFICE_RETAIL'), value: 'Obiekt biurowo-handlowy' },
  //     { label: t('BUILDING_TYPE.OFFICE_WAREHOUSE'), value: 'Obiekt biurowo-magazynowy' },
  //     { label: t('BUILDING_TYPE.TENEMENT_OFFICE'), value: 'Kamienica biurowa' },
  //     { label: t('BUILDING_TYPE.FACTORY'), value: 'Zakład produkcyjny' },
  //     { label: t('BUILDING_TYPE.WAREHOUSE'), value: 'Hala magazynowa' },
  //     { label: t('BUILDING_TYPE.GARAGE_WORKSHOP'), value: 'Garaż/warsztat' },
  //     { label: t('BUILDING_TYPE.CELLAR'), value: 'Piwnica' },
  //     { label: t('BUILDING_TYPE.OTHER'), value: 'Inny' },
  //     { label: t('BUILDING_TYPE.PAVILION'), value: 'Pawilon wolnostojący' },
  //     { label: t('BUILDING_TYPE.SHOPPING_CENTER'), value: 'Centrum handlowe' },
  //     { label: t('BUILDING_TYPE.SHOPPING_GALLERY'), value: 'Galeria handlowa' },
  //     { label: t('BUILDING_TYPE.TENEMENT_RETAIL'), value: 'Kamienica handlowa' },
  //     { label: t('BUILDING_TYPE.TENEMENT_RESIDENTIAL'), value: 'Kamienica mieszkalna' },
  //     { label: t('BUILDING_TYPE.BLOCK_OF_FLATS'), value: 'Blok mieszkalny' },
  //     { label: t('BUILDING_TYPE.APARTMENT'), value: 'Apartamentowiec' },
  //     { label: t('BUILDING_TYPE.DETACHED'), value: 'Dom wolnostojący' },
  //     { label: t('BUILDING_TYPE.SEMI_DETACHED'), value: 'Bliźniak' },
  //     { label: t('BUILDING_TYPE.TERRACED'), value: 'Segment' },
  //   ],
  //   [t]
  // );
}

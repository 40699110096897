import { Button, Modal } from 'antd';
import React, { createContext, useContext, useState } from 'react';

// Interfejs wartości kontekstu
interface DocumentModalContextValues {
  showDocumentModal: (link: string) => void;
  closeModal: () => void;
}

// Stworzenie kontekstu
const DocumentModalContext = createContext<DocumentModalContextValues>(null);

// Provider dla DocumentModalContext
export const DocumentModalProvider: React.FC = ({ children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [documentLink, setDocumentLink] = useState<string | null>(null);

  // Funkcja do wyświetlania modala z dokumentem
  const showDocumentModal = (link: string) => {
    setDocumentLink(link);
    setIsModalVisible(true);
  };

  // Funkcja do zamykania modala
  const closeModal = () => {
    setIsModalVisible(false);
    setDocumentLink(null);
  };

  return (
    <DocumentModalContext.Provider value={{ showDocumentModal, closeModal }}>
      {children}
      <DocumentModal isVisible={isModalVisible} link={documentLink} onClose={closeModal} />
    </DocumentModalContext.Provider>
  );
};

const handleDownloadFile = async (url: string) => {
  let fileName = url.split('%2F').pop() || 'plik.pdf';

  if (fileName.includes('?')) {
    fileName = fileName.split('?')[0];
  }

  try {
    const response = await fetch(url);
    const blob = await response.blob();

    // Tworzenie linku do pobrania pliku
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName; // Nazwa pliku po pobraniu
    link.click();
  } catch (error) {
    console.error('Error downloading file: ', error);
  }
};

// Komponent modal do wyświetlania dokumentów
interface DocumentModalProps {
  isVisible: boolean;
  link: string | null;
  onClose: () => void;
}

const DocumentModal: React.FC<DocumentModalProps> = ({ isVisible, link, onClose }) => {
  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      width="60%"
      style={{ height: '95vh', padding: 0 }}
      centered
      title="Podgląd Dokumentu"
      footer={
        <div className="flex justify-center">
          <Button
            onClick={(e) => {
              handleDownloadFile(link);
              e.stopPropagation();
            }}
            className="underline"
            rel="noreferrer">
            Pobierz
          </Button>
        </div>
      }>
      {link && (
        <div style={{ height: '85vh', width: '100%' }}>
          <iframe src={link} width="100%" height="100%" style={{ border: 'none' }} title="Document Viewer"></iframe>
        </div>
      )}
    </Modal>
  );
};

// Hook do używania kontekstu DocumentModal
export const useDocumentModal = (): DocumentModalContextValues => {
  return useContext(DocumentModalContext);
};

import { firestore } from '../../../firebase/firebase';
import { FirestoreCollection } from '../../../firebase/firebase.models';
import { Verification } from '../../../models/offers.model';

export const isIdentityConfirmed = async (currentUser: { uid: string }): Promise<Verification> => {
  const doc = await firestore.collection(FirestoreCollection.USERS).doc(currentUser.uid).get();

  const data = doc.data();
  const identity = data?.verification?.identity as Verification;

  return identity || Verification.INITIAL;
};

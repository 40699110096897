import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { HowItWorksTile } from '../../../components/HowItWorksTail/HowItWorksTile';
import { WidthWrapper } from '../../../components/Layout/styles';

const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.backgroundOrange};
  padding: 80px 0;
`;
const Content = styled.article`
  display: flex;
  justify-content: space-evenly;

  > *:not(:last-child):after {
    content: url('/images/assets/arrow.png');
    position: absolute;
    width: auto;
    height: auto;
    background: 0 0;
    left: 100%;
    top: 40px;
  }
`;

export const HowItWorks: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <WidthWrapper>
        <Content>
          <HowItWorksTile
            title={t('HOW_WORKS_ADD.POST_AD.TITLE')}
            description={t('HOW_WORKS_ADD.POST_AD.DESCRIPTION')}
            color={'green'}
            icon={'building-o'}
          />
          <HowItWorksTile
            title={t('HOW_WORKS_ADD.SIGN_OFFER.TITLE')}
            description={t('HOW_WORKS_ADD.SIGN_OFFER.DESCRIPTION')}
            color={'yellow'}
            icon={'check2'}
          />
          <HowItWorksTile
            title={t('HOW_WORKS_ADD.RECEIVE_LEASE.TITLE')}
            description={t('HOW_WORKS_ADD.RECEIVE_LEASE.DESCRIPTION')}
            color={'blue'}
            icon={'handshake-o'}
          />
        </Content>
      </WidthWrapper>
    </Wrapper>
  );
};

import { notification } from 'antd';

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export const openNotification = (type: 'success' | 'error', description: string): void => {
  notification[type]({
    message: type,
    description: description,
  });
};

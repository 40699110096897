import 'esri-leaflet';
import * as ELG from 'esri-leaflet-geocoder';
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';
import * as React from 'react';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Marker, useMap } from 'react-leaflet';
import { useDebounce } from 'react-use';
import { AutoComplete, Icon, InputGroup } from 'rsuite';
import { ItemDataType } from 'rsuite/lib/@types/common';
import styled from 'styled-components';

import { INITIAL_BOUNDS } from '../LeafletMap';
import { GeocodeSearchItem, Suggestion } from '../leaflet.model';

const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
`;
const StyledIcon = styled(Icon)`
  * && {
    transform: translateY(4px);
    font-size: 18px;
    line-height: 1;
  }
`;

export const searchProvider = (ELG as any).arcgisOnlineProvider({
  apikey: process.env.REACT_APP_ARGIS_KEY,
} as any);

interface Props {
  domNode: Element;
  setSearchResult: Dispatch<SetStateAction<GeocodeSearchItem>>;
  searchResult: GeocodeSearchItem;
  initialAddress?: string;
}
export const LeafletEsriGeocodeByClick: React.FC<Props> = ({
  domNode,
  setSearchResult,
  searchResult,
  initialAddress,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const selectedMarkerRef = useRef<Marker>(null);
  const map = useMap();
  const [searchValue, setSearchValue] = useState<string>(initialAddress);
  const [debouncedSearchValue, setDebouncedSearchValue] = React.useState('');
  const { t } = useTranslation();

  useEffect(() => {
    // możesz dodać tu warunek (jakiś przycisk, który odblokuje mapkę?)
    map.scrollWheelZoom.disable();
    map.dragging.disable();

    return () => {
      map.scrollWheelZoom.enable();
      map.dragging.enable();
    };
  }, [map]);

  useDebounce(
    () => {
      setDebouncedSearchValue(searchValue);
    },
    300,
    [searchValue]
  );
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  useEffect(() => {
    if (debouncedSearchValue?.length > 3) {
      searchProvider.suggestions(debouncedSearchValue, INITIAL_BOUNDS, (u, s) => {
        if (Array.isArray(s)) {
          setSuggestions(s);
        }
      });
    }
  }, [debouncedSearchValue]);

  const onSelect = useCallback((item: ItemDataType) => {
    searchProvider.results(item.value, null, INITIAL_BOUNDS, (e, s: GeocodeSearchItem[]) => {
      setSearchResult(s[0]);
    });
  }, []);

  useEffect(() => {
    if (searchResult?.bounds) {
      map.flyToBounds(searchResult?.bounds as any);
    }
  }, [searchResult]);

  return (
    <>
      {searchResult && (
        <Marker position={[+searchResult.latlng.lat, +searchResult.latlng.lng]} ref={selectedMarkerRef} />
      )}

      <GeoSearchInputPortal domNode={domNode}>
        <InputGroup inside>
          <StyledAutoComplete
            size="lg"
            placeholder={t('COMMON.FORMS.PLACEHOLDERS.ENTER_LOCATION')}
            name={'suggestions'}
            data={suggestions.map((s) => s.text)}
            value={searchValue}
            onChange={(e) => setSearchValue(e)}
            onSelect={onSelect}
            filterBy={() => true}
          />
          <InputGroup.Button>
            <StyledIcon icon="search" size="lg" />
          </InputGroup.Button>
        </InputGroup>
      </GeoSearchInputPortal>
    </>
  );
};

const GeoSearchInputPortal: React.FC<{ domNode: Element }> = ({ children, domNode }) => {
  return domNode ? ReactDOM.createPortal(children, domNode) : null;
};

import { useEffect, useState } from 'react';

import { Verification } from '../../models/offers.model';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

export const useGetIdentityVerificationStatus = (uid: string | null): [Verification | null, boolean, any | null] => {
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState<Verification | null>(null);

  useEffect(() => {
    if (!uid) {
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);
    const unsubscribe = firestore
      .collection(FirestoreCollection.USERS)
      .doc(uid)
      .onSnapshot(
        (snapshot) => {
          const data = snapshot.data();
          setVerificationStatus(data ? data.verification.identity : null);
          setLoading(false);
        },
        (e) => {
          console.error('Error fetching identity:', e);
          setError(e);
          setLoading(false);
        }
      );
    return () => unsubscribe();
  }, [uid]);

  return [verificationStatus, loading, error];
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next'; // Import the translation hook

import { SelectOption } from '../../../../models/main.model';

export function useOtherRoomsOptions(): SelectOption[] {
  const { t } = useTranslation(); // Initialize the translation hook

  return useMemo(
    () => [
      { label: t('OTHER_ROOMS.KITCHEN'), value: 'kuchnia' },
      { label: t('OTHER_ROOMS.RECREATIONAL_ROOM'), value: 'pomieszczenie rekreacyjne' },
      { label: t('OTHER_ROOMS.TERRACE'), value: 'taras' },
      { label: t('OTHER_ROOMS.SMOKING_ROOM'), value: 'pomieszczenie dla palaczy' },
    ],
    [t]
  );
}

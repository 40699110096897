import { Notification } from 'rsuite';

import { ToastConfig, ToastContentType, ToastVariant } from './toast.model';

const defaultOptions: ToastConfig = {
  variant: ToastVariant.SUCCESS,
};

function show(description: ToastContentType, title?: string, config: ToastConfig = defaultOptions): void {
  Notification[config.variant]({
    title,
    description,
    duration: config.duration,
  });
}

function close(): void {
  Notification.closeAll();
}

export const toastService = {
  show,
  close,
};

import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { useAuth } from '../AuthContext';
import { AppRoutes } from '../../Routing/routing.model';

export function useSignOut(): () => Promise<void> {
  const { signOut } = useAuth();
  const { push } = useHistory();

  return useCallback(() => {
    return signOut().then(() => push(AppRoutes.HOME));
  }, []);
}

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../models/main.model';
import { PremisesPurpose } from '../../../../models/offers.model';

export function usePremisesPurposeSelectOptions(): SelectOption[] {
  const { t } = useTranslation();
  return useMemo(
    () =>
      Object.entries(PremisesPurpose).map(([key, value]) => ({
        label: t(`FORMS.PREMISES_PURPOSE.VALUES.${key.toUpperCase()}`),
        value,
      })),
    [t]
  );
}

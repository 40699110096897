import { Collapse } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizedLabelWithMultipleLinks } from '../../OfferAdd/utils/LocalizedLabelWithMultipleLinks';
import { LocalizedLabelWithMultipleModals } from '../../OfferAdd/utils/LocalizedLabelWithMultipleModals';

import { Title } from './FAQ';

const { Panel } = Collapse;

export const Terminology: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Słownictwo i pojęcia */}
      <div>
        <Title>{t('FAQ.TERMINOLOGY.TITLE')}</Title>
        <Collapse>
          <Panel header={t('FAQ.TERMINOLOGY.PROJECT_OFFER_CONTRACT_HEADER')} key="15">
            <p>
              {t('FAQ.TERMINOLOGY.PROJECT_OFFER_CONTRACT_TEXT')}
              <LocalizedLabelWithMultipleLinks
                i18nKey="FAQ.TERMINOLOGY.PROJECT_OFFER_CONTRACT_LINK"
                linkPaths={{
                  '1': '/pomoc',
                }}
              />
            </p>
          </Panel>
          <Panel header={t('FAQ.TERMINOLOGY.TENANT_LANDLORD_HEADER')} key="3">
            <p>{t('FAQ.TERMINOLOGY.TENANT_LANDLORD_TEXT')}</p>
          </Panel>
        </Collapse>
      </div>

      {/* Płatności i kaucje */}
      <div>
        <Title>{t('FAQ.PAYMENTS.TITLE')}</Title>
        <Collapse>
          <Panel header={t('FAQ.PAYMENTS.COST_HEADER')} key="17">
            <p>
              {t('FAQ.PAYMENTS.COST_TEXT')}
              <LocalizedLabelWithMultipleModals
                i18nKey="FAQ.PAYMENTS.COST_LINK"
                linkPaths={{
                  '1': '/view-document/documents&FL_Cennik.pdf',
                }}
              />
            </p>
          </Panel>
          <Panel header={t('FAQ.PAYMENTS.DEPOSIT_RELEASE_HEADER')} key="18">
            <p>{t('FAQ.PAYMENTS.DEPOSIT_RELEASE_TEXT')}</p>
          </Panel>
          <Panel header={t('FAQ.PAYMENTS.DEPOSIT_DEDUCTION_HEADER')} key="19">
            <p>{t('FAQ.PAYMENTS.DEPOSIT_DEDUCTION_TEXT')}</p>
          </Panel>
        </Collapse>
      </div>
    </>
  );
};

import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Marker, Popup, useMap } from 'react-leaflet';
import { LatLngTuple, icon } from 'leaflet';

import { Offer } from '../../../models/offers.model';

const OfferIcon = icon({
  iconUrl: '/images/assets/office-icon.png',
  iconSize: [32, 32],
  iconAnchor: [16, 16],
  popupAnchor: [0, -16],
});

interface Props {
  offerData: Offer[];
  selectedOfferId: string | null;
  disableAnimation?: boolean;
}
export const MapMarkers: React.FC<Props> = ({ selectedOfferId, offerData, disableAnimation }) => {
  const map = useMap();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const selectedMarkerRef = useRef<Marker>(null);

  useEffect(() => {
    const selectedOffer = offerData.find((o) => o.id === selectedOfferId);
    if (selectedOffer) {
      map.flyTo([+selectedOffer.geoMarker.lat, +selectedOffer.geoMarker.lng]);
    }
    selectedMarkerRef.current?.openPopup();
  }, [selectedOfferId]);

  useEffect(() => {
    if (offerData.length) {
      const pins = offerData.map((s): LatLngTuple => [+s.geoMarker.lat, +s.geoMarker.lng]);
      disableAnimation ? map.fitBounds(pins, { padding: [100, 100] }) : map.flyToBounds(pins, { padding: [100, 100] });
    }
  }, [offerData]);

  return (
    <>
      {offerData?.map((offer) => (
        <Marker
          icon={OfferIcon}
          key={offer.id}
          position={[+offer.geoMarker.lat, +offer.geoMarker.lng]}
          ref={offer.id === selectedOfferId ? selectedMarkerRef : null}>
          <Popup keepInView={offer.id === selectedOfferId}>{offer.name}</Popup>
        </Marker>
      ))}
    </>
  );
};

import React from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

const images = [
  { src: '/images/assets/legenda_2.drawio.png', alt: 'Legend', title: 'Legenda interakcji użytkownika' },
  { src: '/images/assets/flow_utworz.drawio.png', alt: 'User flow chart 1', title: 'Utwórz konto' },
  { src: '/images/assets/flow_zaloguj.drawio.png', alt: 'User flow chart 2', title: 'Zaloguj' },
  { src: '/images/assets/flow_potwierdz_toz.drawio.png', alt: 'User flow chart 3', title: 'Potwierdź tożsamość' },
  { src: '/images/assets/flow_potwierdz_firme.drawio.png', alt: 'User flow chart 4', title: 'Potwierdź firmę' },
  { src: '/images/assets/flow_dodaj.drawio.png', alt: 'User flow chart 6', title: 'Dodaj lokal' },
  {
    src: '/images/assets/flow_wynajmujacy.drawio.png',
    alt: 'User flow chart 7',
    title: 'Proces najmu - Wynajmujący',
  },
  { src: '/images/assets/flow_najemca.drawio.png', alt: 'User flow chart 5', title: 'Proces najmu - Najemca' },
];

const ImageZoomWrapper = ({ imageSrc, altText, imageTitle }) => (
  <TransformWrapper wheel={{ step: 0.1 }}>
    {({ zoomIn, zoomOut, resetTransform }) => (
      <>
        <div
          className="border-2 border-gray-500 rounded-md my-6 flex flex-col items-center"
          style={{
            backgroundImage: 'radial-gradient(#cecece 1px, transparent 1px)',
            backgroundSize: '12px 12px',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            padding: '10px',
          }}>
          <h2 className="font-bold text-center text-gray-600 text-xl my-2 bg-gray-300  rounded-md w-full p-2">
            {imageTitle}
          </h2>
          <div className="flex flex-col">
            <div className="tools inline-flex space-x-2 mb-4 p-4 bg-gray-300 bg-opacity-70 rounded-md">
              {' '}
              <button
                onClick={() => zoomIn(1, 300, 'easeOutCubic')}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition">
                +
              </button>
              <button
                onClick={() => zoomOut(1, 300, 'easeOutCubic')}
                className="px-4 py-2 bg-blue-400 text-white rounded-md hover:bg-blue-500 transition">
                -
              </button>
              <button
                onClick={() => resetTransform()}
                className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition">
                Reset
              </button>
            </div>
          </div>
          <TransformComponent>
            <div style={{ padding: '40px 15px' }}>
              <img src={imageSrc} alt={altText} className="w-full h-auto" />
            </div>
          </TransformComponent>
        </div>
      </>
    )}
  </TransformWrapper>
);

export const Flows: React.FC = () => {
  return (
    <div className="p-8 mx-auto ">
      <h1 className="text-3xl text-center font-bold">Schemat działania platformy</h1>

      {images.map((image, index) => (
        <ImageZoomWrapper key={index} imageSrc={image.src} altText={image.alt} imageTitle={image.title} />
      ))}
    </div>
  );
};

export default Flows;

// FileUploadComponent.tsx
import { UploadOutlined } from '@ant-design/icons';
import { Spin, Upload } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizedLabelWithMultipleLinks } from '../../../../OfferAdd/utils/LocalizedLabelWithMultipleLinks';

interface FileUploadComponentProps {
  disabled?: boolean;
  show: boolean;
  isUploading: boolean;
  fileList: any[];
  customUpload: (options: any) => Promise<void>;
}

export const FileUploadComponent: React.FC<FileUploadComponentProps> = ({
  disabled = false,
  show,
  isUploading,
  fileList,
  customUpload,
}) => {
  const { Dragger } = Upload;
  const { t } = useTranslation();

  if (show) {
    return (
      <>
        <Dragger
          name="file"
          multiple={false}
          fileList={fileList}
          customRequest={customUpload}
          disabled={disabled}
          onDrop={(e) => {
            console.log('Dropped files', e.dataTransfer.files);
          }}
          showUploadList={false}
          style={{ marginTop: '10px', marginBottom: '16px' }}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined rev={undefined} />
          </p>
          <p className="ant-upload-text">{t('UPLOAD_TERMS')}</p>
          <p className="ant-upload-hint">
            <LocalizedLabelWithMultipleLinks
              i18nKey="DOWNLOAD_TERMS"
              linkPaths={{
                11: '/',
                33: 'https://moj.gov.pl/nforms/signer/upload?xFormsAppName=SIGNER',
              }}
            />
          </p>
        </Dragger>
        {isUploading ? <Spin style={{ marginTop: '20px' }} /> : null}
      </>
    );
  } else {
    return null;
  }
};

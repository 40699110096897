import { firestore } from '../../../firebase/firebase';
import { FirestoreCollection } from '../../../firebase/firebase.models';

// Hook to set offer to a specific user
export const setOfferToUser = (userUid: string, offerId: string): Promise<void> => {
  const userRef = firestore.collection(FirestoreCollection.USERS).doc(userUid);

  // Use Firestore transaction to ensure atomicity
  return firestore.runTransaction((transaction) => {
    return transaction.get(userRef).then((userDoc) => {
      if (!userDoc.exists) {
        throw new Error('Document does not exist!');
      }

      // Add the offerId to the user's offers array (or set it if doesn't exist)
      const currentOffers = userDoc.data().offers || [];
      if (!currentOffers.includes(offerId)) {
        currentOffers.push(offerId);
      }
      transaction.update(userRef, { offers: currentOffers });
    });
  });
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../models/main.model';

export function useTechnicalRoomTypeOptions(): SelectOption[] {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        'kuchnia',
        'toaleta wspólna',
        'toaleta damska',
        'toaleta męska',
        'serwerownia',
        'pom. sprzątaczek',
        'korytarz',
      ].map((item) => ({
        label: t(`FORMS.OFFER_ADD.TECHNICAL_ROOM_TYPE.${item.toUpperCase()}`), // Użycie tłumaczeń dla label
        value: item, // Zachowanie oryginalnego value
      })),
    [t]
  );
}

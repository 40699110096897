// import dayjs from 'dayjs';
import i18n from './i18n';
import { Language } from './i18n.model';

let _currentLanguage: Language = i18n.language as Language;
// dayjs.locale(_currentLanguage);

export const getCurrentLanguage = (): Language => {
  return i18n.language as Language;
};

export const switchLanguageTo = (languageKey: Language): void => {
  _currentLanguage = languageKey;
  // dayjs.locale(_currentLanguage);
  console.log('switchLanguageTo', languageKey);
  i18n.changeLanguage(_currentLanguage);
};

import { EditOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { toastService } from '../../components/Toast/toast.service';

const RecruitmentHeader: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = window.innerWidth < 768;

  const actions: React.ReactNode[] = [
    <a
      href="https://docs.google.com/forms/d/e/1FAIpQLSc9lRv1hyl6Qtnw-7iB2WYvmnUpmnxBMggQyLtclZ5iRsEAmQ/viewform"
      target="_blank"
      key="signUp"
      rel="noreferrer">
      <EditOutlined />
    </a>, // Survey link

    <LinkOutlined
      key="share"
      onClick={() => {
        const linkToElement = `${window.location.origin}${window.location.pathname}#Recruitment`;
        navigator.clipboard.writeText(linkToElement);
        toastService.show(t('RECRUITMENT.SHARE_LINK'), t('RECRUITMENT.LINK_COPIED'));
      }}
    />,
  ];

  return (
    <div
      id="Recruitment"
      className="relative bg-cover bg-center"
      style={{ backgroundImage: 'url(/images/assets/pexels-olly-789822.jpg)' }}>
      <div className="w-full flex justify-center py-8">
        <div className="flex flex-col w-full mx-4 lg:mx-8 lg:w-2/3">
          <h1 className="text-gray-900 text-6xl lg:text-4xl font-extrabold text-left mt-24 md:mt-0 lg:mt-0">
            {t('RECRUITMENT.JOIN')}
            <a className="text-blue-700 ml-1" href="https://najmuje.com">
              {t('RECRUITMENT.COMPANY_NAME')}
            </a>
          </h1>
          <h2 className="text-gray-900 font-bold text-xl mt-4 text-left">{t('RECRUITMENT.TAGLINE')}</h2>
          <div className="flex justify-center md:justify-start lg:justify-start w-full mt-36 lg:mt-12">
            <Card
              title={t('RECRUITMENT.SPECIALIST_POSITION')}
              actions={actions}
              className="shadow-md mx-1 w-full md:w-3/4 lg:w-2/5 lg:mb-24"
              extra={
                !isMobile && (
                  <Button
                    type="link"
                    size="large"
                    className="m-4 mr-0 font-bold"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSc9lRv1hyl6Qtnw-7iB2WYvmnUpmnxBMggQyLtclZ5iRsEAmQ/viewform"
                    target="_blank">
                    {t('RECRUITMENT.JOIN_NOW')}
                  </Button>
                )
              }>
              <div className="text-lg mb-4">
                <strong>{t('RECRUITMENT.WHY_US')}</strong>
              </div>
              <div className="mb-2">
                <strong>{t('RECRUITMENT.SALARY')}</strong>
                <br /> {t('RECRUITMENT.SALARY_DESCRIPTION')}
              </div>
              <div className="mb-2">
                <strong>{t('RECRUITMENT.FLEXIBILITY')}</strong>
                <br />
                {t('RECRUITMENT.FLEXIBILITY_DESCRIPTION')}
              </div>
              <div className="mb-2">
                <strong>{t('RECRUITMENT.TRAINING_SUPPORT')}</strong>
                <br /> {t('RECRUITMENT.TRAINING_COURSE_DESCRIPTION')}
              </div>
              <div className="mb-2">
                <strong>{t('RECRUITMENT.MANAGEMENT_OPPORTUNITY')}</strong>
                <br /> {t('RECRUITMENT.MANAGEMENT_OPPORTUNITY_DESCRIPTION')}
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruitmentHeader;

import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Panel, PanelGroup, Table } from 'rsuite';
import styled from 'styled-components';

import { PanelContent } from '../../../components/Layout/styles';
import { Offer } from '../../../models/offers.model';
// import { Offer } from '../../../models/offers.model';
import { useBuildingType } from '../../OfferAdd/components/FormOptions/useBuildingType.hook';
import { useDriveType } from '../../OfferAdd/components/FormOptions/useDriveType.hook';
import { useIsulationType } from '../../OfferAdd/components/FormOptions/useIsulationType.hook';
import { useMaterialType } from '../../OfferAdd/components/FormOptions/useMaterialType.hook';
import { useNonRentFees } from '../../OfferAdd/components/FormOptions/useNonRentFees.hook';
import { useOfficeEquipment } from '../../OfferAdd/components/FormOptions/useOfficeEquipment.hook';
import { useOtherRoomsOptions } from '../../OfferAdd/components/FormOptions/useOtherRoomsOptions.hhok';
import { useOwnershipTypeOptions } from '../../OfferAdd/components/FormOptions/useOwnershipTypeOptions';
import { useSourrundingType } from '../../OfferAdd/components/FormOptions/useSourrundingType.hook';

import { SignInToShow } from './SignInToShow';

const PanelWrapper = styled.div`
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
`;

interface Props {
  offer: Offer;
  defaultExpanded?: boolean;
  isUserLoggedIn: boolean;
}
export const DetailsAndFunctions: React.FC<Props> = ({ offer, defaultExpanded = true, isUserLoggedIn }) => {
  const { t } = useTranslation();
  const officeEquipmentOptions = useOfficeEquipment();
  const nonRentFeesOptions = useNonRentFees();
  const ownershipTypeOptions = useOwnershipTypeOptions();
  const buildingTypeOptions = useBuildingType();
  const materialType = useMaterialType();
  const insulationTypeOptions = useIsulationType();
  const driveTypeOptions = useDriveType();
  const sourrundingTypeOptions = useSourrundingType();
  const otherRoomOptions = useOtherRoomsOptions();

  const nonRentFeeLabel =
    nonRentFeesOptions.find((option) => option.value === offer.leaseAgreement.nonRentFees)?.label ||
    offer.leaseAgreement.nonRentFees;

  // const receptionDate = offer.receptionTime
  //   ? `${dayjs(offer.receptionTime.date).format('DD.MM.YYYY')} ${
  //       offer.receptionTime?.hour ? dayjs(offer.receptionTime.hour).format('HH:mm') : ''
  //     }`
  //   : '';

  const determineRemainingDetailsStructured = (legalMainUse, relatedDetails) => {
    if (legalMainUse === 'Biurowe') {
      return [
        {
          id: '1',
          labelName: t('DETAILS.RAISED_FLOORS'),
          value: relatedDetails.raisedFloors ? t('YES') : t('NO'),
        },
        {
          id: '2',
          labelName: t('DETAILS.RAISED_CEILINGS'),
          value: relatedDetails.raisedCeilings ? t('YES') : t('NO'),
        },
        { id: '3', labelName: t('DETAILS.ALARM'), value: relatedDetails.alarm ? t('YES') : t('NO') },
        {
          id: '4',
          labelName: t('DETAILS.MONITORING'),
          value: relatedDetails.monitoring ? t('YES') : t('NO'),
        },
        {
          id: '5',
          labelName: t('DETAILS.OPENING_WINDOWS'),
          value: relatedDetails.openingWindows ? t('YES') : t('NO'),
        },
        {
          id: '6',
          labelName: t('DETAILS.OFFICE_EQUIPMENT'),
          value: (relatedDetails.officeEquipment || [])
            .map((item) => {
              const foundItem = officeEquipmentOptions.find((option) => option.value === item);
              return foundItem ? foundItem.label : item; // Jeśli znaleziono tłumaczenie, używamy go, inaczej oryginalną wartość
            })
            .join(', '),
        },
      ];
    } else if (legalMainUse === 'Handlowo-Usługowe') {
      return [
        {
          id: '1',
          labelName: t('DETAILS.ANY_ARRANGEMENT'),
          value: relatedDetails.anyArrangement ? t('YES') : t('NO'),
        },
        {
          id: '2',
          labelName: t('DETAILS.INDEPENDENT_VENTILATION'),
          value: relatedDetails.independentVentilationChannel ? t('YES') : t('NO'),
        },
        {
          id: '3',
          labelName: t('DETAILS.COLUMNS_GRID'),
          value:
            relatedDetails.columnsGridStructure?.x && relatedDetails.columnsGridStructure?.y
              ? `${relatedDetails.columnsGridStructure.x} cm na ${relatedDetails.columnsGridStructure.y} cm`
              : t('NO'),
        },
        {
          id: '4',
          labelName: t('DETAILS.NUMBER_OF_ENTRIES'),
          value: relatedDetails.numberOfEntries?.toString() || t('NO_ENTRIES'),
        },
      ];
    } else {
      return [
        {
          id: '1',
          labelName: t('DETAILS.WAREHOUSE_EQUIPMENT'),
          value: (relatedDetails.warehouseEquipment || []).join(', '),
        },
        { id: '2', labelName: t('DETAILS.HALL_HEIGHT'), value: `${relatedDetails.hallHeight} m` },
        { id: '3', labelName: t('DETAILS.CRANE'), value: relatedDetails.crane ? t('YES') : t('NO') },
        {
          id: '4',
          labelName: t('DETAILS.CAR_RAMP'),
          value: relatedDetails.carRamp ? t('YES') : t('NO'),
        },
        {
          id: '5',
          labelName: t('DETAILS.WAREHOUSE_HANDLING'),
          value: relatedDetails.warehouseHandling ? t('YES') : t('NO'),
        },
        {
          id: '6',
          labelName: t('DETAILS.HIGH_BAY_RACKING'),
          value: relatedDetails.highBayRacking ? t('YES') : t('NO'),
        },
        {
          id: '7',
          labelName: t('DETAILS.FORKLIFT'),
          value: relatedDetails.forklift ? t('YES') : t('NO'),
        },
        {
          id: '8',
          labelName: t('DETAILS.MECHANICAL_VENTILATION'),
          value: relatedDetails.mechanicalVentilation ? t('YES') : t('NO'),
        },
        {
          id: '9',
          labelName: t('DETAILS.GROUND_LEVEL_WAREHOUSE'),
          value: relatedDetails.groundLevelWarehouse ? t('YES') : t('NO'),
        },
        {
          id: '10',
          labelName: t('DETAILS.LIFT'),
          value: relatedDetails.lift
            ? `${t('YES')}, ${t('DETAILS.LIFT_CAPACITY')}: ${relatedDetails.liftCapacityKg} kg`
            : t('NO'),
        },
        {
          id: '11',
          labelName: t('DETAILS.WINDOW_OPENINGS'),
          value: relatedDetails.windowOpenings ? t('YES') : t('NO'),
        },
        {
          id: '12',
          labelName: t('DETAILS.DUST_FREE_FLOOR'),
          value: relatedDetails.dustFreeFloor ? t('YES') : t('NO'),
        },
        {
          id: '13',
          labelName: t('DETAILS.TIR_HALL_ENTRANCE'),
          value: relatedDetails.TIRHallEntrance ? t('YES') : t('NO'),
        },
        {
          id: '14',
          labelName: t('DETAILS.RAIL_RAMP'),
          value: relatedDetails.railRamp ? t('YES') : t('NO'),
        },
        {
          id: '15',
          labelName: t('DETAILS.NATURAL_VENTILATION'),
          value: relatedDetails.naturalVentilation ? t('YES') : t('NO'),
        },
        {
          id: '17',
          labelName: t('DETAILS.COLUMNS_GRID'),
          value:
            relatedDetails.columnsGridStructure?.x && relatedDetails.columnsGridStructure?.y
              ? `${relatedDetails.columnsGridStructure.x} cm na ${relatedDetails.columnsGridStructure.y} cm`
              : t('NO'),
        },
      ];
    }
  };

  const dataOthers = useMemo(
    () => determineRemainingDetailsStructured(offer.legalMainUse, offer.relatedDetails),
    [offer]
  );

  const dataMedia = useMemo(
    () => [
      {
        id: '1',
        labelName: t('DETAILS.POWER_ALLOCATION'),
        value: `${offer.mediaDetails.powerAllocation} kW`,
      },
      {
        id: '2',
        labelName: t('DETAILS.ENERGY_CATEGORY'),
        value: offer.mediaDetails.energyCategory,
      },
      {
        id: '3',
        labelName: t('DETAILS.AIR_CONDITIONING'),
        value: offer.mediaDetails.airConditioning ? t('YES') : t('NO'),
      },
      {
        id: '4',
        labelName: t('DETAILS.GRAVITY_VENTILATION'),
        value: offer.mediaDetails.gravityVentilation ? t('YES') : t('NO'),
      },
      {
        id: '5',
        labelName: t('DETAILS.HEAT_RECOVERY'),
        value: offer.mediaDetails.heatRecovery ? t('YES') : t('NO'),
      },
      {
        id: '6',
        labelName: t('DETAILS.TRANSFORMER_STATION'),
        value: offer.mediaDetails.transformerIn ? t('YES') : t('NO'),
      },
      {
        id: '7',
        labelName: t('DETAILS.DOUBLE_POWER_LINES'),
        value: offer.mediaDetails.doublePowerLines ? t('YES') : t('NO'),
      },
      {
        id: '8',
        labelName: t('DETAILS.WATER'),
        value: offer.mediaDetails.water ? t('YES') : t('NO'),
      },
      {
        id: '9',
        labelName: t('DETAILS.GAS'),
        value: offer.mediaDetails.gas ? t('YES') : t('NO'),
      },
      {
        id: '10',
        labelName: t('DETAILS.SEWAGE'),
        value: offer.mediaDetails.sewerage ? t('YES') : t('NO'),
      },
      {
        id: '11',
        labelName: t('DETAILS.HEATING'),
        value: offer.mediaDetails.heating ? t('YES') : t('NO'),
      },
      {
        id: '12',
        labelName: t('DETAILS.FIBER'),
        value: offer.mediaDetails.fiber ? t('YES') : t('NO'),
      },
      {
        id: '13',
        labelName: t('DETAILS.LAN'),
        value: offer.mediaDetails.LAN ? t('YES') : t('NO'),
      },
      {
        id: '14',
        labelName: t('DETAILS.PHONE_LINE'),
        value: offer.mediaDetails.phoneLine ? t('YES') : t('NO'),
      },
    ],
    [offer, t]
  );

  const dataParking = useMemo(
    () => [
      {
        id: '1',
        labelName: t('DETAILS.PARKING.PARKING_SURFACE'),
        value: `${offer.parkingDetails.parkingSurface} m²`,
      },
      {
        id: '2',
        labelName: t('DETAILS.PARKING.TOTAL_PARKING_SPOTS'),
        value: `${offer.parkingDetails.totalParkingSurface}`,
      },
      {
        id: '3',
        labelName: t('DETAILS.PARKING.GUARDED_PARKING'),
        value: offer.parkingDetails.guardedParking ? t('YES') : t('NO'),
      },
      {
        id: '4',
        labelName: t('DETAILS.PARKING.PARKING_LOTS_AVAILABLE'),
        value: `${offer.parkingDetails.parkingLotsAvailable}`,
      },
    ],
    [offer, t]
  );
  const dataFinancial = useMemo(
    () => [
      {
        id: '1',
        labelName: t('DETAILS.FINANCIAL.RENT_NET'),
        value: `${offer.price} zł`,
      },
      {
        id: '2',
        labelName: t('DETAILS.FINANCIAL.DEPOSIT_WITH_VAT'),
        value: `${offer.leaseAgreement.deposit} zł`,
      },
      {
        id: '3',
        labelName: t('DETAILS.FINANCIAL.RENT_PAYMENT_DEADLINE'),
        value: `${offer.leaseAgreement.payDay}`,
      },
      {
        id: '4',
        labelName: t('DETAILS.FINANCIAL.FIRST_RENT_PAYMENT'),
        value: `${offer.leaseAgreement.firstPaymentDay}`,
      },
      {
        id: '5',
        labelName: t('DETAILS.FINANCIAL.NON_RENT_FEES'),
        value:
          `${nonRentFeeLabel}` + // Zastąpienie `offer.leaseAgreement.nonRentFees` dynamiczną etykietą
          `${
            offer.leaseAgreement.nonRentFees === 'Ryczałtowe'
              ? ` (${t('DETAILS.FINANCIAL.LUMP_SUM')}: ${offer.leaseAgreement.lumpSum} zł)`
              : ''
          }` +
          `${
            offer.leaseAgreement.nonRentFees === 'Licznikowe'
              ? offer.leaseAgreement.averageMonthlyCostsAnnualizedKnown
                ? ` (${t('DETAILS.FINANCIAL.UNKNOWN_ANNUAL_COSTS')})`
                : ` (${t('DETAILS.FINANCIAL.ANNUAL_COSTS')}: ${offer.leaseAgreement.averageMonthlyCostsAnnualized} ${
                    offer.priceCurrency
                  } [${t('GROSS')}])`
              : ''
          }`,
      },
    ],
    [offer, t]
  );

  const dataLease = useMemo(
    () => [
      {
        id: '1',
        labelName: t('DETAILS.LEASE.OFFER_VALID_UNTIL'),
        value: `${formatDate(offer.offerValidTo, 'date')}`,
      },
      {
        id: '2',
        labelName: t('DETAILS.LEASE.AVAILABILITY'),
        value: `${formatDate(offer.availability, 'date')} - ${
          offer.availabilityTo ? formatDate(offer.availabilityTo, 'date') : t('DETAILS.LEASE.NOT_SPECIFIED')
        }`,
      },
      {
        id: '3',
        labelName: t('DETAILS.LEASE.UNIT_RECEIPT_DEADLINE'),
        value: `${offer.unitReceiptDeadline} ${t('DETAILS.LEASE.DAYS')}`,
      },
      {
        id: '4',
        labelName: t('DETAILS.LEASE.LEASE_TERM'),
        value:
          `${
            offer.leaseAgreement.infinite
              ? `${t('DETAILS.LEASE.INDEFINITE')}: ${
                  offer.leaseAgreement.leaseNoticePeriod ?? t('DETAILS.LEASE.NOT_SPECIFIED')
                } ${t('DETAILS.LEASE.MONTHS_NOTICE')}`
              : ''
          }` +
          `${
            offer.leaseAgreement.fixedshort
              ? `${offer.leaseAgreement.infinite ? ', ' : ''}- ${offer.leaseAgreement.fixedshortMonths || 'X'} ${t(
                  'DETAILS.LEASE.MONTHS'
                )}`
              : ''
          }` +
          `${
            offer.leaseAgreement.fixedlong
              ? `${offer.leaseAgreement.infinite || offer.leaseAgreement.fixedshort ? ', ' : ''}- ${
                  offer.leaseAgreement.fixedlongMonths || 'Y'
                } ${t('DETAILS.LEASE.MONTHS')}`
              : ''
          }`,
      },
      {
        id: '5',
        labelName: t('DETAILS.LEASE.OWN_TERMS_ATTACHED'),
        value: `${offer.ownTerms.selected ? t('YES') : t('NO')}`,
      },
      // offer.offerStatus.state == OfferStatusState.SIGNED && {
      //   id: '6',
      //   labelName: 'Data i godzina przekazania',
      //   value: receptionDate || '',
      // },
      // {
      //   id: '6',
      //   labelName: 'Włączona opcja kontroferty',
      //   value: `${offer.counteroffer.selected ? 'Tak' : 'Nie'}`,
      // },
    ],
    [offer, t]
  );
  const dataAddress = useMemo(
    () => [
      {
        id: '1',
        labelName: t('ADDRESS.PROVINCE'),
        value: offer.address.officialAddress.province,
      },
      {
        id: '2',
        labelName: t('ADDRESS.COUNTY'),
        value: offer.address.officialAddress.county,
      },
      {
        id: '3',
        labelName: t('ADDRESS.DISTRICT'),
        value: offer.address.officialAddress.district,
      },
      {
        id: '4',
        labelName: t('ADDRESS.ESTATE'),
        value: offer.address.officialAddress.estate,
      },
      {
        id: '5',
        labelName: t('ADDRESS.STREET'),
        value: offer.address.officialAddress.street,
      },
      {
        id: '6',
        labelName: t('ADDRESS.HOUSE_NUMBER'),
        value: offer.address.officialAddress.houseNumber,
      },
    ],
    [offer, t]
  );

  const dataSurfaces = useMemo(
    () => [
      {
        id: '1',
        labelName: t('DETAILS.SURFACE.USABLE_AREA_FOR_RENT'),
        value: `${offer.areaDetails.surface ?? '0'} m²`,
      },
      {
        id: '6',
        labelName: t('DETAILS.SURFACE.TOTAL_BUILDING_AREA'),
        value: `${offer.areaDetails.totalSurface ?? '0'} m²`,
      },
      {
        id: '8',
        labelName: t('DETAILS.SURFACE.PLOT_NUMBER'),
        value: offer.address.plotNumber,
      },
      {
        id: '9',
        labelName: t('DETAILS.SURFACE.LAND_AND_MORTGAGE_REGISTER_NUMBER'),
        value: offer.address.landAndMortgageRegisterNumber,
      },
      {
        id: '9.5',
        labelName: t('DETAILS.SURFACE.OWNERSHIP_TYPE'),
        value: (function () {
          const foundOption = ownershipTypeOptions.find((option) => option.value === offer.address.ownershipType);
          return foundOption ? foundOption.label : offer.address.ownershipType;
        })(),
      },
      {
        id: '10',
        labelName: t('DETAILS.SURFACE.BUILDING_TYPE'),
        value: (function () {
          const foundOption = buildingTypeOptions.find((option) => option.value === offer.areaDetails.buildingType);
          return foundOption ? foundOption.label : offer.areaDetails.buildingType;
        })(),
      },
      {
        id: '11',
        labelName: t('DETAILS.SURFACE.CONSTRUCTION_MATERIAL'),
        value: (function () {
          return (offer.areaDetails.constructionMaterial || [])
            .map((material) => {
              const foundOption = materialType.find((option) => option.value === material);
              return foundOption ? foundOption.label : material; // If translation is found, return the label, otherwise return the original value
            })
            .join(', '); // Join the array to display materials as a comma-separated string
        })(),
      },
      {
        id: '14',
        labelName: t('DETAILS.SURFACE.THERMAL_INSULATION'),
        value: (function () {
          return (offer.areaDetails.thermalInsulation || [])
            .map((insulation) => {
              const foundOption = insulationTypeOptions.find((option) => option.value === insulation);
              return foundOption ? foundOption.label : insulation; // If translation is found, use it; otherwise, display the original value
            })
            .join(', '); // Join the array into a comma-separated string
        })(),
      },
      {
        id: '15',
        labelName: t('DETAILS.SURFACE.CONSTRUCTION_COMPLETION_YEAR'),
        value: offer.areaDetails.constructionCompletionYear,
      },
      {
        id: '16',
        labelName: t('DETAILS.SURFACE.LAND_AREA'),
        value: `${offer.areaDetails.landArea} m²`,
      },
      {
        id: '17',
        labelName: t('DETAILS.SURFACE.DRIVE'),
        value: (offer.areaDetails.drive || [])
          .map((driveType) => {
            const foundOption = driveTypeOptions.find((option) => option.value === driveType);
            return foundOption ? foundOption.label : driveType; // If translation is found, use it; otherwise, display the original value
          })
          .join(', '),
      },
      {
        id: '18',
        labelName: t('DETAILS.SURFACE.SURROUNDINGS'),
        value: (offer.areaDetails.surroundings || [])
          .map((surroundingType) => {
            const foundOption = sourrundingTypeOptions.find((option) => option.value === surroundingType);
            return foundOption ? foundOption.label : surroundingType; // If translation is found, use it; otherwise, display the original value
          })
          .join(', '),
      },
      {
        id: '19',
        labelName: t('DETAILS.SURFACE.PUBLIC_TRANSPORT_DISTANCE'),
        value: offer.areaDetails.publicTransportDistance + ' m',
      },
    ],
    [offer, t]
  );

  const roomMapper = (rooms, label, parentId, idxStart) => {
    return {
      id: `${parentId}-${idxStart}`,
      labelName: label,
      value: '',
      children: rooms.map((room, idx) => {
        return {
          id: `${parentId}-${idxStart}-${idx + 1}`,
          labelName: room.type || '',
          value: `${room.surface || ''} m²  ${room.windows ? t('WITH_WINDOWS') : t('WITHOUT_WINDOWS')}`,
        };
      }),
    };
  };

  const dataLevels = useMemo(
    () => [
      {
        id: '1',
        labelName: t('LEVELS.NUMBER_OF_LEVELS'),
        value: offer.areaDetails.levels.length,
        children: offer.areaDetails.levels
          .sort((a, b) => (a.levelNumber < b.levelNumber ? -1 : 1))
          .map((o, i) => {
            const children = [
              {
                id: `1-${i + 1}-1`,
                labelName: t('LEVELS.TOTAL_AREA_OF_LEVEL'),
                value: `${o.surface} m²`,
              },
              {
                id: `1-${i + 1}-2`,
                labelName: t('LEVELS.HEIGHT'),
                value: `${o.height} cm`,
              },
              {
                id: `1-${i + 1}-4`,
                labelName: t('LEVELS.NUMBER_OF_STAIRCASES'),
                value: `${o.numberOfStaircases} szt.`,
              },
              {
                id: `1-${i + 1}-5`,
                labelName: t('LEVELS.DISABLED_ACCESS'),
                value: o.accessibility ? t('YES') : t('NO'),
              },
              {
                id: `1-${i + 1}-6`,
                labelName: t('LEVELS.SHARED_TOILETS_OUTSIDE_PREMISE'),
                value: o.toiletsLocation ? t('YES') : t('NO'),
              },
              {
                id: `1-${i + 1}-7`,
                labelName: t('LEVELS.OTHER_ROOMS'),
                value: o.otherRooms
                  ? o.otherRoomsDetails
                      .map((r) => {
                        const foundOption = otherRoomOptions.find((option) => option.value === r);
                        return foundOption ? foundOption.label : r;
                      })
                      .join(', ')
                  : t('LEVELS.NO_OTHER_ROOMS'),
              },
            ];

            let idxStart = children.length + 1;

            if (o.commercialAndServiceRooms && o.commercialAndServiceRooms.length > 0) {
              children.push(
                roomMapper(
                  o.commercialAndServiceRooms.map((room) => ({
                    ...room,
                    type: t(`FORMS.OFFER_ADD.COMMERCIAL_ROOM_TYPE.${room.type.toUpperCase()}`),
                    windows: room.windows ? t('FORMS.OFFER_ADD.WITH_WINDOWS') : t('FORMS.OFFER_ADD.WITHOUT_WINDOWS'),
                  })),
                  t('LEVELS.COMMERCIAL_AND_SERVICE_ROOMS'),
                  `1-${i + 1}`,
                  idxStart++
                )
              );
            }

            if (o.offices && o.offices.length > 0) {
              children.push(
                roomMapper(
                  o.offices.map((office) => ({
                    ...office,
                    type: t(`FORMS.OFFER_ADD.OFFICE_TYPE.${office.type.toUpperCase()}`),
                    windows: office.windows ? t('FORMS.OFFER_ADD.WITH_WINDOWS') : t('FORMS.OFFER_ADD.WITHOUT_WINDOWS'),
                  })),
                  t('LEVELS.OFFICE_ROOMS'),
                  `1-${i + 1}`,
                  idxStart++
                )
              );
            }

            if (o.technicalRooms && o.technicalRooms.length > 0) {
              children.push(
                roomMapper(
                  o.technicalRooms.map((room) => ({
                    ...room,
                    type: t(`FORMS.OFFER_ADD.TECHNICAL_ROOM_TYPE.${room.type.toUpperCase()}`), // Mapowanie tłumaczenia dla typów pomieszczeń technicznych
                    windows: room.windows ? t('FORMS.OFFER_ADD.WITH_WINDOWS') : t('FORMS.OFFER_ADD.WITHOUT_WINDOWS'), // Mapowanie tłumaczenia dla okien
                  })),
                  t('LEVELS.TECHNICAL_ROOMS'),
                  `1-${i + 1}`,
                  idxStart++
                )
              );
            }

            if (o.warehouses && o.warehouses.length > 0) {
              children.push(
                roomMapper(
                  o.warehouses.map((room) => ({
                    ...room,
                    type: t(`FORMS.OFFER_ADD.WAREHOUSE_ROOM_TYPE.${room.type.toUpperCase()}`), // Mapowanie tłumaczenia dla typów pomieszczeń magazynowych
                    windows: room.windows ? t('FORMS.OFFER_ADD.WITH_WINDOWS') : t('FORMS.OFFER_ADD.WITHOUT_WINDOWS'), // Mapowanie tłumaczenia dla okien
                  })),
                  t('LEVELS.WAREHOUSE_ROOMS'),
                  `1-${i + 1}`,
                  idxStart++
                )
              );
            }

            return {
              id: `1-${i + 1}`,
              labelName: o.levelName,
              value: o.levelNumber,
              children: children,
            };
          }),
      },
    ],
    [offer, t]
  );

  const dataLegal = useMemo(
    () => [
      {
        id: '1',
        labelName: t('DETAILS.LEGAL.MAIN_USE'),
        value: t(`FORMS.PREMISES_PURPOSE.VALUES.${offer.legalMainUse.toUpperCase()}`) || offer.legalMainUse,
      },
      {
        id: '2',
        labelName: t('DETAILS.LEGAL.ACCEPTED_USE'),
        value: offer.legalWarehouseUseAccepted
          .map((use) => t(`FORMS.PREMISES_PURPOSE.OPTIONS.${use.toUpperCase()}`) || use)
          .join(', '),
      },
      {
        id: '3',
        labelName: t('DETAILS.LEGAL.OFFICIAL_USE'),
        value: offer.legalWarehouseUseOfficial
          .map((use) => t(`FORMS.PREMISES_PURPOSE.OPTIONS.${use.toUpperCase()}`) || use)
          .join(', '),
      },
    ],
    [offer, t]
  );

  return (
    <PanelWrapper>
      <PanelGroup accordion>
        <Panel header={t('HEADING.ADDRESS')} collapsible bodyFill defaultExpanded>
          <PanelContent>
            <TableTree data={dataAddress} />
          </PanelContent>
        </Panel>
        <Panel header={t('HEADING.LEGAL')} collapsible bodyFill defaultExpanded>
          <PanelContent>
            <TableTree data={dataLegal} />
          </PanelContent>
        </Panel>
        <Panel header={t('HEADING.BUILDING')} collapsible bodyFill defaultExpanded={defaultExpanded}>
          <PanelContent>
            <TableTree data={dataSurfaces} />
          </PanelContent>
        </Panel>
        <Panel header={t('HEADING.LEVELS')} collapsible bodyFill defaultExpanded={defaultExpanded}>
          <PanelContent>
            <TableTree data={dataLevels} />
          </PanelContent>
        </Panel>
        <Panel header={t('HEADING.PARKING')} collapsible bodyFill defaultExpanded={defaultExpanded}>
          <PanelContent>
            <TableTree data={dataParking} />
          </PanelContent>
        </Panel>
        <Panel header={t('HEADING.MEDIA')} collapsible bodyFill defaultExpanded={defaultExpanded}>
          <PanelContent>
            <TableTree data={dataMedia} />
          </PanelContent>
        </Panel>
        <Panel header={t('HEADING.OTHER')} collapsible bodyFill defaultExpanded={defaultExpanded}>
          <PanelContent>
            <TableTree data={dataOthers} />
          </PanelContent>
        </Panel>
        <Panel header={t('HEADING.FINANCIAL_ISSUES')} collapsible bodyFill defaultExpanded={defaultExpanded}>
          <PanelContent>
            {isUserLoggedIn ? (
              <TableTree data={dataFinancial} />
            ) : (
              <SignInToShow text={t('SIGN_IN_TO_SHOW.DEFAULT_TEXT')} rows={6} />
            )}
          </PanelContent>
        </Panel>
        <Panel header={t('HEADING.LEASE_ISSUES')} collapsible bodyFill defaultExpanded={defaultExpanded}>
          <PanelContent>
            {isUserLoggedIn ? (
              <TableTree data={dataLease} />
            ) : (
              <SignInToShow text={t('SIGN_IN_TO_SHOW.DEFAULT_TEXT')} rows={6} />
            )}
          </PanelContent>
        </Panel>
      </PanelGroup>
    </PanelWrapper>
  );
};

const TableTree: React.FC<{ data: any[] }> = ({ data }) => {
  return (
    <Table isTree defaultExpandAllRows rowKey="id" autoHeight wordWrap width={800} data={data} headerHeight={0}>
      <Table.Column width={320}>
        <Table.HeaderCell />
        <Table.Cell dataKey="labelName" />
      </Table.Column>

      <Table.Column width={480}>
        <Table.HeaderCell />
        <Table.Cell dataKey="value" />
      </Table.Column>
    </Table>
  );
};

export function formatDate(dateString: Date, type = 'time'): string {
  let dateOptions: any;
  if (type === 'date') {
    dateOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
  } else {
    dateOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };
  }
  const date = new Date(dateString);
  return date.toLocaleString('pl-PL', dateOptions);
}

import { Collapse } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizedLabelWithMultipleLinks } from '../../OfferAdd/utils/LocalizedLabelWithMultipleLinks';

import { Title } from './FAQ';

const { Panel } = Collapse;

export const Administration: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Administracja */}
      <div>
        <Title>{t('FAQ.ADMINISTRATION.TITLE')}</Title>
        <Collapse>
          <Panel header={t('FAQ.ADMINISTRATION.CHECK_SIGNATURE_HEADER')} key="20">
            <p>
              {t('FAQ.ADMINISTRATION.CHECK_SIGNATURE_TEXT', {
                interpolation: { escapeValue: false },
              })}
              <LocalizedLabelWithMultipleLinks
                i18nKey="FAQ.ADMINISTRATION.CHECK_SIGNATURE_LINK"
                linkPaths={{
                  '1': 'https://moj.gov.pl/nforms/signer/upload?xFormsAppName=SIGNER',
                }}
              />
            </p>
          </Panel>
          <Panel header={t('FAQ.ADMINISTRATION.REJECTED_SIGNATURE_HEADER')} key="21">
            <p>
              {t('FAQ.ADMINISTRATION.REJECTED_SIGNATURE_TEXT', {
                interpolation: { escapeValue: false },
              })}
              <LocalizedLabelWithMultipleLinks
                i18nKey="FAQ.ADMINISTRATION.REJECTED_SIGNATURE_LINK"
                linkPaths={{
                  '1': '/moja-tablica/account/info',
                }}
              />
            </p>
          </Panel>
          <Panel header={t('FAQ.ADMINISTRATION.UNCONFIRMED_COMPANY_HEADER')} key="22">
            <p>{t('FAQ.ADMINISTRATION.UNCONFIRMED_COMPANY_TEXT')}</p>
          </Panel>
        </Collapse>
      </div>
    </>
  );
};

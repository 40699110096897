import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const InfoBeta: React.FC<Props> = () => {
  return (
    <div className="z-50 bg-gray-500 text-center text-white transition-opacity duration-300 show">
      Korzystasz z wersji beta aplikacji. Bądź świadom/a możliwych błędów.
    </div>
  );
};

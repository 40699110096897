import firebase from 'firebase/app';
import { useEffect, useState } from 'react';

import { CompanyData } from '../../features/MojaTablica/Account/Identity/LinkCompany/company.models';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

export function useGetCompanies(uid: string | null): [CompanyData[], boolean, firebase.FirebaseError | null] {
  const [companies, setCompanies] = useState<CompanyData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<firebase.FirebaseError | null>(null);

  useEffect(() => {
    if (!uid) {
      setLoading(false);
      setCompanies([]);
      return;
    }

    const unsubscribe = firestore
      .collection(FirestoreCollection.COMPANIES)
      .where('hasAccess', 'array-contains', uid)
      .onSnapshot(
        (snapshot) => {
          const fetchedCompanies: CompanyData[] = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as CompanyData[];
          setCompanies(fetchedCompanies);
          setLoading(false);
        },
        (e) => {
          console.error('Error fetching companies:', e);
          setError(e);
          setLoading(false);
        }
      );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [uid]);

  return [companies, loading, error];
}

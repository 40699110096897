import { ToastVariant } from '../../components/Toast/toast.model';
import { toastService } from '../../components/Toast/toast.service';
import { Offer } from '../../models/offers.model';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

export async function setProjectOffer(
  data: Offer,
  setSaved?: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> {
  try {
    const docRef = firestore.collection(FirestoreCollection.OFFERS).doc(data.id);
    docRef.set(data);
    setSaved ? setSaved(true) : null;
  } catch (error) {
    toastService.show(error, 'Błąd', { variant: ToastVariant.ERROR });
    console.error('Wystąpił błąd podczas zapisywania dokumentu:', error);
  }
}

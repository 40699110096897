import * as React from 'react';
import styled from 'styled-components';

import { useAuth } from '../../../features/Auth/AuthContext';
import { addCurrency } from '../../../utils/currency.utils';
import { Typography } from '../../Typography/Typography';

const Price = styled(Typography.H5)`
  color: ${({ theme }) => theme.textGreen};
`;
const Label = styled.sub`
  bottom: -0.25em;
  font-size: 15px;
  font-weight: 400;
  color: #929fc5;
`;

interface Props {
  price: number;
  className?: string;
}
export const OfferPrice: React.FC<Props> = ({ className, price }) => {
  const { isAuth } = useAuth();
  return (
    <Price className={className}>
      {isAuth && (
        <>
          {addCurrency(price)}{' '}
          <Label>
            <span className="text-green-600">/netto</span>
          </Label>
        </>
      )}
    </Price>
  );
};

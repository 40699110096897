export enum AppRoutes {
  HOME = '/',
  OFFERS = '/offers',
  RECRUITMENT = '/rekrutacja',
  SUPPORT = '/pomoc',
  FAQ = '/faq',
  OFFER_PREVIEW = '/offer/preview/:id',
  OFFER_ROOT = '/offer',
  OFFER = '/offer/:id',
  ADD_OFFER = '/add-offer/:id',
  ADD_OFFER_CONFIRM = '/add-offer/confirm/:id',
  ADD_OFFER_VERIFY = '/add-offer/verify/:id',
  EDIT_OFFER = '/edit-offer/:id/:value/:uid',
  OFFER_SUBMIT_CONFIRM = '/contract/:id/submit/:userUid',
  OFFER_SUBMIT_VERIFY = '/contract/:id/verify',
  MOJA_TABLICA = '/moja-tablica',

  MOJA_TABLICA_ACTIVE_OFFERS = '/moja-tablica/my-offers/active-offers',
  MOJA_TABLICA_UNSIGNED_OFFERS = '/moja-tablica/my-offers/unsigned-offers',
  MOJA_TABLICA_SIGNED_OFFERS = '/moja-tablica/my-offers/signed-offers',
  MOJA_TABLICA_BOOKED_OFFERS = '/moja-tablica/my-offers/booked-offers',
  MOJA_TABLICA_FAVORITES = '/moja-tablica/favorites',

  MOJA_TABLICA_ACCOUNT_INFO = '/moja-tablica/account/info',
  MOJA_TABLICA_SECURITY = '/moja-tablica/account/security',
  MOJA_TABLICA_CONFIRM_IDENTITY = '/moja-tablica/account/confirm-identity',
  MOJA_TABLICA_LINK_COMPANY = '/moja-tablica/account/link-company',
  MOJA_TABLICA_NOTIFICATIONS = '/moja-tablica/advanced/notifications',
  MOJA_TABLICA_SUPPORT = '/moja-tablica/advanced/support',
  MOJA_TABLICA_REPORT = '/moja-tablica/advanced/report',
  CONFIRM_DELETE_ACCOUNT = '/account/:id/confirm-delete/',
  INDICATE_TRANSFER_PERSON = '/indicate-transfer-person/:id/:userUid',
  OFFER_DEPOSIT = '/deposit/:id/',

  PROTOCOL = '/protocol/:id',

  EMAIL_VIEW = '/mail/:emailId/:accessToken',
  DOCUMENT = '/view-document/:documentPath',

  // ADMINISTRATOR
  ADMIN = '/admin',
}

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../models/main.model';

export function useOwnershipTypeOptions(): SelectOption[] {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: t('OWNERSHIP.OWNERSHIP'), value: 'Własność' },
      { label: t('OWNERSHIP.CO_OWNERSHIP'), value: 'Współwłasność' },
      { label: t('OWNERSHIP.COOPERATIVE_RIGHT'), value: 'Spółdzielcze prawo do lokalu' },
      { label: t('OWNERSHIP.RENT_AGREEMENT'), value: 'Umowa najmu' },
      { label: t('OWNERSHIP.LEASE_AGREEMENT'), value: 'Umowa dzierżawy' },
      { label: t('OWNERSHIP.USUFRUCT_AGREEMENT'), value: 'Umowa użytkowania' },
      { label: t('OWNERSHIP.LENDING_AGREEMENT'), value: 'Umowa użyczenia' },
      { label: t('OWNERSHIP.LEASING_AGREEMENT'), value: 'Umowa leasingu' },
    ],
    [t]
  );
}

import { Button, Modal, Typography } from 'antd';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';
import { Col } from 'rsuite';

export const SPECIAL_OFFER_KEY = 'specialOffer100';
const { Paragraph } = Typography;

export const SpecialOffer100: React.FC = () => {
  const [cookie, setCookie] = useCookies([SPECIAL_OFFER_KEY]);
  // console.log('specialOffer100', cookie.specialOffer100);
  const [isVisible, setIsVisible] = useState(false);
  const { push } = useHistory();

  const giveCookieConsent = (value) => {
    setCookie(SPECIAL_OFFER_KEY, value, { path: '/' });
  };

  // useEffect(() => {
  //   if (!isAuth && cookie[SPECIAL_OFFER_KEY] !== 'OK') {
  //     giveCookieConsent('SHOW');
  //   }
  // }, [isAuth, cookie, setCookie]);

  const showModalWithDelay = useCallback(() => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        setIsVisible(false); // zmieni na true, zeby włączyc
        resolve();
      }, 15000);
    });
  }, []);

  useEffect(() => {
    if (cookie[SPECIAL_OFFER_KEY] === 'SHOW') {
      showModalWithDelay();
    } else if (cookie[SPECIAL_OFFER_KEY] === 'OK') {
      setIsVisible(false);
    }
  }, [cookie, showModalWithDelay]);

  const handleOk = () => {
    giveCookieConsent('OK');
    setIsVisible(false);
  };

  return (
    <>
      <Modal
        open={isVisible}
        title={'Uwaga!'}
        onOk={handleOk}
        onCancel={handleOk}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}>
        <Paragraph>
          Promocja dla pierwszych 100 klientów! Wprowadź swoją ofertę i wypełnij formularz, aby otrzymać darmowe
          transakcje. To wyjątkowa okazja, aby skorzystać z naszych usług bez żadnych opłat.
        </Paragraph>
        <div className={'text-lg opacity-80 mx-auto w-full'}>
          <Col className="flex flex-col justify-center">
            <div className="mx-auto m-4">Nie przegap tej szansy!</div>
            <Button onClick={() => push('https://google.com')} type="primary" className="mt-8" disabled>
              Link do ankiety (wkrótce)
            </Button>
          </Col>
        </div>
      </Modal>
    </>
  );
};

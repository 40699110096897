import type { InputRef } from 'antd';
import { Button, Card, Input, Space, Typography } from 'antd';
import React, { useRef } from 'react';

import { firebaseFunctions } from '../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../firebase/firebase.models';

import { AdminApp } from './AdminApp';
import { NotificationType, openNotification } from './components/openNotification';

const { Title, Paragraph } = Typography;

export const AdminHome: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState('');

  const inputRef = useRef<InputRef>(null);

  const addAdminClaim = async () => {
    setLoading(true);
    try {
      await firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ADD_ADMIN_CLAIM)({ email: userEmail });
      openNotification(NotificationType.SUCCESS, 'Admin claim added successfully');

      if (inputRef.current) {
        inputRef.current.input.value = '';
      }
    } catch (error: any) {
      openNotification(NotificationType.ERROR, 'Error while adding admin claim - see console for more details');
      console.error('Error while adding admin claim:', error);
    }
    setLoading(false);
    setUserEmail('');
  };

  return (
    <AdminApp>
      <Card>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Title level={4}>Welcome to the Admin Home Page</Title>
          <Paragraph>
            This is the admin dashboard where you can manage the application. Enter an email below to give admin
            privileges.
          </Paragraph>
          <Input
            ref={inputRef}
            placeholder="Enter email"
            name="email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
          <Button type="primary" onClick={addAdminClaim} loading={loading} block>
            Add Admin
          </Button>
        </Space>
      </Card>
    </AdminApp>
  );
};

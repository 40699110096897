import { EnvironmentOutlined } from '@ant-design/icons';
import * as React from 'react';

import { Offer } from '../../../models/offers.model';

interface Props {
  offer: Offer;
  onLocationClick: ((id: string) => void) | null;
}
export const OfferLocationButton: React.FC<Props> = ({ offer, onLocationClick }) => {
  return (
    <div onClick={() => onLocationClick?.(offer.id)} className="w-full mt-8">
      <div className=" text-blue-500 hover:opacity-100 flex items-center opacity-80 mt-1">
        <EnvironmentOutlined className="text-lg mr-2" />
        {offer.address.street
          ? `${offer.address.street}, ${offer.address.postCode} ${offer.address.city}`
          : 'brak informacji'}
      </div>
    </div>
  );
};

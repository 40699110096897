import firebase from 'firebase';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

export const firebaseApp: firebase.app.App = firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});
export const firebaseAuth: firebase.auth.Auth = firebaseApp.auth();
export const firestore = firebaseApp.firestore();
export const firebaseStorage = firebaseApp.storage();
export const firebaseFunctions = firebaseApp.functions();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const analytics = firebase.analytics();

// console.log({
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID,
// });

if (process.env.NODE_ENV == 'development') {
  // firebaseFunctions.useEmulator('localhost', 5001);
  // firestore.useEmulator('localhost', 8080);
  // firebaseStorage.useEmulator('localhost', 9199);
  // firebaseAuth.useEmulator('http://localhost:9099');
}

import firebase from 'firebase';
import { useCallback, useState } from 'react';

import { SHOW_LOGS } from '../../features/App/App';
import { useAuth } from '../../features/Auth/AuthContext';
import { Offer, OfferStatusState } from '../../models/offers.model';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

import { useGetCompanyData } from './getCompanyData.hook';
import { useProcessDates } from './useProcessDates.hook';

export function useGetBookedOffersByUid(): [Offer[], () => void, boolean, firebase.FirebaseError | null] {
  const [error, setError] = useState<firebase.FirebaseError | null>(null);
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState<Offer[]>([]);
  const { currentUser } = useAuth();
  const { companyData } = useGetCompanyData(currentUser?.uid);
  SHOW_LOGS && console.log('companyData:', companyData);

  const getBookedOffersByUid = useCallback(async () => {
    if (!companyData || !currentUser) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const queries = getOffersQueries(companyData);

      // Zapytanie dotyczące ofert najemcy
      const bookedByQuery = firestore
        .collection(FirestoreCollection.OFFERS)
        .where('offerStatus.state', '==', OfferStatusState.BOOKED)
        .where('offerStatus.tenant.companyId', '==', companyData?.id || 'INVALID_ID');

      // Wykonaj wszystkie zapytania równolegle, dodając `bookedByQuery.get()` do tablicy
      const querySnapshots = await Promise.all([...queries.map((query) => query.get()), bookedByQuery.get()]);

      // Przetwórz wyniki
      const combinedOffers: Offer[] = [];
      querySnapshots.forEach((snapshot) => {
        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          combinedOffers.push({
            id: doc.id,
            ...data,
            ...useProcessDates({
              availability: data.availability,
              availabilityTo: data.availabilityTo,
              offerValidTo: data.offerValidTo,
            }),
          } as Offer);
        });
      });

      // Usuń duplikaty (opcjonalnie, jeśli jest to potrzebne)
      const uniqueOffers = Array.from(new Map(combinedOffers.map((offer) => [offer.id, offer])).values());

      SHOW_LOGS && console.log('Unique Booked Offers:', uniqueOffers);
      setOffers(uniqueOffers);
    } catch (error: any) {
      console.error(error);
      setError(error as firebase.FirebaseError);
    } finally {
      setLoading(false);
    }
  }, [companyData, currentUser]);

  return [offers, getBookedOffersByUid, loading, error];
}

// Helper function to get queries in chunks
function getOffersQueries(companyData) {
  const offers = Array.isArray(companyData?.offers) && companyData.offers.length > 0 ? companyData.offers : [];

  const chunkSize = 10;
  const chunks = [];
  for (let i = 0; i < offers.length; i += chunkSize) {
    chunks.push(offers.slice(i, i + chunkSize));
  }

  // Stwórz zapytania dla każdej części
  return chunks.map((chunk) =>
    firestore
      .collection(FirestoreCollection.OFFERS)
      .where('id', 'in', chunk)
      .where('offerStatus.state', '==', OfferStatusState.BOOKED)
  );
}

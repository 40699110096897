import { Alert, Button, Space, Spin } from 'antd';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { ProgressConfigType } from '../../components/OfferRelatedWrapper/OfferProgress';
import { OfferRelatedWrapper } from '../../components/OfferRelatedWrapper/OfferRelatedWrapper';
import { ToastVariant } from '../../components/Toast/toast.model';
import { toastService } from '../../components/Toast/toast.service';
import { onLogOfferAddConfirm } from '../../events/hooks/onLogOfferAddConfirm.hook';
import { firebaseFunctions } from '../../firebase/firebase';
import { FirebaseCallableFunctions, FunctionAddOfferPayload } from '../../firebase/firebase.models';
import { useGetOffer } from '../../firebase/hooks/getOfferById.hook';
import { OfferCheck, useUpdateOfferCheck } from '../../firebase/hooks/updateOfferCheck.hook';
import { useGetOfferImages } from '../../hooks/getOfferImages.hook';
import { OfferStatusState } from '../../models/offers.model';
import { App } from '../App/App';
import { useAuth } from '../Auth/AuthContext';
import { AppRoutes } from '../Routing/routing.model';

import { createPDFs } from './Documents/createPDFs';
import { ConfigType } from './Documents/documents.models';

interface Props {
  offerId: string;
}

export const OfferAddConfirm: React.FC<Props> = ({ offerId }) => {
  const { isAuthenticating, isAuth } = useAuth();
  return <App>{!isAuthenticating && isAuth ? <WhenAuth offerId={offerId} /> : <WhenNotAuth />}</App>;
};

const WhenAuth: React.FC<Props> = ({ offerId }) => {
  const { push } = useHistory();

  const isLoading = useRef(false);
  const [offer] = useGetOffer(offerId);
  const imagesUrl = useGetOfferImages(offerId);

  const [errorMessage, setErrorMessage] = useState('');
  const [showLoadingOffer, setShowLoadingOffer] = useState(true);
  const { t } = useTranslation();

  const handleOfferConfirmation = async () => {
    try {
      // Fake step: Sprawdzanie bezpieczeństwa sieci
      await simulateStep(offerId, OfferCheck.DOWNLOADING_DATA, 2000);

      // Real step: Podpisywanie oferty
      useUpdateOfferCheck(offer.id, OfferCheck.SIGNING_OFFER);
      await createPDFs(offer, ConfigType.OFFER);

      // step
      await simulateStep(offerId, OfferCheck.SAVE_REPORT, 2300);
      onLogOfferAddConfirm(offerId);

      // Real step: Wysyłanie oferty
      useUpdateOfferCheck(offer.id, OfferCheck.SENDING_OFFER);
      const onOfferAddConfirm = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_OFFER_ADD_CONFIRM);
      const functionSignOfferConfirmPayload: FunctionAddOfferPayload = { offerId };

      await onOfferAddConfirm(functionSignOfferConfirmPayload);
      useUpdateOfferCheck(offer.id, OfferCheck.DONE);
    } catch (error: any) {
      handleError(error);
    }
  };

  const handleError = (error: any) => {
    console.error('Error during offer confirmation:', error);
    setErrorMessage(error.message);
    toastService.show(error.message, t('ERROR_DESCRIPTION'), {
      variant: ToastVariant.ERROR,
    });
    push(AppRoutes.HOME);
  };

  useEffect(() => {
    if (!offer) {
      setShowLoadingOffer(true);
      return;
    }

    setShowLoadingOffer(false);

    if (offerId === undefined) {
      push(AppRoutes.HOME, { offerId });
      return;
    }

    if (offer.offerStatus.state === OfferStatusState.UNSIGNED && !isLoading.current) {
      useUpdateOfferCheck(offerId, OfferCheck.CHECKING_NETWORK);
      isLoading.current = true;
      handleOfferConfirmation();
    }
  }, [offer, offerId, push]);

  if (showLoadingOffer) {
    return (
      <Space
        direction="vertical"
        style={{
          width: '100%',
          height: '50vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Spin size="large" />
      </Space>
    );
  }

  if (offer && offer.offerStatus.state !== OfferStatusState.UNSIGNED && !isLoading.current) {
    return (
      <Space
        direction="vertical"
        style={{
          width: '100%',
          marginTop: '100px',
          marginBottom: '60%',
          padding: '50px',
        }}>
        <Alert
          message={t('OFFER_ALREADY_ACTIVE')}
          description={t('OFFER_VIEW_DESCRIPTION')}
          type="info"
          action={
            <Button size="small" type="primary" onClick={() => push(AppRoutes.OFFER.replace(':id', offerId))}>
              {t('SEE')}
            </Button>
          }
        />
      </Space>
    );
  }

  if (offer) {
    return (
      <OfferRelatedWrapper
        images={imagesUrl}
        offer={offer}
        description={t('RENTAL_OFFER_POSTED')}
        error={errorMessage}
        progressConfigType={ProgressConfigType.OFFER}
      />
    );
  }

  return <div>{t('ERROR_DESCRIPTION')}</div>;
};

// Funkcja symulująca kroki z opóźnieniem
export async function simulateStep(offerId: string, step: OfferCheck, duration: number): Promise<void> {
  await useUpdateOfferCheck(offerId, step);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

const WhenNotAuth: React.FC = () => {
  const { showSignInModal, closeModal } = useAuth();

  useEffect(() => {
    showSignInModal();
    return () => closeModal();
  }, [showSignInModal, closeModal]);

  return null;
};

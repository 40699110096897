import { Alert, Space } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  days: number;
  firstPaymentDay: number;
}

export const ReceiptDeadline: React.FC<Props> = ({ days, firstPaymentDay }) => {
  const { t } = useTranslation(); // Translation hook

  if (days) {
    return (
      <Space direction="vertical" size={12} style={{ marginBottom: 16 }}>
        <Alert
          type="info"
          message={t('RECEIPT_DEADLINE.MESSAGE', {
            days,
            firstPaymentDay,
          })}
        />
      </Space>
    );
  } else return <></>;
};

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { ProgressConfigType } from '../../components/OfferRelatedWrapper/OfferProgress';
import { OfferRelatedWrapper } from '../../components/OfferRelatedWrapper/OfferRelatedWrapper';
import { useGetOffer } from '../../firebase/hooks/getOfferById.hook';
import { useGetOfferImages } from '../../hooks/getOfferImages.hook';
import { App } from '../App/App';
import { useAuth } from '../Auth/AuthContext';
import { AppRoutes } from '../Routing/routing.model';

interface Props {
  offerId: string;
}
export const OfferSubmitVerify: React.FC<Props> = ({ offerId }) => {
  const { isAuthenticating, isAuth } = useAuth();
  return <App>{!isAuthenticating && isAuth ? <WhenAuth offerId={offerId} /> : <Redirect to={AppRoutes.HOME} />}</App>;
};

const WhenAuth: React.FC<Props> = ({ offerId }) => {
  const [offer] = useGetOffer(offerId);
  const imagesUrl = useGetOfferImages(offerId);
  const { t } = useTranslation();
  return (
    <>
      {offer && (
        <OfferRelatedWrapper
          images={imagesUrl}
          offer={offer}
          description={
            <div>
              <div className={'text-xl'}>{t('SIGN_CONTRACT.EMAIL_VERIFICATION_REQUIRED')}</div>
            </div>
          }
          progressConfigType={ProgressConfigType.SIGN_VERIFY}
        />
      )}
    </>
  );
};

import { Card, Divider, Space, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Offer } from '../../../models/offers.model';

const { Title, Text } = Typography;

interface Props {
  offer: Offer;
}

export const Details: React.FC<Props> = ({ offer }) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Space direction="vertical" style={{ width: '70%', padding: '16px' }}>
        <Card bordered={false} style={{ boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
          <Title level={3}>{t('PAY_DEPOSIT')}</Title>
          <Divider />
          <Text>{t('LEASE_AGREEMENT_SIGNED')}</Text>
          <Title level={4} style={{ margin: '16px 0' }}>
            {offer.name}
          </Title>
          <Text>{t('FINALIZE_RENTAL_DEPOSIT')}</Text>
          <Title level={2} style={{ margin: '16px 0', color: '#52c41a' }}>
            {offer.leaseAgreement.deposit} PLN
          </Title>
          <Text>{t('TRANSFER_FUNDS_BANK_ACCOUNT')}</Text>
          <Title level={5} style={{ margin: '16px 0', color: '#1890ff' }}>
            {process.env.REACT_APP_BANK_ACCOUNT ? process.env.REACT_APP_BANK_ACCOUNT : 'zgłoś błąd'}
          </Title>
          <Text>{t('DEPOSIT_INFO_DETAILS')}</Text>
          <Divider></Divider>
          <Text type="secondary">
            {t('TRANSFER_TITLE_INFO')}
            {offer.name}.
          </Text>
        </Card>
      </Space>
    </div>
  );
};

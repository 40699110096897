import { Collapse } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizedLabelWithMultipleLinks } from '../../OfferAdd/utils/LocalizedLabelWithMultipleLinks';
import { LocalizedLabelWithMultipleModals } from '../../OfferAdd/utils/LocalizedLabelWithMultipleModals';

import { Title } from './FAQ';

const { Panel } = Collapse;

export const General: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Ogólne */}
      <div>
        <Title>{t('FAQ.GENERAL.TITLE')}</Title>
        <Collapse>
          <Panel header={t('FAQ.GENERAL.LEGAL_BASIS_HEADER')} key="1">
            <p>
              {t('FAQ.GENERAL.LEGAL_BASIS_TEXT')}
              <LocalizedLabelWithMultipleModals
                i18nKey="FAQ.GENERAL.LEGAL_BASIS_LINK"
                linkPaths={{
                  '1': '/view-document/documents&FL_AspektyPrawne.pdf',
                }}
              />
            </p>
          </Panel>
          <Panel header={t('FAQ.GENERAL.PROCESS_OVERVIEW_HEADER')} key="2">
            <p>
              {t('FAQ.GENERAL.PROCESS_OVERVIEW_TEXT')}
              <LocalizedLabelWithMultipleLinks
                i18nKey="FAQ.GENERAL.PROCESS_OVERVIEW_LINK"
                linkPaths={{
                  '1': '/pomoc',
                }}
              />
            </p>
          </Panel>
          <Panel header={t('FAQ.GENERAL.SIGNING_CONTRACTS_HEADER')} key="4">
            <p>{t('FAQ.GENERAL.SIGNING_CONTRACTS_TEXT')}</p>
          </Panel>
          <Panel header={t('FAQ.GENERAL.EMAIL_SIGNATURE_HEADER')} key="8">
            <p>{t('FAQ.GENERAL.EMAIL_SIGNATURE_TEXT')}</p>
          </Panel>
          <Panel header={t('FAQ.GENERAL.DOCUMENT_SECURITY_HEADER')} key="5">
            <p>
              {t('FAQ.GENERAL.DOCUMENT_SECURITY_TEXT')}
              <LocalizedLabelWithMultipleLinks
                i18nKey="FAQ.GENERAL.DOCUMENT_SECURITY_LINK"
                linkPaths={{
                  '1': '/moja-tablica/my-offers/unsigned-offers',
                }}
              />
            </p>
          </Panel>
          <Panel header={t('FAQ.GENERAL.REPORT_SUGGESTIONS_HEADER')} key="6">
            <p>
              {t('FAQ.GENERAL.REPORT_SUGGESTIONS_TEXT')}
              <LocalizedLabelWithMultipleLinks
                i18nKey="FAQ.GENERAL.REPORT_SUGGESTIONS_LINK"
                linkPaths={{
                  '1': '/moja-tablica/advanced/report',
                }}
              />
            </p>
          </Panel>
        </Collapse>
      </div>
    </>
  );
};

import { message } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { firebaseFunctions } from '../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../firebase/firebase.models';
import { ToastVariant } from '../../Toast/toast.model';
import { toastService } from '../../Toast/toast.service';

interface Props {
  offerId: string;
  uid: string;
}

const StyledDiv = styled.div`
  position: absolute !important;
  opacity: 0.9;
`;

export const DeleteFavorite: React.FC<Props> = ({ offerId, uid }) => {
  const { t } = useTranslation(); // Hook tłumaczeń

  const handleDelete = () => {
    message.loading(t('OFFER.REMOVING'), 0); // "Usuwanie..."
    const removeFromFavorites = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.REMOVE_FROM_FAVORITES);
    removeFromFavorites({ offerId: offerId, uid: uid })
      .then(() => {
        toastService.show(t('OFFER.REMOVED_SUCCESS'), t('SUCCESS')); // "Usunięto z ulubionych" / "Sukces"
      })
      .catch(() => {
        toastService.show(t('OFFER.REMOVE_ERROR'), t('ERROR'), { variant: ToastVariant.ERROR }); // "Coś poszło nie tak. Spróbuj później" / "Błąd"
      })
      .finally(() => {
        message.destroy();
      });
  };

  return (
    <StyledDiv className="absolute -top-2 right-0 m-6 hover:underline" onClick={handleDelete}>
      {t('REMOVE')} {/* "Usuń" */}
    </StyledDiv>
  );
};

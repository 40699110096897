import { DownOutlined } from '@ant-design/icons';
import useVisibilitySensor from '@rooks/use-visibility-sensor';
import { Drawer, Dropdown, MenuProps } from 'antd';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Avatar, Header, Icon, Nav, Navbar } from 'rsuite';
import styled from 'styled-components';

import LanguageSwitcher from '../../../components/LanguageSwitcher/LanguageSwitcher';
import { WidthWrapper } from '../../../components/Layout/styles';
import { Logo } from '../../../components/Logo/Logo';
import { useGetCompany } from '../../../firebase/hooks/getCompanyByUid.hook';
import { Verification } from '../../../models/offers.model';
import { gtm } from '../../../reportGTM';
import { useAuth } from '../../Auth/AuthContext';
import { useSignOut } from '../../Auth/SignOut/useSignOut';
import { fetchUserData } from '../../MojaTablica/Account/Identity/ConfirmIdentity/firebaseIdentityService';
import { AppRoutes } from '../../Routing/routing.model';
import { useOnAddOfferPush } from '../hooks/onAddOfferPush.hook';
import { truncateText } from '../TruncatedText';

import { SPECIAL_OFFER_KEY } from './SpecialOffer100';

const StyledNavbarHeader = styled.div`
  float: left;
  @media (max-width: 415px) {
    margin-top: 20px;
  }
`;

const StyledNavbar = styled(Navbar)<{ sticky: boolean }>`
  padding-top: 35px;
  transition: all 0.3s ease;
  position: absolute;
  top: 0;
  width: 100%;
  box-shadow: none;
  * && {
    background-color: ${({ theme }) => theme.primary};
  }

  ${({ sticky }) =>
    sticky &&
    `
      box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
      padding-top: 8px;
      padding-bottom: 8px;
      position: fixed;
      z-index: 10;
      * && {
        background-color: white;
      };
    `}
`;
const StyledNavSensor = styled.div<{ sticky?: boolean }>`
  height: 88px;
  background-color: ${({ theme }) => theme.primary};
  transition: all 0.3s ease;
  ${({ sticky }) =>
    sticky &&
    `
      height: 72px;
      background-color: white;
    `}
`;
const MenuButton = styled.div<{ sticky?: boolean }>`
  display: none;

  @media (max-width: 900px) {
    margin-top: 30px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
  @media (max-width: 415px) {
    margin-top: 50px;
  }

  ${({ sticky }) =>
    sticky &&
    `
    @media (max-width: 415px) {
      margin-top: 20px;
    }
    `}
`;

export const FixedMenu: React.FC = () => {
  const { isAuth, showSignInModal, showSignUpModal, currentUser } = useAuth();
  const onSignOut = useSignOut();
  const { t } = useTranslation();
  const { push } = useHistory();
  const [activeKay, setActiveKey] = useState();
  const [company] = useGetCompany(currentUser?.uid);
  const [mojaTabliceRedirect, setMojaTabliceRedirect] = useState(AppRoutes.MOJA_TABLICA);
  const { pathname } = useLocation();
  const [, setPromo] = useCookies(['specialOffer100']);

  const rootNode = useRef(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { isVisible } = useVisibilitySensor(rootNode, {
    intervalCheck: false,
    scrollCheck: true,
    resizeCheck: false,
    partialVisibility: true,
  });

  const onAddOfferPush = useOnAddOfferPush();

  const onAddOffer = useCallback(() => {
    if (!isAuth) {
      showSignInModal({
        onSuccess: () => Promise.resolve().then(() => onAddOfferPush()),
      });
    } else {
      onAddOfferPush();
    }
  }, [isAuth, showSignInModal, onAddOfferPush]);

  const items: MenuProps['items'] = [
    {
      label: <a href="/offers?premisesPurpose=Biurowe">Biura</a>,
      key: '0',
    },
    {
      label: <a href="/offers?premisesPurpose=Handlowo-Usługowe">Lokale Handlowo-Usługowe</a>,
      key: '1',
    },
    {
      label: <a href="/offers?premisesPurpose=Magazynowo-Przemysłowe">Magazyny</a>,
      key: '1',
    },
  ];

  useEffect(() => {
    const checkUserVerification = async () => {
      if (currentUser) {
        const user = await fetchUserData(currentUser?.uid);

        if (!currentUser.emailVerified) {
          setMojaTabliceRedirect(AppRoutes.MOJA_TABLICA_ACCOUNT_INFO);
        } else if (user) {
          if (user.verification?.identity !== Verification.ACCEPTED) {
            setMojaTabliceRedirect(AppRoutes.MOJA_TABLICA_CONFIRM_IDENTITY);
          } else if (user.verification?.company !== Verification.ACCEPTED) {
            setMojaTabliceRedirect(AppRoutes.MOJA_TABLICA_LINK_COMPANY);
          } else {
            setMojaTabliceRedirect(AppRoutes.MOJA_TABLICA);
          }
        } else return;
      }
    };

    checkUserVerification();
  }, [currentUser]);

  const handleClickMojaTablica = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    push(mojaTabliceRedirect);
  };

  return (
    <Header>
      <StyledNavSensor ref={rootNode} sticky={isVisible === false} />

      <Drawer
        title="Menu"
        placement="right"
        closable={true}
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        width={250}>
        <Nav onSelect={setActiveKey} style={{ float: 'right', margin: '0 16px' }}>
          <Nav.Item eventKey="1" onClick={() => push(AppRoutes.OFFERS)} style={{ float: 'right' }}>
            Oferty
          </Nav.Item>
          <br></br>
          <Nav.Item eventKey="2" onClick={() => push(AppRoutes.ADD_OFFER)} style={{ float: 'right' }}>
            Dodaj ofertę
          </Nav.Item>
          <br></br>
          {isAuth ? (
            <>
              <Nav.Item
                style={{ float: 'right' }}
                onClick={handleClickMojaTablica}
                active={pathname.indexOf(AppRoutes.MOJA_TABLICA) > -1}>
                {t('LINK_MY_DASHBOARD')}
              </Nav.Item>
              <br></br>
              <Nav.Item onClick={() => onSignOut()} icon={<Icon icon="sign-out" />} style={{ float: 'right' }}>
                {t('LINK_LOG_OUT')}
              </Nav.Item>
              <br></br>
            </>
          ) : (
            <>
              <Nav.Item
                icon={<Icon icon="user-plus" />}
                onClick={() => {
                  showSignUpModal();
                  gtm('click_button_form_reg');
                }}
                style={{ float: 'right' }}
                id="button_form_reg_mobile">
                {t('LINK_SING_UP')}
              </Nav.Item>
              <br></br>
              <Nav.Item icon={<Icon icon="sign-in" />} onClick={() => showSignInModal()} style={{ float: 'right' }}>
                {t('LINK_SING_IN')}
              </Nav.Item>
            </>
          )}
        </Nav>
      </Drawer>

      <StyledNavbar
        appearance={isVisible === true ? 'inverse' : undefined}
        sticky={isVisible === false ? true : undefined}>
        <WidthWrapper>
          <StyledNavbarHeader>
            <Logo onClick={() => push(AppRoutes.HOME)} isVisible={isVisible} />
            <Navbar.Body>
              {isMobile && (
                <MenuButton sticky={isVisible === false ? true : undefined}>
                  <Nav pullRight>
                    <Nav.Item onClick={() => setDrawerVisible(true)}>
                      <Icon icon="bars" size="2x" />
                    </Nav.Item>
                  </Nav>
                </MenuButton>
              )}
            </Navbar.Body>
          </StyledNavbarHeader>

          {!isMobile && (
            <Navbar.Body>
              <Nav onSelect={setActiveKey} activeKey={activeKay}>
                <Dropdown menu={{ items }} placement="bottomLeft" overlayStyle={{ paddingTop: 5 }}>
                  <Nav.Item
                    style={{ margin: '0 24px' }}
                    eventKey="1"
                    onClick={(e) => {
                      e.preventDefault();
                      push(AppRoutes.OFFERS);
                    }}
                    active={pathname.indexOf(AppRoutes.OFFERS) > -1}>
                    {t('LINK_I_RENT')} <DownOutlined />
                  </Nav.Item>
                </Dropdown>

                <Nav.Item
                  eventKey="2"
                  onClick={(e) => {
                    e.preventDefault();
                    setPromo(SPECIAL_OFFER_KEY, 'SHOW', { path: '/' });
                    onAddOffer();
                    gtm('add_offer_fixed_menu');
                  }}
                  active={pathname.indexOf(AppRoutes.ADD_OFFER.replace('/:id', '')) > -1}>
                  {t('LINK_I_AM_RENTING')}
                </Nav.Item>
              </Nav>
              <Nav pullRight>
                {isAuth ? (
                  <>
                    <Nav.Item onClick={handleClickMojaTablica} active={pathname.indexOf(AppRoutes.MOJA_TABLICA) > -1}>
                      <div className="relative flex w-auto items-center h-full">
                        <div className="flex flex-col mr-1">
                          <div className="font-semibold mr-3">{t('LINK_MY_DASHBOARD')}</div>
                          <div>{truncateText({ text: company?.nazwa ?? '', startLength: 5, endLength: 5 })}</div>
                        </div>

                        <Avatar circle src={currentUser.photoURL ?? '/images/avatar/default.jpg'} />
                      </div>
                    </Nav.Item>
                    <Nav.Item onClick={() => onSignOut()} icon={<Icon icon="sign-out" />}>
                      {t('LINK_LOG_OUT')}
                    </Nav.Item>
                  </>
                ) : (
                  <>
                    <Nav.Item
                      icon={<Icon icon="user-plus" />}
                      onClick={() => {
                        showSignUpModal();
                        gtm('click_button_form_reg');
                      }}
                      id="button_form_reg">
                      {t('LINK_SING_UP')}
                    </Nav.Item>
                    <Nav.Item icon={<Icon icon="sign-in" />} onClick={() => showSignInModal()}>
                      {t('LINK_SING_IN')}
                    </Nav.Item>
                  </>
                )}
                <Nav pullRight>
                  <LanguageSwitcher languages={['pl', 'en']} />
                </Nav>
              </Nav>
            </Navbar.Body>
          )}
        </WidthWrapper>
      </StyledNavbar>
    </Header>
  );
};

// src/components/DocumentViewer.tsx
import { Alert, Space, Spin } from 'antd';
import firebase from 'firebase/app';
import 'firebase/storage';
import React, { useEffect, useState } from 'react';

export const DocumentViewer: React.FC<{ documentPath: string }> = ({ documentPath }) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDocument = async () => {
      const formattedDocumentPath = documentPath.replace('&', '/');
      try {
        const storage = firebase.storage();
        const fileRef = storage.ref(formattedDocumentPath);
        const url = await fileRef.getDownloadURL();
        setFileUrl(url);
      } catch (error) {
        setError('Failed to load the document.');
      } finally {
        setLoading(false);
      }
    };

    fetchDocument();
  }, [documentPath]);

  if (loading) {
    return (
      <Space
        direction="horizontal"
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Spin size="large" />
      </Space>
    );
  }

  if (error) {
    return <Alert message={error} type="error" />;
  }

  return (
    <div className="h-screen">
      <iframe src={fileUrl} width="100%" height="100%" title="Document Viewer"></iframe>
    </div>
  );
};

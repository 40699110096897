import { Modal } from 'antd';
import { FormikErrors } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'rsuite';

import { Offer } from '../../../models/offers.model';

interface ValidationErrorsButtonProps {
  validateForm: (values?: any) => Promise<FormikErrors<Offer>>;
  onAddOffers: (e: any) => void;
  isAddingPending: boolean;
  isSubmitting: boolean;
  saved: boolean;
  onChanges: () => void;
}

export const ValidationErrorsButton: React.FC<ValidationErrorsButtonProps> = ({
  validateForm,
  onAddOffers,
  isAddingPending,
  isSubmitting,
  saved,
  onChanges,
}) => {
  const [validationErrors, setValidationErrors] = useState<FormikErrors<Offer> | null>(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleValidation = async (e: any) => {
    // Uruchom walidację formularza
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      // Brak błędów - wykonaj submit formularza
      onAddOffers(e);
    } else {
      // Jeśli są błędy, wyświetl modal z błędami
      setValidationErrors(errors);
      handleOpen();
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const renderErrorMessages = (errors: FormikErrors<Offer>) => {
    const flattenErrors = (obj, parentKey = '') => {
      return Object.keys(obj).reduce((acc, key) => {
        const errorPath = parentKey ? `${parentKey}.${key}` : key;

        if (typeof obj[key] === 'object' && obj[key] !== null) {
          acc = { ...acc, ...flattenErrors(obj[key], errorPath) };
        } else {
          acc[errorPath] = obj[key];
        }
        return acc;
      }, {});
    };

    const flatErrors = flattenErrors(errors);
    return (
      <ul>
        {Object.entries(flatErrors).map(([, error], index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    );
  };

  const errorList = validationErrors ? (
    <ul>{renderErrorMessages(validationErrors)}</ul>
  ) : (
    <p>{t('COMMON.FORMS.NO_VALIDATION_ERRORS')}</p>
  );

  return (
    <>
      <Modal open={open} footer={null} closable={true} onCancel={handleClose} title={t('VALIDATION.VALIDATION_ERROR')}>
        <div style={{ maxHeight: '600px', overflow: 'auto' }}>{errorList}</div>
      </Modal>
      <Button
        appearance="primary"
        loading={isAddingPending} // Obsługuje stan ładowania
        disabled={isSubmitting || !saved} // Wyłącza przycisk, jeśli formularz jest wysyłany lub dane nie są zapisane
        onClick={handleValidation}
        onChange={() => {
          onChanges();
        }}
        style={{ maxHeight: '40px' }}>
        {t('FORMS.OFFER_ADD.BUTTONS.SEND_OFFER_PROJECT_WITH_LEASE_TERMS')}
      </Button>
    </>
  );
};

import firebase from 'firebase';

import 'firebase/storage';
import { ToastVariant } from '../../../components/Toast/toast.model';
import { toastService } from '../../../components/Toast/toast.service';
import { firebaseFunctions } from '../../../firebase/firebase';
import { FirebaseCallableFunctions, FunctionAddOfferPayload } from '../../../firebase/firebase.models';
import { OfferCheck, useUpdateOfferCheck } from '../../../firebase/hooks/updateOfferCheck.hook';
import { Offer, Verification } from '../../../models/offers.model';
import { AppRoutes } from '../../Routing/routing.model';
import { createPDFs } from '../Documents/createPDFs';
import { ConfigType } from '../Documents/documents.models';
import { isCompanyConfirmed } from '../utils/isCompanyConfirmed';
import { isIdentityConfirmed } from '../utils/isIdentityConfirmed';

export const handleOnOfferAdd = async (
  currentUser: firebase.User,
  offer: Offer,
  setIsIdentityModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setIsCompanyModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setImagesVerificationResults: React.Dispatch<React.SetStateAction<string | null>>,
  push: (path: string) => void
): Promise<{ success: boolean; description: string }> => {
  const checkIfImagesExist = async (offerId: string): Promise<boolean> => {
    const storage = firebase.storage();
    const imagesFolderRef = storage.ref(`offers/${offerId}/images`);

    try {
      const res = await imagesFolderRef.listAll();
      if (res?.items?.length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error listing images in folder:', error);
      return false;
    }
  };

  push(AppRoutes.ADD_OFFER_VERIFY.replace(':id', offer.id));

  const offerHasImages = await checkIfImagesExist(offer.id);

  useUpdateOfferCheck(offer.id, OfferCheck.VERIFYING_DATA);

  if ((await isIdentityConfirmed(currentUser)) !== Verification.ACCEPTED) {
    setIsIdentityModalOpen(true);
  } else if ((await isCompanyConfirmed(currentUser)) !== Verification.ACCEPTED) {
    setIsCompanyModalOpen(true);
  } else {
    await verifyOfferData();

    if (offerHasImages) {
      useUpdateOfferCheck(offer.id, OfferCheck.VERIFYING_IMAGES);

      const verificationResults = await verifyOfferImages(offer.id);
      console.log(verificationResults);
      setImagesVerificationResults(verificationResults);
      if (verificationResults) {
        push(AppRoutes.ADD_OFFER.replace(':id', offer.id));
        return;
      }
    }
    useUpdateOfferCheck(offer.id, OfferCheck.GENERATING_PROJECT_OFFER);

    setLoading(true);

    createPDFs(offer, ConfigType.PROJECT)
      .then(() => {
        useUpdateOfferCheck(offer.id, OfferCheck.SENDING_PROJECT_OFFER);
        const offerAdd = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_OFFER_ADD);
        const functionPayload: FunctionAddOfferPayload = { offerId: offer.id };

        offerAdd(functionPayload)
          .then(() => {
            useUpdateOfferCheck(offer.id, OfferCheck.DONE);
            // gtm('generate_lead', {
            //   // currency: 'PLN',
            //   // value: 100,
            //   lead_source: 'Wynajmujący - Dodanie oferty',
            // });
          })
          .catch((error: any) => {
            console.error(error);
            return { success: false, description: 'ERROR_DESCRIPTION' };
          });
      })
      .catch((error: any) => {
        console.error(error);
        return { success: false, description: 'DOCUMENT_CREATION_ERROR' };
      })
      .finally(() => setLoading(false));
  }
};

async function verifyOfferData(): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 2000);
  });
}

async function verifyOfferImages(offerId: string): Promise<string | null> {
  const verifyPhotos = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.VERIFY_PHOTOS_GEMINI);
  const functionPayload: FunctionAddOfferPayload = { offerId: offerId };

  try {
    const resp = await verifyPhotos(functionPayload);
    if (resp.data.response) {
      alert(formatImageVerificationResponse(JSON.stringify(resp.data.response)));
      return resp.data.response;
    } else {
      return null;
    }
  } catch (error: any) {
    toastService.show(error.message, 'Nie udało się zweryfikować zdjęć.', {
      variant: ToastVariant.ERROR,
    });
    return null;
  }
}

function formatImageVerificationResponse(response: string): string {
  const lines = response.split('\n');
  const formattedLines = lines.map((line, index) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, reasonLine] = line.split(' jest nieodpowiednie z powodu: Nie. ');
    return `Zdjęcie ${index + 1} zostało oznaczone jako nieodpowiednie z następującego powodu: ${reasonLine}.`;
  });
  return formattedLines.join('\n');
}

// function formatImageVerificationResponse(response: string): string {
//   const lines = response.split('\n');
//   const formattedLines = lines.map((line, index) => {
//     const parts = line.split(' jest nieodpowiednie z powodu: Nie. ');
//     const reasonLine = parts[1]; // undefined if no reason is present

//     // Check if there is a reason, and use the appropriate message template
//     // if (reasonLine) {
//     //   return t('IMAGE_VERIFICATION.NOT_APPROPRIATE', {
//     //     index: index + 1,
//     //     reason: reasonLine,
//     //   });
//     // } else {
//     //   return t('IMAGE_VERIFICATION.NO_REASON', { index: index + 1 });
//     // }
//   });

//   return formattedLines.join('\n');
// }

import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
  border-radius: 8px;
  overflow: hidden;
  margin: 0 8px;
  display: block;
  cursor: pointer;
`;
const StyledImage = styled.img`
  height: 100%;
  max-height: 64px;
`;

interface Props {
  onClick: () => void;
  isVisible: any;
}
export const Logo: React.FC<Props> = ({ onClick, isVisible }) => {
  return (
    <Wrapper onClick={onClick}>
      <StyledImage src={isVisible ? '/images/logo/logo.png' : '/images/logo/Logo-black.png'} alt={'najmuje.com'} />
    </Wrapper>
  );
};

import { Card, Col, Divider, Form, Row, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Offer } from '../../../models/offers.model';
import { Level, Room } from '../../../models/protocol.model';

import { usePremiseConditionOptions } from './usePremiseConditionOptions.hook';

interface Props {
  onChange: (data: Level[]) => void;
  offer: Offer;
  initialValues: Level[];
}

const EstablishedPremiseCondition: React.FC<Props> = ({ onChange, offer, initialValues }) => {
  const { t } = useTranslation();
  const [levels, setLevels] = useState<Level[]>(() =>
    offer.areaDetails.levels.map((level, index) => ({
      levelNumber: level.levelNumber,
      levelName: level.levelName,
      offices: level.offices?.map((office, i) => ({
        name: office.type,
        condition: initialValues?.[index]?.offices?.[i]?.condition || '',
        comments: initialValues?.[index]?.offices?.[i]?.comments || '',
      })),
      warehouses: level.warehouses?.map((room, i) => ({
        name: room.type,
        condition: initialValues?.[index]?.warehouses?.[i]?.condition || '',
        comments: initialValues?.[index]?.warehouses?.[i]?.comments || '',
      })),
      commercialAndServiceRooms: level.commercialAndServiceRooms?.map((room, i) => ({
        name: room.type,
        condition: initialValues?.[index]?.commercialAndServiceRooms?.[i]?.condition || '',
        comments: initialValues?.[index]?.commercialAndServiceRooms?.[i]?.comments || '',
      })),
      technicalRooms: level.technicalRooms?.map((room, i) => ({
        name: room.type,
        condition: initialValues?.[index]?.technicalRooms?.[i]?.condition || '',
        comments: initialValues?.[index]?.technicalRooms?.[i]?.comments || '',
      })),
      otherRoomsDetails: level.otherRoomsDetails?.map((detail, i) => ({
        name: detail,
        condition: initialValues?.[index]?.otherRoomsDetails?.[i]?.condition || '',
        comments: initialValues?.[index]?.otherRoomsDetails?.[i]?.comments || '',
      })),
    }))
  );

  useEffect(() => {
    setLevels(() =>
      offer.areaDetails.levels.map((level, index) => ({
        levelNumber: level.levelNumber,
        levelName: level.levelName,
        offices: level.offices?.map((office, i) => ({
          name: office.type,
          condition: initialValues?.[index]?.offices?.[i]?.condition || '',
          comments: initialValues?.[index]?.offices?.[i]?.comments || '',
        })),
        warehouses: level.warehouses?.map((room, i) => ({
          name: room.type,
          condition: initialValues?.[index]?.warehouses?.[i]?.condition || '',
          comments: initialValues?.[index]?.warehouses?.[i]?.comments || '',
        })),
        commercialAndServiceRooms: level.commercialAndServiceRooms?.map((room, i) => ({
          name: room.type,
          condition: initialValues?.[index]?.commercialAndServiceRooms?.[i]?.condition || '',
          comments: initialValues?.[index]?.commercialAndServiceRooms?.[i]?.comments || '',
        })),
        technicalRooms: level.technicalRooms?.map((room, i) => ({
          name: room.type,
          condition: initialValues?.[index]?.technicalRooms?.[i]?.condition || '',
          comments: initialValues?.[index]?.technicalRooms?.[i]?.comments || '',
        })),
        otherRoomsDetails: level.otherRoomsDetails?.map((detail, i) => ({
          name: detail,
          condition: initialValues?.[index]?.otherRoomsDetails?.[i]?.condition || '',
          comments: initialValues?.[index]?.otherRoomsDetails?.[i]?.comments || '',
        })),
      }))
    );
  }, [initialValues, offer.areaDetails.levels]);

  const allowedRoomTypes = [
    'offices',
    'warehouses',
    'commercialAndServiceRooms',
    'technicalRooms',
    'otherRoomsDetails',
  ];

  const handleRoomUpdate = (levelIndex: number, roomType: keyof Level, roomIndex: number, updatedRoom: Room) => {
    const newLevels = [...levels];
    const level = newLevels[levelIndex];

    const property = level[roomType];
    if (Array.isArray(property)) {
      const rooms = property as Room[];
      rooms[roomIndex] = updatedRoom;
      level[roomType] = rooms;
    }

    setLevels(newLevels);
    onChange(newLevels);
  };

  return (
    <Card title={<div className="text-base font-medium">{t('PROTOCOL.A.TITLE')}</div>} className="mb-2">
      {levels.map((level, levelIndex) => (
        <Form layout="vertical" key={levelIndex}>
          <Row className="font-bold text-base pb-4">
            {t('PROTOCOL.A.FLOOR')}: {level.levelNumber} ({level.levelName}):
          </Row>
          {Object.entries(level)
            .filter(([key]) => allowedRoomTypes.includes(key))
            .map(([type, rooms]) =>
              addRoomsToTable(type as keyof Level, rooms as Room[], levelIndex, handleRoomUpdate, t)
            )}
        </Form>
      ))}
    </Card>
  );
};

function addRoomsToTable(
  type: keyof Level,
  rooms: Room[],
  levelIndex: number,
  handleRoomUpdate: (levelIndex: number, roomType: keyof Level, roomIndex: number, updatedRoom: Room) => void,
  t: (key: string) => string
) {
  const conditionOptions = usePremiseConditionOptions();
  const translatedType = getTranslatedType(type, t);

  if (!rooms || rooms.length === 0) return null;

  return (
    <div className="my-4">
      <Divider plain orientation="center">
        {translatedType}
      </Divider>
      {rooms.map((room, roomIndex) => (
        <Row key={roomIndex}>
          <Col span={8}>{getTranslatedRoomName(type, room.name, t)}</Col>
          <Col span={8}>
            <Form.Item className="mr-4">
              <Select
                options={conditionOptions}
                onChange={(value) => handleRoomUpdate(levelIndex, type, roomIndex, { ...room, condition: value })}
                value={room.condition}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <TextArea
                rows={1}
                placeholder={t('PROTOCOL.COMMENTS')}
                maxLength={1000}
                value={room.comments}
                onChange={(e) => handleRoomUpdate(levelIndex, type, roomIndex, { ...room, comments: e.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
      ))}
    </div>
  );
}

function getTranslatedType(type: keyof Level, t: (key: string) => string): string {
  const types = {
    offices: t('LEVELS.OFFICE_ROOMS'),
    warehouses: t('LEVELS.WAREHOUSE_ROOMS'),
    commercialAndServiceRooms: t('LEVELS.COMMERCIAL_AND_SERVICE_ROOMS'),
    technicalRooms: t('LEVELS.TECHNICAL_ROOMS'),
    otherRoomsDetails: t('LEVELS.OTHER_ROOMS'),
  };
  return types[type] || t(`LEVELS.UNKNOWN_TYPE.${type}`);
}

export default EstablishedPremiseCondition;

function getTranslatedRoomName(type: keyof Level, roomName: string, t: (key: string) => string): string {
  // Mapowanie typów pomieszczeń na odpowiednie tłumaczenia
  const translationMapping: Record<keyof Level, string> = {
    offices: 'OFFICE_TYPE',
    warehouses: 'WAREHOUSE_ROOM_TYPE',
    commercialAndServiceRooms: 'COMMERCIAL_ROOM_TYPE',
    technicalRooms: 'TECHNICAL_ROOM_TYPE',
    otherRoomsDetails: 'OTHER_ROOM_TYPE',
  };

  const translationKey = translationMapping[type];
  if (translationKey) {
    return t(`FORMS.OFFER_ADD.${translationKey}.${roomName.toUpperCase()}`);
  }

  return roomName; // Jeśli nie ma tłumaczenia, zwróć oryginalną nazwę
}

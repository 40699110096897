import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useGetOffers } from '../../firebase/hooks/getOffers.hook';
import { SearchFormFilters } from '../../models/offers.model';
import { App, SHOW_LOGS } from '../App/App';

import { HowItWorks } from './components/HowItWorks';
import { OffersForm } from './components/OffersForm';
import { SearchedOffers } from './components/SearchedOffers';

const normalizeFilters = (filters: Record<string, any>): SearchFormFilters => {
  return Object.fromEntries(Object.entries(filters).map(([key, value]) => [key, value === 'null' ? null : value]));
};

export const Offers: React.FC = () => {
  const [selectedOfferId, setSelectedOfferId] = useState<string>();
  const [offers, onSearchOffers, isSearchPending] = useGetOffers();
  const history = useHistory();
  const location = useLocation();

  const defaultFilters: SearchFormFilters = {
    premisesPurpose: null,
    freeFrom: null,
    minArea: '',
    maxPrice: '',
    street: '',
    estate: '',
    district: '',
    county: '',
    province: '',
  };

  const [initialFilters, setInitialFilters] = useState<SearchFormFilters>(defaultFilters);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const rawFilters = Object.fromEntries(params.entries());
    const filters = normalizeFilters(rawFilters);
    SHOW_LOGS && console.log('filters', filters);
    setInitialFilters(filters);
    onSearchOffers(filters);
  }, [location.search, onSearchOffers]);

  const handleSearch = (values: SearchFormFilters) => {
    const params = new URLSearchParams(
      Object.entries(values).map(([key, value]) => [key, value !== null ? value : 'null'])
    );
    history.push({ search: params.toString() });
  };

  const onLocationClick = useCallback((id: string) => {
    setSelectedOfferId(id);
  }, []);

  return (
    <App>
      <OffersForm
        onSearchOffers={handleSearch}
        isSearchPending={isSearchPending}
        offers={offers}
        selectedOfferId={selectedOfferId}
        initialValues={initialFilters} // Przekazanie initialValues do formularza
      />
      <SearchedOffers offers={offers} isSearchPending={isSearchPending} onLocationClick={onLocationClick} />
      <HowItWorks />
    </App>
  );
};

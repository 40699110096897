import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Message } from 'rsuite';
import styled from 'styled-components';

import { Tenant } from '../../../models/legalEntity.model';

const StyledMessage = styled(Message)`
  margin-bottom: ${({ theme }) => theme.gap(3)};
  width: 100%;
`;
const EntityWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.gap(2)};
`;

interface Props {
  tenant: Tenant;
}

export const LegalEntity: React.FC<Props> = ({ tenant }) => {
  const { t } = useTranslation();

  return (
    <StyledMessage
      type="warning"
      title={t('LEGAL_ENTITY.TITLE')}
      description={
        <div>
          <EntityWrapper>
            <div>{tenant.name}</div>
            {tenant.lastName && <div>{tenant.lastName}</div>}
            {tenant.companyName && (
              <div>
                {t('LEGAL_ENTITY.COMPANY_NAME')}: {tenant.companyName}
              </div>
            )}
            {tenant.NIP && (
              <div>
                {t('LEGAL_ENTITY.NIP')}: {tenant.NIP}
              </div>
            )}
          </EntityWrapper>
          <p>
            {t('LEGAL_ENTITY.NOT_CORRECT')} <a href="/moja-tablica/account/info">{t('LEGAL_ENTITY.SETTINGS')}</a>.
          </p>
        </div>
      }
    />
  );
};

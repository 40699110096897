import * as React from 'react';

import { useGetOffer } from '../../firebase/hooks/getOfferById.hook';
import { useGetOfferImages } from '../../hooks/getOfferImages.hook';
import { OfferStatusState } from '../../models/offers.model';

import { OfferView } from './OfferView';

interface Props {
  id: string;
  preview?: boolean;
}
export const Offer: React.FC<Props> = ({ id, preview }) => {
  const [offer] = useGetOffer(id);
  const imagesUrl = useGetOfferImages(id);

  if (offer?.offerStatus.state != OfferStatusState.ACTIVE) {
    preview = true;
  }
  // tu zdefiniuj czy user jest zalogowany i jego status weryfikacji który przezkaesz dalej!!
  return offer ? <OfferView offer={offer} images={imagesUrl} preview={preview} /> : null;
};

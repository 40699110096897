import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../models/main.model';

export function useOfficeEquipment(): SelectOption[] {
  const { t } = useTranslation(); // Hook do tłumaczeń

  const officeEquipmentTranslationMap = {
    Biurka: 'DESKS',
    Krzesła: 'CHAIRS',
    'Rolety/żaluzje': 'BLINDS',
    Wykładzina: 'CARPET',
    'Sprzęt multimedialny': 'MULTIMEDIA',
    Szafy: 'CABINETS',
    Kopiarka: 'COPIER',
    Niszczarka: 'SHREDDER',
  };

  return useMemo(
    () =>
      Object.entries(officeEquipmentTranslationMap).map(([original, translationKey]) => ({
        label: t(`OFFICE_EQUIPMENT.${translationKey}`), // Tłumaczenie klucza
        value: original, // Oryginalna wartość
      })),
    [t]
  );
}

import { TFunction } from 'i18next';
import * as yup from 'yup';

import { OfferType } from '../../../models/offers.model';

export const useValidation = (t: TFunction<'translation'>): yup.SchemaOf<any> => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return yup.object().shape({
    uid: yup.string().required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('VALIDATION.ADD_OFFER.UID') })),
    description: yup.string().required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DESCRIPTION') })),
    legalMainUse: yup
      .string()
      .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.LEGAL.MAIN_USE') })),
    legalWarehouseUseAccepted: yup
      .array()
      .min(1, t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.LEGAL.ACCEPTED_USE') })),
    legalWarehouseUseOfficial: yup
      .array()
      .min(1, t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.LEGAL.OFFICIAL_USE') })),
    areaDetails: yup.object().shape({
      surface: yup
        .number()
        .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.USABLE_AREA_FOR_RENT') }))
        .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.USABLE_AREA_FOR_RENT') })),
      totalSurface: yup
        .number()
        .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.TOTAL_BUILDING_AREA') }))
        .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.TOTAL_BUILDING_AREA') })),
      // offices: yup
      //   .array()
      //   .of(
      //     yup.object().shape({
      //       surface: yup
      //         .number()
      //         .nullable() // pozwala na pustą wartość
      //         .test(
      //           'surface-and-type',
      //           t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.LEVELS.TOTAL_AREA_OF_LEVEL') }),
      //           function (value) {
      //             const { type } = this.parent;
      //             // Walidacja: jeśli jedno z pól jest uzupełnione, oba muszą być wypełnione
      //             return (!value && !type) || (value && type);
      //           }
      //         ),
      //       type: yup
      //         .string()
      //         .nullable() // pozwala na pustą wartość
      //         .test(
      //           'type-and-surface',
      //           t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.LEVELS.TOTAL_AREA_OF_LEVEL') }),
      //           function (value) {
      //             const { surface } = this.parent;
      //             // Walidacja: jeśli jedno z pól jest uzupełnione, oba muszą być wypełnione
      //             return (!value && !surface) || (value && surface);
      //           }
      //         ),
      //       windows: yup.boolean(),
      //     })
      //   )
      //   .when('legalMainUse', {
      //     is: 'Biurowe',
      //     then: yup
      //       .array()
      //       .of(
      //         yup.object().shape({
      //           type: yup
      //             .string()
      //             .required(
      //               t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.LEVELS.TOTAL_AREA_OF_LEVEL') })
      //             ),
      //           surface: yup
      //             .number()
      //             .typeError(
      //               t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.USABLE_AREA_FOR_RENT') })
      //             )
      //             .required(
      //               t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.USABLE_AREA_FOR_RENT') })
      //             ),
      //           windows: yup.boolean(),
      //         })
      //       )
      //       .min(1, t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.LEVELS.TOTAL_AREA_OF_LEVEL') })),
      //   }),
      // warehouses: yup
      //   .array()
      //   .of(
      //     yup.object().shape({
      //       type: yup
      //         .string()
      //         .required(
      //           t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.WAREHOUSE_EQUIPMENT.WAREHOUSE_HANDLING') })
      //         ),
      //       surface: yup
      //         .number()
      //         .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.USABLE_AREA_FOR_RENT') }))
      //         .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.USABLE_AREA_FOR_RENT') })),
      //       windows: yup.boolean(),
      //     })
      //   )
      //   .when('legalMainUse', {
      //     is: 'Handlowo-Usługowe',
      //     then: yup
      //       .array()
      //       .of(
      //         yup.object().shape({
      //           type: yup.string().required(
      //             t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', {
      //               field: t('DETAILS.WAREHOUSE_EQUIPMENT.WAREHOUSE_HANDLING'),
      //             })
      //           ),
      //           surface: yup
      //             .number()
      //             .typeError(
      //               t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.USABLE_AREA_FOR_RENT') })
      //             )
      //             .required(
      //               t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.USABLE_AREA_FOR_RENT') })
      //             ),
      //           windows: yup.boolean(),
      //         })
      //       )
      //       .min(
      //         1,
      //         t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.WAREHOUSE_EQUIPMENT.WAREHOUSE_HANDLING') })
      //       ),
      //   }),
      toiletsLocation: yup.boolean(),
      levels: yup.array().of(
        yup.object().shape({
          levelNumber: yup
            .number()
            .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('LEVELS.NUMBER_OF_LEVELS') }))
            .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('LEVELS.NUMBER_OF_LEVELS') })),
          levelName: yup
            .string()
            .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('LEVELS.TOTAL_AREA_OF_LEVEL') })),
          surface: yup
            .number()
            .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('LEVELS.TOTAL_AREA_OF_LEVEL') }))
            .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('LEVELS.TOTAL_AREA_OF_LEVEL') })),
          height: yup
            .number()
            .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('LEVELS.HEIGHT') }))
            .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('LEVELS.HEIGHT') })),
          numberOfStaircases: yup
            .number()
            .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('LEVELS.NUMBER_OF_STAIRCASES') }))
            .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('LEVELS.NUMBER_OF_STAIRCASES') })),
          accessibility: yup.boolean(),

          offices: yup.array().of(
            yup.object().shape({
              type: yup
                .string()
                .nullable()
                .required(
                  t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', {
                    field: t('FORMS.OFFER_ADD.ROOM_TYPE_VALIDATION.SELECT_TYPE_OFFICE'),
                  })
                ),
              surface: yup
                .number()
                .nullable()
                .required(
                  t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', {
                    field: t('FORMS.OFFER_ADD.ROOM_TYPE_VALIDATION.SELECT_SURFACE_OFFICE'),
                  })
                ),
              windows: yup.boolean(),
            })
          ),

          commercialAndServiceRooms: yup.array().of(
            yup.object().shape({
              type: yup
                .string()
                .nullable()
                .required(
                  t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', {
                    field: t('FORMS.OFFER_ADD.ROOM_TYPE_VALIDATION.SELECT_TYPE_COMMERCIAL_SERVICE'),
                  })
                ),
              surface: yup
                .number()
                .nullable()
                .required(
                  t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', {
                    field: t('FORMS.OFFER_ADD.ROOM_TYPE_VALIDATION.SELECT_SURFACE_COMMERCIAL_SERVICE'),
                  })
                ),
              windows: yup.boolean(),
            })
          ),

          warehouses: yup.array().of(
            yup.object().shape({
              type: yup
                .string()
                .nullable()
                .required(
                  t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', {
                    field: t('FORMS.OFFER_ADD.ROOM_TYPE_VALIDATION.SELECT_TYPE_WAREHOUSE'),
                  })
                ),
              surface: yup
                .number()
                .nullable()
                .required(
                  t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', {
                    field: t('FORMS.OFFER_ADD.ROOM_TYPE_VALIDATION.SELECT_SURFACE_WAREHOUSE'),
                  })
                ),
              windows: yup.boolean(),
            })
          ),

          technicalRooms: yup.array().of(
            yup.object().shape({
              type: yup
                .string()
                .nullable()
                .required(
                  t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', {
                    field: t('FORMS.OFFER_ADD.ROOM_TYPE_VALIDATION.SELECT_TYPE_TECHNICAL'),
                  })
                ),
              surface: yup
                .number()
                .nullable()
                .required(
                  t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', {
                    field: t('FORMS.OFFER_ADD.ROOM_TYPE_VALIDATION.SELECT_SURFACE_TECHNICAL'),
                  })
                ),
              windows: yup.boolean(),
            })
          ),
        })
      ),
      surroundings: yup
        .array()
        .min(1, t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.SURROUNDINGS') })),
      publicTransportDistance: yup
        .number()
        .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.PUBLIC_TRANSPORT_DISTANCE') }))
        .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.PUBLIC_TRANSPORT_DISTANCE') })),
      drive: yup.array().min(1, t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.DRIVE') })),
      buildingType: yup
        .string()
        .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.BUILDING_TYPE') })),
      constructionMaterial: yup
        .array()
        .min(1, t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.CONSTRUCTION_MATERIAL') })),
      thermalInsulation: yup
        .array()
        .min(1, t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.THERMAL_INSULATION') })),
      constructionCompletionYear: yup
        .number()
        .typeError(
          t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.CONSTRUCTION_COMPLETION_YEAR') })
        )
        .required(
          t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.CONSTRUCTION_COMPLETION_YEAR') })
        )
        .max(
          new Date().getFullYear() + 2,
          t('VALIDATION.ADD_OFFER.INVALID_DATE', { field: t('FORMS.OFFER_ADD.NAMES.BUILDING_COMPLETION_YEAR') })
        )
        .min(
          1500,
          t('VALIDATION.ADD_OFFER.INVALID_DATE', { field: t('FORMS.OFFER_ADD.NAMES.BUILDING_COMPLETION_YEAR') })
        ),
      landArea: yup
        .number()
        .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.LAND_AREA') }))
        .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.LAND_AREA') })),
    }),
    offerType: yup.mixed<OfferType.RENT>(),
    offerStatus: yup.object().shape({
      state: yup.string(),
    }),
    parkingDetails: yup.object().shape({
      parkingSurface: yup
        .number()
        .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.PARKING.PARKING_SURFACE') }))
        .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.PARKING.PARKING_SURFACE') })),
      totalParkingSurface: yup
        .number()
        .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.PARKING.PARKING_SURFACE') }))
        .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.PARKING.PARKING_SURFACE') })),
      guardedParking: yup.boolean(),
      parkingLotsAvailable: yup
        .number()
        .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.PARKING.PARKING_LOTS_AVAILABLE') }))
        .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.PARKING.PARKING_LOTS_AVAILABLE') })),
    }),
    mediaDetails: yup.object().shape({
      powerAllocation: yup
        .number()
        .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.POWER_ALLOCATION') }))
        .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.POWER_ALLOCATION') })),
      transformerIn: yup.boolean(),
      doublePowerLines: yup.boolean(),
      water: yup.boolean(),
      gas: yup.boolean(),
      sewerage: yup.boolean(),
      heating: yup.boolean(),
      fiber: yup.boolean(),
      LAN: yup.boolean(),
      phoneLine: yup.boolean(),
      energyCategory: yup
        .string()
        .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.ENERGY_CATEGORY') })),
    }),
    geoMarker: yup.object().shape({ lat: yup.number().nullable(), lng: yup.number().nullable() }),
    relatedDetails: yup.object().shape({
      warehouseEquipment: yup.array().of(yup.string()),
      warehouseGroundLevel: yup.boolean(),
      carRamp: yup.boolean(),
      warehouseManagement: yup.boolean(),
      forklift: yup.boolean(),
      gates: yup.array().of(
        yup.object().shape({
          width: yup
            .number()
            .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.WAREHOUSE_EQUIPMENT.CAR_RAMP') })),
          height: yup
            .number()
            .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.WAREHOUSE_EQUIPMENT.CAR_RAMP') })),
        })
      ),
      hallHeight: yup.number().typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.HALL_HEIGHT') })),
      lift: yup.boolean(),
      liftCapacityKg: yup
        .number()
        .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.LIFT_CAPACITY') })),
      columnsGridStructure: yup.object().shape({
        x: yup.number().nullable(),
        y: yup.number().nullable(),
      }),
    }),
    landlord: yup.string(),
    id: yup
      .string()
      .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('OWNERSHIP.LAND_AND_MORTGAGE_REGISTER_NUMBER') })),
    address: yup.object().shape({
      street: yup.string(),
      postCode: yup.string(),
      country: yup.string(),
      province: yup.string(),
      district: yup.string(),
      city: yup.string(),
      plotNumber: yup
        .string()
        .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('FORMS.OFFER_ADD.NAMES.PLOT_NUMBER') })),
      landAndMortgageRegisterNumber: yup
        .string()
        .required(
          t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.LAND_AND_MORTGAGE_REGISTER_NUMBER') })
        ),
      ownershipType: yup
        .string()
        .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.SURFACE.OWNERSHIP_TYPE') })),
      officialAddress: yup.object().shape({
        houseNumber: yup
          .string()
          .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('ADDRESS.HOUSE_NUMBER') })),
        street: yup.string().required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('ADDRESS.STREET') })),
        estate: yup.string().required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('ADDRESS.ESTATE') })),
        district: yup.string().required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('ADDRESS.DISTRICT') })),
        county: yup.string().required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('ADDRESS.COUNTY') })),
        province: yup.string().required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('ADDRESS.PROVINCE') })),
      }),
    }),
    subleaseAgreement: yup.boolean().when('address.ownershipType', {
      is: (ownershipType) => ownershipType === t('OWNERSHIP.RENT_AGREEMENT'),
      then: yup
        .boolean()
        .oneOf([true], t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('OWNERSHIP.RENT_AGREEMENT') })),
      otherwise: yup.boolean(),
    }),
    name: yup.string().required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.LEGAL.MAIN_USE') })),
    price: yup
      .number()
      .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.FINANCIAL.RENT_NET') }))
      .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.FINANCIAL.RENT_NET') })),
    priceCurrency: yup
      .string()
      .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.FINANCIAL.RENT_NET') })),
    availability: yup
      .date()
      .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.LEASE.AVAILABILITY') })),
    availabilityTo: yup
      .date()
      .nullable()
      .test(
        'availabilityTo',
        t('VALIDATION.ADD_OFFER.INVALID_DATE', { field: t('FORMS.OFFER_ADD.NAMES.OFFER_VALID_TO') }),
        function (value) {
          const { availability } = this.parent;
          if (value === null) return true;
          return value && availability && value >= availability;
        }
      )
      .typeError(t('VALIDATION.ADD_OFFER.INVALID_DATE', { field: t('FORMS.OFFER_ADD.NAMES.OFFER_VALID_TO') })),
    offerValidTo: yup
      .date()
      .typeError(t('VALIDATION.ADD_OFFER.INVALID_DATE', { field: t('FORMS.OFFER_ADD.NAMES.LEASE_VALID_TO') }))
      .min(new Date(), t('VALIDATION.ADD_OFFER.INVALID_DATE', { field: t('FORMS.OFFER_ADD.NAMES.LEASE_VALID_TO') }))
      .required(t('VALIDATION.REQUIRED_FIELD')),
    unitReceiptDeadline: yup
      .number()
      .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.LEASE.UNIT_RECEIPT_DEADLINE') }))
      .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.LEASE.UNIT_RECEIPT_DEADLINE') })),
    leaseAgreement: yup.object().shape({
      infinite: yup.boolean().when(['fixedshort', 'fixedlong'], {
        is: (fixedshort, fixedlong) => !fixedshort && !fixedlong,
        then: yup.boolean().oneOf([true], t('VALIDATION.ADD_OFFER.AT_LEAST_ONE_FIELD_REQUIRED')),
      }),
      leaseNoticePeriod: yup
        .number()
        .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.LEASE.MONTHS_NOTICE') }))
        .when('infinite', {
          is: true,
          then: yup
            .number()
            .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.LEASE.MONTHS_NOTICE') })),
        }),
      fixedshort: yup.boolean(),
      fixedshortMonths: yup
        .number()
        .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.LEASE.LEASE_TERM') }))
        .when('fixedshort', {
          is: true,
          then: yup
            .number()
            .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.LEASE.LEASE_TERM') })),
        }),
      fixedlong: yup.boolean(),
      fixedlongMonths: yup
        .number()
        .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.LEASE.LEASE_TERM') }))
        .when('fixedlong', {
          is: true,
          then: yup
            .number()
            .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.LEASE.LEASE_TERM') })),
        }),
      deposit: yup
        .number()
        .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('FORMS.OFFER_ADD.NAMES.DEPOSIT_WITH_VAT') }))
        .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('FORMS.OFFER_ADD.NAMES.DEPOSIT_WITH_VAT') })),
      payDay: yup
        .number()
        .typeError(
          t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('FORMS.OFFER_ADD.NAMES.RENT_PAYMENT_DEADLINE') })
        )
        .required(
          t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('FORMS.OFFER_ADD.NAMES.RENT_PAYMENT_DEADLINE') })
        ),
      nonRentFees: yup
        .string()
        .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('FORMS.OFFER_ADD.NAMES.NON_RENT_FEES') }))
        .typeError(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('FORMS.OFFER_ADD.NAMES.NON_RENT_FEES') })),
      lumpSum: yup.number().when('nonRentFees', {
        is: t('DETAILS.FINANCIAL.LUMP_SUM'),
        then: yup
          .number()
          .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.FINANCIAL.LUMP_SUM') })),
        otherwise: yup.number().nullable(),
      }),
      firstPaymentDay: yup
        .number()
        .typeError(
          t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('FORMS.OFFER_ADD.NAMES.FIRST_RENT_PAYMENT_FROM') })
        )
        .required(
          t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('FORMS.OFFER_ADD.NAMES.FIRST_RENT_PAYMENT_FROM') })
        ),
      averageMonthlyCostsAnnualizedKnown: yup.boolean(),
      averageMonthlyCostsAnnualized: yup.number().when(['nonRentFees', 'averageMonthlyCostsAnnualizedKnown'], {
        is: (nonRentFees, averageMonthlyCostsAnnualizedKnown) =>
          nonRentFees === t('DETAILS.FINANCIAL.METERED') && !averageMonthlyCostsAnnualizedKnown,
        then: yup
          .number()
          .required(t('VALIDATION.ADD_OFFER.REQUIRED_FIELD', { field: t('DETAILS.FINANCIAL.ANNUAL_COSTS') })),
        otherwise: yup.number().nullable(),
      }),
    }),

    // Summary checkboxes for offer acceptance
    summary: yup.object().shape({
      summary1: yup.bool().oneOf([true], t('VALIDATION.ADD_OFFER.LEASE_TERMS_REQUIRED')),
      summary2: yup.bool().oneOf([true], t('VALIDATION.ADD_OFFER.ACCEPT_TENANT_SIGNATURE_REQUIRED')),
      summary3: yup.bool().oneOf([true], t('VALIDATION.ADD_OFFER.OFFER_ARTICLE_66_REQUIRED')),
      summary4: yup.bool().oneOf([true], t('VALIDATION.ADD_OFFER.WITHDRAWAL_CONDITION_REQUIRED')),
    }),
  });
};

import { Button, Collapse, CollapseProps, Space } from 'antd';
import Typography from 'antd/es/typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'rsuite';

import { App } from '../App/App';

import RecruitmentHeader from './RecruitmentHeader';

const { Title, Paragraph } = Typography;

export const Recruitment: React.FC = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSc9lRv1hyl6Qtnw-7iB2WYvmnUpmnxBMggQyLtclZ5iRsEAmQ/viewform',
      '_blank'
    );
  };

  const whyWithUs: CollapseProps['items'] = [
    {
      key: '2',
      label: t('RECRUITMENT.CAREER_DEVELOPMENT'),
      children: <p>{t('RECRUITMENT.CAREER_DESCRIPTION')}</p>,
    },
    {
      key: '3',
      label: t('RECRUITMENT.ATTRACTIVE_SALARY'),
      children: <p>{t('RECRUITMENT.SALARY_DESCRIPTION')}</p>,
    },
    {
      key: '4',
      label: t('RECRUITMENT.FLEXIBLE_WORK_CONDITIONS'),
      children: <p>{t('RECRUITMENT.WORK_CONDITIONS_DESCRIPTION')}</p>,
    },
    {
      key: '5',
      label: t('RECRUITMENT.TRAINING_SUPPORT'),
      children: <p>{t('RECRUITMENT.TRAINING_SUPPORT_DESCRIPTION')}</p>,
    },
    {
      key: '6',
      label: t('RECRUITMENT.FUTURE_IN_MANAGEMENT'),
      children: <p>{t('RECRUITMENT.FUTURE_MANAGEMENT_DESCRIPTION')}</p>,
    },
  ];

  const whoWeAre: CollapseProps['items'] = [
    {
      key: '2',
      label: t('RECRUITMENT.CURRENT_OFFERS'),
      children: <p>{t('RECRUITMENT.CURRENT_OFFERS_DESCRIPTION')}</p>,
    },
    {
      key: '3',
      label: t('RECRUITMENT.VERIFICATION'),
      children: <p>{t('RECRUITMENT.VERIFICATION_DESCRIPTION')}</p>,
    },
    {
      key: '4',
      label: t('RECRUITMENT.COMPREHENSIVENESS'),
      children: <p>{t('RECRUITMENT.COMPREHENSIVENESS_DESCRIPTION')}</p>,
    },
    {
      key: '5',
      label: t('RECRUITMENT.SPEED_AND_EFFICIENCY'),
      children: <p>{t('RECRUITMENT.SPEED_DESCRIPTION')}</p>,
    },
    {
      key: '6',
      label: t('RECRUITMENT.SECURITY'),
      children: <p>{t('RECRUITMENT.SECURITY_DESCRIPTION')}</p>,
    },
    {
      key: '7',
      label: t('RECRUITMENT.INNOVATION'),
      children: <p>{t('RECRUITMENT.INNOVATION_DESCRIPTION')}</p>,
    },
  ];

  return (
    <App>
      <RecruitmentHeader />
      <Space className="block p-2 lg:p-0 lg:m-16 w-full lg:w-1/2 lg:mx-auto">
        <Title>{t('RECRUITMENT.HEADING')}</Title>
        <Paragraph>{t('RECRUITMENT.INTRODUCTION')}</Paragraph>
        <Title level={2} className="mt-12">
          {t('RECRUITMENT.WHY_US')}
        </Title>
        <Collapse accordion items={whyWithUs} className="my-8" />
        <Title level={2}>{t('RECRUITMENT.WHO_WE_ARE')}</Title>
        <Paragraph>{t('RECRUITMENT.WHO_WE_ARE_DESCRIPTION')}</Paragraph>
        <Collapse accordion items={whoWeAre} className="my-8" />
        <Title level={2}>{t('RECRUITMENT.WHO_WE_LOOK_FOR')}</Title>
        <Paragraph>{t('RECRUITMENT.WHO_WE_LOOK_FOR_DESCRIPTION')}</Paragraph>
        <Title level={2}>{t('RECRUITMENT.TASKS')}</Title>
        <Paragraph>{t('RECRUITMENT.TASKS_DESCRIPTION')}</Paragraph>
        <Title level={2}>{t('RECRUITMENT.WHAT_WE_OFFER')}</Title>
        <Paragraph>{t('RECRUITMENT.OFFER_DESCRIPTION')}</Paragraph>
        <Title level={2}>{t('RECRUITMENT.HOW_TO_APPLY')}</Title>
        <Paragraph>
          {t('RECRUITMENT.APPLY_LINK')} <a onClick={handleClick}>{t('RECRUITMENT.SURVEY_LINK')}</a>{' '}
          {t('RECRUITMENT.APPLY_DESCRIPTION')}
        </Paragraph>
        <Button type="primary" size="large" className="mt-4" onClick={handleClick}>
          {t('RECRUITMENT.JOIN_US')}
        </Button>
        <Divider className="mb-48 mt-8"></Divider>
      </Space>
    </App>
  );
};

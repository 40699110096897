import type { MenuProps } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

import { App } from '../../../App/App';
import { AppRoutes } from '../../../Routing/routing.model';
import { MenuWrapper } from '../../components/MenuWrapper';
import { additionalNavigation } from '../../Menu/AdditionalNavigation';
import { MenuItems } from '../../Menu/Menu';

export const Notifications: React.FC = () => {
  const { push } = useHistory();
  const onClick: MenuProps['onClick'] = (e) => {
    additionalNavigation(e.key);
    push(e.key);
  };

  return (
    <App>
      <MenuWrapper>
        <MenuItems
          onClick={onClick}
          defaultSelectedKeys={[AppRoutes.MOJA_TABLICA_NOTIFICATIONS]}
          defaultOpenKeys={['sub2', 'sub3', 'sub4']}
        />
      </MenuWrapper>
      <p>zarządzaj powiadomieniami</p>
    </App>
  );
};

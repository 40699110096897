import { FormikHelpers } from 'formik/dist/types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ToastVariant } from '../../../../components/Toast/toast.model';
import { toastService } from '../../../../components/Toast/toast.service';
import { ForgotPasswordFormValues } from '../../auth.model';
import { useAuth } from '../../AuthContext';

export function useOnSubmit(): (
  values: ForgotPasswordFormValues,
  formikHelpers: FormikHelpers<ForgotPasswordFormValues>
) => Promise<void> {
  const { sendPasswordResetEmail, closeModal } = useAuth();
  const { t } = useTranslation();
  return useCallback(({ email }) => {
    return sendPasswordResetEmail(email)
      .then(() => {
        toastService.show(t('AUTH.FORGOT_PASSWORD.API_RESPONSE_SUCCESS'), t('SUCCESS'));
        closeModal();
      })
      .catch((e) => {
        toastService.show(e.message, t('ERROR'), { variant: ToastVariant.ERROR });
      });
  }, []);
}

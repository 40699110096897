import { Badge, Popover, Skeleton } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next'; // Importujemy hook do tłumaczeń

import { useAuth } from '../../Auth/AuthContext';

export const SignInToShow: React.FC<{ text?: string; rows?: number }> = ({ text, rows }) => {
  const { showSignInModal } = useAuth();
  const { t } = useTranslation(); // Inicjalizacja hooka do tłumaczeń

  const content = (
    <div className="w-96">
      <div>
        {t('SIGN_IN_TO_SHOW.OFFER_EXPLANATION')} {/* Zamiast sztywnego tekstu */}
        <a className="underline text-blue-500" onClick={() => showSignInModal()}>
          {t('SIGN_IN_TO_SHOW.SIGN_IN')} {/* Zalogowanie się */}
        </a>
        {t('SIGN_IN_TO_SHOW.ACCESS_DETAILS')} {/* Aby uzyskać dostęp */}
      </div>
    </div>
  );

  return (
    <Popover
      content={content}
      title={t('SIGN_IN_TO_SHOW.POPOVER_TITLE')} // "Dlaczego nie mogę zobaczyć szczegółów?"
      trigger="hover"
      placement="bottomLeft">
      <div className="text-white -my-4">{text ? text : t('SIGN_IN_TO_SHOW.DEFAULT_TEXT')}</div>
      <Badge.Ribbon
        text={
          <a className="hover:text-gray-50" onClick={() => showSignInModal()}>
            {text ? text : t('SIGN_IN_TO_SHOW.DEFAULT_TEXT')}
          </a>
        }>
        <div className="h-10"></div>
        <Skeleton active paragraph={{ rows: rows ? rows : 2 }} title={false} />
      </Badge.Ribbon>
    </Popover>
  );
};

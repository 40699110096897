import { LeftOutlined } from '@ant-design/icons';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'rsuite';

import { AppRoutes } from '../../Routing/routing.model';

export const ContinueEditing: React.FC<{ offerId: string }> = ({ offerId }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(AppRoutes.ADD_OFFER.replace(':id', offerId));
  };

  return (
    <Button appearance="default" onClick={handleClick} style={{ display: 'block', marginBottom: '10px' }}>
      <div className="flex flex-row items-center justify-center">
        <LeftOutlined rev={undefined} className="pr-2" />
        Edytuj dalej
      </div>
    </Button>
  );
};

export interface SignUpFormValues {
  email: string;
  password: string;
  passwordConfirm: string;
  marketingConsent: boolean;
}
export interface ExtendedSignUpFormValues extends SignUpFormValues {
  firstName: string;
  lastName: string;
  marketingConsent: boolean;
}
export interface UserProfileFormValues {
  firstName: string;
  lastName: string;
}

export interface CompleteSignUpFormValues extends SignUpFormValues, UserProfileFormValues {}

export interface SignInFormValues {
  email: string;
  password: string;
}

export interface NonEmailSignUpFormValues {
  firstName: string;
  lastName: string;
  marketingConsent: boolean;
  email: string;
  uid: string;
}

export interface ForgotPasswordFormValues {
  email: string;
}

export enum AuthDialog {
  SIGN_IN,
  SIGN_UP,
  FORGOT_PASSWORD,
  NON_EMAIL_SIGN_UP,
  VERIFY_EMAIL,
}

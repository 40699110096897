import * as React from 'react';
import styled from 'styled-components';
import { Icon } from 'rsuite';

import { Typography } from '../Typography/Typography';

const TileWrapper = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 400px;
  position: relative;
`;
const SquareWrapper = styled.div<{ color: 'green' | 'yellow' | 'blue' }>`
  width: 85px;
  height: 85px;
  line-height: 97px;
  text-align: center;
  margin: 15px auto;
  position: relative;

  :before {
    content: '';
    position: absolute;
    border-radius: 20px;
    width: 85px;
    height: 85px;
    top: 0;
    left: 0;
    transform: rotate(45deg);
    background: #e7faf5;
    border: 1px solid transparent;

    ${({ color }) => {
      switch (color) {
        case 'green':
          return `
            background: rgba(15, 202, 152, 0.1);
            border-color: rgba(15, 202, 152, 0.3);
            color: #29cf8d !important;
          `;
        case 'yellow':
          return `
          background: rgba(255,143,0,.1);
          border-color: rgba(255,143,0,.3);
          color: #ff8f00!important;`;
        case 'blue':
          return `
          color: #1d96ff!important;
          background: rgba(29,150,255,.1);
          border-color: rgba(29,150,255,.3);`;
      }
    }}
  }
`;
const SquareIcon = styled(Icon)<{ color: 'green' | 'yellow' | 'blue' }>`
  position: relative;
  z-index: 10;
  font-size: 30px;

  ${({ color }) => {
    switch (color) {
      case 'green':
        return `color: #0fca98;`;
      case 'yellow':
        return `color: #ff9b20;`;
      case 'blue':
        return `color: #1d96ff;`;
    }
  }}
`;
const TileHeader = styled(Typography.H3)`
  text-align: center;
  margin-bottom: 12px;
`;
const TileSubHeader = styled(Typography.H6)`
  text-align: center;
  color: #4e5c79;
  font-weight: 400;
`;

interface Props {
  title: string;
  description: string;
  color: 'blue' | 'green' | 'yellow';
  icon: string;
}
export const HowItWorksTile: React.FC<Props> = ({ title, description, color, icon }) => {
  return (
    <TileWrapper>
      <SquareWrapper color={color}>
        <SquareIcon icon={icon} color={color} />
      </SquareWrapper>
      <TileHeader>{title}</TileHeader>
      <TileSubHeader>{description}</TileSubHeader>
    </TileWrapper>
  );
};

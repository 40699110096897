import firebase from 'firebase/app';
import 'firebase/firestore';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { firestore } from '../../../firebase/firebase';
import { FirestoreCollection } from '../../../firebase/firebase.models';

const db = firebase.firestore();

interface SessionData {
  offerId: string;
  sessionId: string;
  deviceId: string;
  timestamp: number;
}

const SESSION_TIMEOUT = 14 * 1000; // 30 s
const TIMESTAMP_UPDATE_INTERVAL = 15 * 1000; // 15 s

const useSessionTracker = (offerId: string | null): void => {
  const sessionId = uuidv4();

  // Sprawdzenie lub generowanie unikalnego identyfikatora urządzenia
  const deviceId = Cookies.get('device_id') || uuidv4();
  Cookies.set('device_id', deviceId, { expires: 365 }); // Przechowuj identyfikator przez rok

  useEffect(() => {
    removeInactiveSessions(offerId);
  }, [offerId]);

  useEffect(() => {
    if (!offerId) return;

    const sessionRef = db.collection(FirestoreCollection.SESSIONS).doc(`${offerId}_${sessionId}`);
    const sessionData: SessionData = {
      offerId,
      sessionId,
      deviceId,
      timestamp: Date.now(),
    };

    // Funkcja do tworzenia lub aktualizacji sesji z identyfikatorem urządzenia
    const createOrUpdateSession = async () => {
      try {
        // Użyj `set` z opcją `merge: true`, aby upewnić się, że dokument zostanie utworzony lub zaktualizowany
        await sessionRef.set(sessionData, { merge: true });
      } catch (error) {
        console.error('Failed to set session data:', error);
      }
    };
    createOrUpdateSession();

    // Aktualizacja znacznika czasu co 15 sekund
    const intervalId = setInterval(async () => {
      try {
        // Przed aktualizacją sprawdź, czy dokument istnieje
        const doc = await sessionRef.get();
        if (doc.exists) {
          await sessionRef.update({ timestamp: Date.now() });
        } else {
          console.warn('Document does not exist. Creating the document.');
          await sessionRef.set(sessionData); // Utwórz dokument, jeśli nie istnieje
        }
      } catch (error) {
        console.error('Failed to update session timestamp:', error);
      }
    }, TIMESTAMP_UPDATE_INTERVAL);

    // Nasłuch na inne sesje tego samego użytkownika, ale z różnych urządzeń
    const unsubscribe = firestore
      .collection(FirestoreCollection.SESSIONS)
      .where('offerId', '==', offerId)
      .onSnapshot(
        (snapshot) => {
          const currentTime = Date.now();

          const activeSessions = snapshot.docs
            .map((doc) => doc.data() as SessionData)
            .filter((session) => session.sessionId !== sessionId) // Pomijanie tej samej sesji
            .filter((session) => session.deviceId !== deviceId) // Pomijanie sesji z tego samego urządzenia
            .filter((session) => currentTime - session.timestamp < SESSION_TIMEOUT); // Ignorowanie starych sesji

          if (activeSessions.length > 0) {
            alert('Masz otwartą inną sesję na innym urządzeniu.');
          }
        },
        (error) => {
          console.error('Error listening for session changes:', error);
        }
      );

    // Usunięcie sesji przy zamykaniu okna
    const handleBeforeUnload = async () => {
      try {
        await sessionRef.delete();
      } catch (error) {
        console.error('Failed to delete session on unload:', error);
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      clearInterval(intervalId);
      unsubscribe();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [offerId, sessionId, deviceId]);
};

export default useSessionTracker;
const removeInactiveSessions = async (offerId: string | null) => {
  if (!offerId) {
    console.warn('offerId is undefined or null, skipping removeInactiveSessions.');
    return;
  }

  const currentTime = Date.now();
  try {
    const snapshot = await firestore.collection(FirestoreCollection.SESSIONS).where('offerId', '==', offerId).get();

    const inactiveSessions = snapshot.docs
      .map((doc) => doc.data() as SessionData)
      .filter((session) => currentTime - session.timestamp >= SESSION_TIMEOUT);

    for (const session of inactiveSessions) {
      try {
        await firestore
          .collection(FirestoreCollection.SESSIONS)
          .doc(`${session.offerId}_${session.sessionId}`)
          .delete();
      } catch (error) {
        console.error('Failed to delete inactive session:', error);
      }
    }
  } catch (error) {
    console.error('Error fetching or deleting inactive sessions:', error);
  }
};

import firebase from 'firebase';

import { firestore } from '../../firebase/firebase';
import { FirestoreCollection } from '../../firebase/firebase.models';

interface Props {
  offerId: string;
  accepted: boolean;
  currentUser: firebase.User;
}

// Modify the function to return a status object instead of calling message.error inside the hook
export const handleOnProtocolSign = async ({
  offerId,
  accepted,
  currentUser,
}: Props): Promise<{ success: boolean; description?: string }> => {
  if (!offerId || !currentUser) {
    return { success: false, description: 'MISSING_DATA' }; // Returning a description for missing data
  }

  try {
    if (accepted) {
      await firestore.collection(FirestoreCollection.OFFERS).doc(offerId).update({
        'protocolData.protocolSigningUid': currentUser.uid,
        'protocolData.signedAt': new Date().toLocaleString(),
      });
      console.log('Dane protokołu zapisane pomyślnie');
      return { success: true }; // Success case
    }
    return { success: true }; // If accepted is false, still return success (if no error occurred)
  } catch (error) {
    console.error('Error in handleOnProtocolSign:', error);
    return { success: false, description: 'CAN_NOT_SAVE' }; // Error case with description
  }
};

import { firestore } from '../../../../../../firebase/firebase';
import { FirestoreCollection } from '../../../../../../firebase/firebase.models';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function deleteUser(uid: string, currentUser: any): Promise<[string, boolean]> {
  let response = '';
  let respError = false;
  try {
    // czy kasować też projekty ofert usera czy zostawić do statystyk?
    await currentUser
      .delete()
      .then(async () => {
        await firestore
          .collection(FirestoreCollection.USERS)
          .doc(uid)
          .delete()
          .then(() => {
            response = 'Usunięto uŻytkownika';
            respError = false;
          });
      })
      .catch((error) => {
        console.log(error);
        response = error.message.toString();
        respError = true;
      });
  } catch (error) {
    console.error('Wystąpił błąd podczas usuwania użytkownika:', error);
    response = 'Wystąpił błąd podczas usuwania użytkownika';
    respError = true;
  }

  return [response, respError];
}

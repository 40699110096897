export function truncateText({
  text,
  startLength = 3,
  endLength = 3,
}: {
  text: string;
  startLength?: number;
  endLength?: number;
}): string {
  if (text.length <= startLength + endLength) {
    return text;
  }
  const start = text.slice(0, startLength);
  const end = text.slice(-endLength);
  return `${start}...${end}`;
}

import { firestore } from '../firebase/firebase';
import { FirestoreCollection } from '../firebase/firebase.models';

export const useSequentialId = (): { generateSequentialId: () => Promise<number> } => {
  const adminRef = firestore.collection(FirestoreCollection.ADMIN);
  const counterRef = adminRef.doc('counter');

  const generateSequentialId = async (): Promise<number> => {
    return firestore.runTransaction(async (transaction) => {
      return transaction.get(counterRef).then(async (counterDoc) => {
        let newCounter: number;

        if (!counterDoc.exists) {
          newCounter = 0;
          await counterRef.set({ last: newCounter });
        } else {
          newCounter = counterDoc.data().last + 1;
          transaction.update(counterRef, { last: newCounter });
        }

        return newCounter;
      });
    });
  };

  return { generateSequentialId };
};

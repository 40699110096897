import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../models/main.model';

export function useWarehouseRoomTypeOptions(): SelectOption[] {
  const { t } = useTranslation();

  return useMemo(
    () =>
      ['warsztat', 'open space', 'hala magazynowa', 'hala produkcyjna', 'chłodnia'].map((item) => ({
        label: t(`FORMS.OFFER_ADD.WAREHOUSE_ROOM_TYPE.${item.toUpperCase()}`),
        value: item,
      })),
    [t]
  );
}

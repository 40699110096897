import { Modal } from 'antd';
import { t } from 'i18next';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { OfferRelatedWrapper } from '../../components/OfferRelatedWrapper/OfferRelatedWrapper';
import { ToastVariant } from '../../components/Toast/toast.model';
import { toastService } from '../../components/Toast/toast.service';
import { onLogOfferAddConfirm } from '../../events/hooks/onLogOfferAddConfirm.hook';
import { firebaseFunctions } from '../../firebase/firebase';
import { FirebaseCallableFunctions, FunctionAddOfferPayload } from '../../firebase/firebase.models';
import { useGetOffer } from '../../firebase/hooks/getOfferById.hook';
import { useGetOfferImages } from '../../hooks/getOfferImages.hook';
import { App } from '../App/App';
import { useAuth } from '../Auth/AuthContext';
import { AppRoutes } from '../Routing/routing.model';

import { createPDFs } from './Documents/createPDFs';
import { ConfigType } from './Documents/documents.models';
interface Props {
  offerId: string;
  value: string;
  userId: string;
}

export const OfferEditConfirm: React.FC<Props> = ({ offerId, value, userId }) => {
  const { isAuthenticating, isAuth, currentUser } = useAuth();
  if (currentUser.uid !== userId) {
    Modal.error({
      title: <p>{t('NO_ACCESS')}</p>,
      okButtonProps: { style: { display: 'none' } },
    });
    return <></>;
  } else {
    return (
      <App>
        {!isAuthenticating && isAuth ? <WhenAuth offerId={offerId} value={value} userId={userId} /> : <WhenNotAuth />}
      </App>
    );
  }
};

const WhenAuth: React.FC<Props> = ({ offerId, value }) => {
  const { push } = useHistory();
  const isLoading = useRef(false);
  const [offer] = useGetOffer(offerId);
  const imagesUrl = useGetOfferImages(offerId);
  const [successfullyConfirmed, setSuccessfullyConfirmed] = useState(false);
  const { t } = useTranslation();
  const onCounterOfferAccepted = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_COUNTER_OFFER_ACCEPTED);
  const onOfferAddConfirm = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_OFFER_ADD_CONFIRM);

  const onCounterOfferAcceptedPayload = { offerId };
  const functionSignOfferConfirmPayload: FunctionAddOfferPayload = { offerId };

  useEffect(() => {
    if (isLoading.current) return;
    if (!offer) return;
    if (offerId === undefined) return push(AppRoutes.HOME, { offerId });

    isLoading.current = true;

    if (value === 'accept') {
      onCounterOfferAccepted(onCounterOfferAcceptedPayload)
        .then(() => {
          const newOffer = { ...offer };
          if (offer.counteroffer.isRentToggleChecked) {
            newOffer.price = offer.counteroffer.newPrice;
          }
          if (offer.counteroffer.isContractDurationToggleChecked) {
            newOffer.leaseAgreement.fixedlongMonths = offer.counteroffer.newDuration;
          }
          console.log(newOffer);

          createPDFs(newOffer, ConfigType.OFFER)
            .then(() => {
              // Ustawienie stanu i logowanie operacji niezależnie od wyniku onOfferAddConfirm
              setSuccessfullyConfirmed(true);
              onLogOfferAddConfirm(offerId);

              onOfferAddConfirm(functionSignOfferConfirmPayload)
                .catch(() => {
                  push(AppRoutes.HOME);
                  toastService.show(t('CHECK_CORRECTNESS'), t('ERROR_DESCRIPTION'), {
                    variant: ToastVariant.ERROR,
                  });
                })
                .finally(() => {
                  isLoading.current = false;
                });
            })
            .catch((e) => {
              console.error(e);
              isLoading.current = false;
            });
        })
        .catch((e) => {
          console.error(e);
        });
    } else if (value === 'decline') {
      const onCounterOfferDeclined = firebaseFunctions.httpsCallable(
        FirebaseCallableFunctions.ON_COUNTER_OFFER_DECLINED
      );
      onCounterOfferDeclined({ tenant: offer.counteroffer.tenant, offerName: offer.name }).then(() => {
        setSuccessfullyConfirmed(true);
      });
    }
  }, [offer, offerId, value]);

  if (value === 'decline') {
    return (
      <>
        {offer && (
          <OfferRelatedWrapper
            images={imagesUrl}
            offer={offer}
            loading={!successfullyConfirmed}
            description={
              <div>
                Nie zaakceptopwałeś zmian w{' '}
                <div
                  className="text-blue-700 underline font-medium"
                  onClick={() => push(AppRoutes.OFFER.replace(':id', offerId))}>
                  ofercie.
                </div>
              </div>
            }
            title={t('PROPOSAL_NOT_ACCEPTED')}
            loadingText={t('PROCESSING')}
            loadingGif={'/images/assets/build.gif'}
          />
        )}
      </>
    );
  } else if (value === 'accept') {
    return (
      <>
        {offer && (
          <OfferRelatedWrapper
            images={imagesUrl}
            offer={offer}
            loading={!successfullyConfirmed}
            description={
              <div>
                {t('OFFER_ADJUSTED')}
                <br></br>
                <a href={`https://${process.env.REACT_APP_DOMAIN}/offer/${offerId}`} className="text-blue-500">
                  {t('VIEW_OFFER')}
                </a>
              </div>
            }
            title={t('CONGRATULATIONS')}
            loadingText={t('EDITING')}
            loadingGif={'/images/assets/build.gif'}
          />
        )}
      </>
    );
  }
};

const WhenNotAuth: React.FC = () => {
  const { showSignInModal, closeModal } = useAuth();

  useEffect(() => {
    showSignInModal();
    return () => closeModal();
  }, []);

  return <></>;
};

import { Modal } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

import { AppRoutes } from '../../features/Routing/routing.model';

interface Props {
  isCompanyModalOpen: boolean;
}

export const CompanyModal: React.FC<Props> = ({ isCompanyModalOpen }) => {
  const { push } = useHistory();

  const handleCompanyOk = () => {
    push(AppRoutes.MOJA_TABLICA_LINK_COMPANY);
  };

  return (
    <Modal
      title="Twoje konto ma niepotwierdzoną firmę"
      open={isCompanyModalOpen}
      onOk={handleCompanyOk}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={false}>
      <p>Zanim wystawisz ofertę musisz potwierdzić swoją firmę</p>
    </Modal>
  );
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../models/main.model';
import { LegalWarehouseUseAccepted } from '../../../../models/offers.model';

export function usePremisesPurposeWarehouseSelectOptions(): SelectOption[] {
  const { t } = useTranslation();
  return useMemo(
    () =>
      Object.entries(LegalWarehouseUseAccepted).map(([, value]) => ({
        label: t(`FORMS.PREMISES_PURPOSE.OPTIONS.${value.toUpperCase()}`),
        value,
      })),
    [t]
  );
}

import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Spin, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ToastVariant } from '../../../components/Toast/toast.model';
import { toastService } from '../../../components/Toast/toast.service';
import { firebaseFunctions } from '../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../firebase/firebase.models';
import { gtm } from '../../../reportGTM';
import { useAuth } from '../../Auth/AuthContext';
import { App } from '../App';

import { Account } from './Account';
import { Administration } from './Administration';
import { Documents } from './Documents';
import { General } from './General';
import { Terminology } from './Terminology';

interface TitleProps {
  children: React.ReactNode;
  className?: string;
}

export const Title: React.FC<TitleProps> = ({ children, className }) => {
  return <div className={`text-blue-500 opacity-80 font-light text-lg my-2 ${className}`}>{children}</div>;
};

const askGemini = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_ASK_GEMINI_ABOUT_FAQ);
const report = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_REPORT);

const FAQ: React.FC = () => {
  const [showResponse, setShowResponse] = React.useState(false);
  const [showFormWhyNo, setShowFormWhyNo] = React.useState(false);
  const [showFormWhy, setShowFormWhy] = React.useState(true);

  const [userQuestion, setUserQuestion] = React.useState('');
  const [responseText, setResponseText] = React.useState('');

  const [loadingResponse, setLoadingResponse] = React.useState(false);

  const [userFeedback, setUserFeedback] = React.useState('');
  const [showFeedback, setShowFeedback] = React.useState(true);

  const { currentUser } = useAuth();

  const { t } = useTranslation();

  const handleAskQuestion = async () => {
    if (!userQuestion) {
      return;
    }
    setLoadingResponse(true);
    try {
      const result = await askGemini({ question: userQuestion });
      setResponseText(result.data.answer);
      setShowResponse(true);
    } catch (error) {
      console.error('Błąd podczas uzyskiwania odpowiedzi:', error);
      setResponseText('Przepraszamy, wystąpił błąd podczas generowania odpowiedzi.');
      setShowResponse(true);
    }
    setLoadingResponse(false);
  };

  const handleSendFeedback = async () => {
    setShowFormWhy(false);
    try {
      await report({
        userUid: currentUser?.uid ?? 'anonymous',
        title: 'FAQ - Feedback',
        type: 'Suggestion',
        description: userFeedback,
      });
    } catch (error) {
      toastService.show('Błąd podczas wysyłania opinii', 'Błąd', { variant: ToastVariant.ERROR });
      console.error('Błąd podczas wysyłania opinii:', error);
    }
  };

  return (
    <App>
      <div className="flex flex-col bg-blue-100 bg-gradient-to-t from-blue-300 to-blue-100 p-6 shadow-lg">
        <Typography.Text className="text-center opacity-80 text-3xl">{t('FAQ.TITLE')}</Typography.Text>

        <Input
          placeholder={t('FAQ.SEARCH_PLACEHOLDER')}
          prefix={loadingResponse ? <Spin className="mr-1" /> : <SearchOutlined onClick={handleAskQuestion} />}
          onChange={(e) => setUserQuestion(e.target.value)}
          onPressEnter={handleAskQuestion}
          className="my-8 w-2/3 md:w-1/3 mx-auto"
        />

        {/* Sekcja odpowiedzi AI */}
        {showResponse && (
          <div className="my-4 text-center">
            <Typography.Text className="text-lg">
              <div dangerouslySetInnerHTML={{ __html: responseText }} className="w-4/5 md:w-2/3 mx-auto" />
            </Typography.Text>
            {showFeedback && (
              <div className="mt-4">
                <Typography.Text className="opacity-75">{t('FAQ.HELPFUL_QUESTION')}</Typography.Text>
                <div
                  className="mt-2"
                  onClick={() => {
                    setShowFeedback(false);
                  }}>
                  <Button type="primary" className="mx-2" onClick={() => gtm('search_helpful', { helpful: 'yes' })}>
                    {t('YES')}
                  </Button>
                  <Button type="default" className="mx-2" onClick={() => gtm('search_helpful', { helpful: 'no' })}>
                    {t('NO')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}

        <Typography.Text className="text-center opacity-50 text-sm my-8">{t('FAQ.NO_ANSWER_FOUND')}</Typography.Text>

        {/* Link do przewinięcia do sekcji FAQ */}
        <a href="#faq-sections" className="text-blue-500 text-center">
          {t('FAQ.GO_TO_FAQ_LINK')}
        </a>
      </div>

      <div id="faq-sections" className="p-8 mb-32 mx-auto" style={{ maxWidth: '1720px' }}>
        {/* Najczęściej zadawane pytania */}
        <Typography.Title level={3} className="text-left my-8">
          {t('FAQ.FREQUENT_QUESTIONS_TITLE')}
        </Typography.Title>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <General />
          <Account />
          <Terminology />
          <Administration />
          <Documents />
        </div>

        {/* Sekcja oceny pomocy */}
        {showFormWhy ? (
          <div className="mt-8 justify-center flex flex-col w-2/3 md:w-1/3 mx-auto">
            <div className="my-4 justify-center flex-col flex mx-auto">
              <Typography.Title level={4}>{t('FAQ.DID_WE_HELP_TITLE')}</Typography.Title>
              <div className="flex justify-center">
                <Button type="primary" className="mx-2" onClick={() => setShowFormWhy(false)}>
                  {t('YES')}
                </Button>
                <Button type="default" className="mx-2" onClick={() => setShowFormWhyNo(true)}>
                  {t('NO')}
                </Button>
              </div>
            </div>
            {showFormWhyNo && (
              <div className="mt-4 flex flex-col">
                <Typography.Text>{t('FAQ.IMPROVEMENT_SUGGESTION')}</Typography.Text>
                <Input.TextArea
                  rows={4}
                  className="mt-2"
                  placeholder={t('FAQ.SUGGESTION_PLACEHOLDER')}
                  onChange={(e) => setUserFeedback(e.target.value)}
                />
                <Button type="primary" className="mt-2" onClick={handleSendFeedback}>
                  {t('SEND')}
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className="mt-8 justify-center flex-col flex mx-auto w-2/3 md:w-1/3">
            <Typography.Title level={4} className="justify-center flex">
              {t('FAQ.THANK_YOU_FOR_FEEDBACK')}
            </Typography.Title>
          </div>
        )}
      </div>
    </App>
  );
};

export default FAQ;

import { Button, Image, Modal, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

interface ImageVerificationModalProps {
  show: boolean;
  onClose: () => void;
  verificationResults: string | null;
  onChangeImage: (imageUrl: string) => void;
}

export const ImageVerificationModal: React.FC<ImageVerificationModalProps> = ({
  show,
  onClose,
  verificationResults,
  onChangeImage,
}) => {
  if (!verificationResults) return null;
  const { t } = useTranslation();
  // Przetwórz odpowiedź do łatwiejszego wyświetlania
  const results = verificationResults.split('\n').map((result) => {
    const [imageLine, reasonLine] = result.split(' jest nieodpowiednie z powodu: ');
    const imageUrl = imageLine.split(': ')[1];
    return { imageUrl, reason: reasonLine };
  });

  return (
    <Modal
      open={show}
      title={t('VERIFYING_IMAGES')}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          {t('CLOSE')}
        </Button>,
      ]}>
      {results.map((result, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
          <Image
            src={result.imageUrl}
            alt={t('IMAGE_UNDER_VERIFICATION')}
            style={{ maxWidth: '100%', marginBottom: '10px' }}
          />
          <Text type="danger">{result.reason}</Text>
          <div style={{ marginTop: '10px' }}>
            <Button type="primary" danger onClick={() => onChangeImage(result.imageUrl)}>
              {t('CHANGE_PHOTO')}
            </Button>
          </div>
        </div>
      ))}
    </Modal>
  );
};

import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Flag from 'react-world-flags';
import { Nav } from 'rsuite';

import { useAuth } from '../../features/Auth/AuthContext';
import { firestore } from '../../firebase/firebase';
import { FirestoreCollection } from '../../firebase/firebase.models';
import { Language } from '../../i18n/i18n.model';
import { getCurrentLanguage, switchLanguageTo } from '../../i18n/i18n.service';
import './LanguageSwitcher.css';

interface FlagButtonProps {
  code: string;
  onClick?: () => void;
}

const languageToFlagMapping: Record<Language, string> = {
  [Language.EN]: 'GB',
  [Language.PL]: 'PL',
};

const FlagButton: React.FC<FlagButtonProps> = ({ code, onClick }) => (
  <Flag
    code={code}
    onClick={onClick}
    className="h-6 w-8 cursor-pointer hover:opacity-80 transition-opacity duration-50 z-30 mb-2"
  />
);

interface Props {
  languages?: string[];
}

const LanguageSwitcher: React.FC<Props> = ({ languages }) => {
  const [expanded, setExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const currentLanguage = getCurrentLanguage();
  const { currentUser } = useAuth();
  // const location = useLocation();

  const getFlagCode = (language: Language): string => {
    return languageToFlagMapping[language] || 'PL';
  };

  const handleLanguageChange = async (language: Language) => {
    await handleUpdateUserLangPreference(language, currentUser?.uid);
    switchLanguageTo(language);
    setExpanded(false);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleOutsideClick);
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const otherLanguages = languages ? languages.filter((lang) => lang !== currentLanguage) : [Language.PL];

  return (
    <div ref={containerRef}>
      <div className="cursor-pointer z-10" onClick={() => setExpanded(!expanded)}>
        <Nav.Item>
          <FlagButton code={getFlagCode(currentLanguage)} />
        </Nav.Item>

        <CSSTransition in={expanded} timeout={100} classNames="fade" unmountOnExit>
          <div className="absolute flex items-center flex-col z-10">
            {otherLanguages.map((lang) => (
              <Nav.Item key={lang}>
                <FlagButton
                  code={getFlagCode(lang as Language)}
                  onClick={() => handleLanguageChange(lang as Language)}
                />
              </Nav.Item>
            ))}
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

export default LanguageSwitcher;

async function handleUpdateUserLangPreference(language: Language, uid?: string) {
  if (!uid) {
    return;
  }
  const docRef = firestore.collection(FirestoreCollection.USERS).doc(uid);
  await docRef.update({ lang: language });
}

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { SignUpFormValues, UserProfileFormValues } from '../../auth.model';

export const useValidation1 = (): yup.SchemaOf<SignUpFormValues> => {
  const { t } = useTranslation();
  return yup.object().shape({
    email: yup
      .string()
      .email(t('AUTH.SIGN_UP.INPUT_EMAIL_VALIDATION_INVALID'))
      .required(t('AUTH.SIGN_UP.INPUT_EMAIL_VALIDATION_REQUIRED')),
    password: yup
      .string()
      .min(8, t('AUTH.SIGN_UP.INPUT_PASSWORD_VALIDATION_MIN')) // Minimum 8 characters
      .max(50, t('AUTH.SIGN_UP.INPUT_PASSWORD_VALIDATION_MAX')) // Maximum 50 characters
      .matches(/[A-Z]/, t('AUTH.SIGN_UP.INPUT_PASSWORD_VALIDATION_UPPERCASE')) // At least one uppercase letter
      .matches(/[a-z]/, t('AUTH.SIGN_UP.INPUT_PASSWORD_VALIDATION_LOWERCASE')) // At least one lowercase letter
      .required(t('AUTH.SIGN_UP.INPUT_PASSWORD_VALIDATION_REQUIRED')), // Required field
    passwordConfirm: yup
      .string()
      .max(50, t('AUTH.SIGN_UP.INPUT_PASSWORD_VALIDATION_MAX')) // Maximum 50 characters
      .oneOf([yup.ref('password'), null], t('AUTH.SIGN_UP.INPUT_CONFIRM_PASSWORD_VALIDATION_NOT_MATCH')) // Must match the password
      .required(t('AUTH.SIGN_UP.INPUT_PASSWORD_VALIDATION_REQUIRED')), // Required field

    marketingConsent: yup.boolean().required(t('AUTH.SIGN_UP.INPUT_LASTNAME_VALIDATION_REQUIRED')),
  });
};

export const useValidation2 = (): yup.SchemaOf<UserProfileFormValues> => {
  const { t } = useTranslation();
  return yup.object().shape({
    firstName: yup.string().required(t('AUTH.SIGN_UP.INPUT_FIRSTNAME_VALIDATION_REQUIRED')),
    lastName: yup.string().required(t('AUTH.SIGN_UP.INPUT_LASTNAME_VALIDATION_REQUIRED')),
  });
};

export const useValidation3 = (): yup.SchemaOf<{ verificationCode: string }> => {
  const { t } = useTranslation();
  return yup.object().shape({
    verificationCode: yup
      .string()
      .matches(/^\d{6}$/) // Walidacja kodu 6-cyfrowego
      .required(t('AUTH.SIGN_UP.INPUT_VERIFICATION_CODE_REQUIRED')), // Komunikat o błędzie wymaganym
  });
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { firebaseStorage } from '../../../firebase/firebase';
import { useOnAddOfferPush } from '../../App/hooks/onAddOfferPush.hook';

interface Props {
  i18nKey: string;
  linkPaths: { [key: string]: string };
}

export const LocalizedLabelWithMultipleLinks: React.FC<Props> = ({ i18nKey, linkPaths }) => {
  const { t } = useTranslation();
  const rawText = t(i18nKey);
  const components = [];

  // Użycie useOnAddOfferPush
  const onAddOfferPush = useOnAddOfferPush();

  const handleDownloadRegulamin = async () => {
    const fileRef = firebaseStorage.ref('documents/FL_Regulamin.pdf');

    try {
      const url = await fileRef.getDownloadURL();
      const response = await fetch(url);
      const blob = await response.blob();

      // Tworzenie linku do pobrania pliku
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Regulamin najmuje.com.pdf'; // Nazwa pliku po pobraniu
      link.click();
    } catch (error) {
      console.error('Error downloading file: ', error);
    }
  };

  let cursor = 0;
  rawText.replace(/<(\d+)>/g, (match, key, offset) => {
    // Tekst przed znacznikiem
    components.push(rawText.slice(cursor, offset));

    // Etykieta linku
    const linkLabel = rawText.slice(offset + match.length, rawText.indexOf(`</${key}>`, offset));

    // Generowanie odpowiedniego linku
    if (key === '11') {
      components.push(
        <a
          key={key}
          href="#"
          className="text-blue-600"
          target="_blank"
          rel="noreferrer"
          onClick={(e) => {
            handleDownloadRegulamin();
            e.stopPropagation();
          }}>
          {linkLabel}
        </a>
      );
    } else if (key === '22') {
      // Dla dynamicznego linku
      components.push(
        <a
          key={key}
          href="#"
          className="text-blue-600"
          target="_blank"
          rel="noreferrer"
          onClick={(e) => {
            e.preventDefault();
            onAddOfferPush();
          }}>
          {linkLabel}
        </a>
      );
    } else if (key === '33') {
      // Dla dynamicznego linku
      components.push(
        <a
          key={key}
          href="https://moj.gov.pl/nforms/signer/upload?xFormsAppName=SIGNER"
          className="text-blue-600"
          target="_blank"
          rel="noreferrer"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          {linkLabel}
        </a>
      );
    } else {
      // Dla normalnego linku
      components.push(
        <a key={key} href={linkPaths[key]} className="text-blue-600" target="_blank" rel="noreferrer">
          {linkLabel}
        </a>
      );
    }
    // Aktualizacja kursora
    cursor = rawText.indexOf(`</${key}>`, offset) + `</${key}>`.length;

    return match; // ten zwrot jest tu tylko dla formalności i nie jest używany
  });

  // Dodanie pozostałego tekstu
  components.push(rawText.slice(cursor));

  return <>{components}</>;
};

import { Typography } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';

import { ModalConfig, useAuth } from '../AuthContext';
import { AdditionalLink } from '../styles';

import { SignUpForm } from './components/SignUpForm';

export const SignUp: React.FC<ModalConfig> = ({ title, onSuccess }) => {
  const { showSignInModal } = useAuth();

  return (
    <>
      <SignUpForm onSuccess={onSuccess} />
      <AdditionalLink className="w-full flex justify-center items-center mt-8">
        <Trans
          i18nKey="AUTH.SIGN_UP.ALREADY_HAVE_AN_ACCOUNT"
          components={{
            link1: (
              <Typography.Link
                className="ml-1"
                onClick={(e) => {
                  e.preventDefault();
                  showSignInModal({ title, onSuccess });
                }}
              />
            ),
          }}
        />
      </AdditionalLink>
    </>
  );
};

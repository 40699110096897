import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  handleOk: () => void;
  isModalOpen: boolean;
  toggleModal: () => void;
}

export const DeleteOfferModal: React.FC<Props> = ({ handleOk, isModalOpen, toggleModal }) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('DELETE_OFFER.HEADING')}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={toggleModal}
      okText={t('CONFIRM')}
      cancelText={t('CANCEL')}>
      <p>{t('DELETE_OFFER.SUBHEADING')}</p>
      <p>{t('DELETE_OFFER.DESCRIPTION')}</p>
    </Modal>
  );
};

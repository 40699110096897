import { DeleteOutlined } from '@ant-design/icons';
import { Alert, Button, Skeleton, Space, Table, type MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { ToastVariant } from '../../../../../components/Toast/toast.model';
import { toastService } from '../../../../../components/Toast/toast.service';
import { firebaseFunctions, firestore } from '../../../../../firebase/firebase';
import { FirebaseCallableFunctions, FirestoreCollection } from '../../../../../firebase/firebase.models';
import { useGetCompanies } from '../../../../../firebase/hooks/getCompanies.hook';
import { useGetCompany } from '../../../../../firebase/hooks/getCompanyByUid.hook';
import { useGetCompanyVerificationStatus } from '../../../../../firebase/hooks/useGetCompanyVerificationStatus.hook';
import { Verification } from '../../../../../models/offers.model';
import { gtm } from '../../../../../reportGTM';
import { App, SHOW_LOGS } from '../../../../App/App';
import { useAuth } from '../../../../Auth/AuthContext';
import { LocalizedLabelWithMultipleLinks } from '../../../../OfferAdd/utils/LocalizedLabelWithMultipleLinks';
import { AppRoutes } from '../../../../Routing/routing.model';
import { MenuWrapper } from '../../../components/MenuWrapper';
import { additionalNavigation } from '../../../Menu/AdditionalNavigation';
import { MenuItems } from '../../../Menu/Menu';

import { AuthorityDragger } from './AuthorityDragger';
import { Company } from './company.models';
import { FormToLinkCompany } from './FormToLinkCompany';
import { onAddCompany } from './utils/onAddCompany';

export const LinkCompany: React.FC = () => {
  const { push } = useHistory();
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);

  const [company, loading] = useGetCompany(currentUser?.uid);
  const [isLoading, setIsLoading] = useState(false);
  const [showLinkCompany, setShowLinkCompany] = useState(false);
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [companiesData] = useGetCompanies(currentUser?.uid);
  const [showFormToLinkCompany, setShowFormToLinkCompany] = useState(false);
  const [companyVerificationStatus] = useGetCompanyVerificationStatus(currentUser?.uid);
  const [isCompanyConfirmed, setIsCompanyConfirmed] = useState<Verification | null>(null);
  const [users, setUsers] = useState<{ [key: string]: string }>({});
  const [deletingWorker, setDeletingWorker] = useState(false);
  const [deletingCompany, setDeletingCompany] = useState(false);
  const [addingCompany, setAddingCompany] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const filteredCompany = companiesData.filter(
    (company) => company.id === companyId && company.creatorId.includes(currentUser.uid)
  );

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setIsCompanyConfirmed(companyVerificationStatus);
  }, [companyVerificationStatus]);

  const transformedCompaniesData = companiesData.map((company) => ({
    ...company,
    companyNazwa: company?.company?.nazwa || 'N/A',

    users: company.users.map((user) => user),
  }));

  useEffect(() => {
    setDisabled(company?.nip?.toString()?.length > 0 || companyVerificationStatus !== Verification.INITIAL);
    setShowFormToLinkCompany(!company || companyVerificationStatus === Verification.INITIAL);
  }, [company, companyVerificationStatus]);

  useEffect(() => {
    if (!currentUser?.uid) return;
    const fetchUserVerification = async () => {
      try {
        const userDoc = await firestore.collection(FirestoreCollection.USERS).doc(currentUser.uid).get();
        const userData = userDoc.data();
        if (userData?.verification?.identity === Verification.ACCEPTED) {
          setShowLinkCompany(true);
        }
        setIsCompanyConfirmed(userData?.verification?.company ?? null);
        setCompanyId(userData?.companyId ?? null);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserVerification();
  }, [company]);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    additionalNavigation(e.key);
    push(e.key);
  };

  const handleFormFinish = async (values: Company) => {
    // ładowanie na czas dodawania firmy
    setFormSubmitting(true);
    setDisabled(true);
    try {
      await onAddCompany(currentUser?.uid, values);
      gtm('form_submit_reg_step_6');
      // Fetch the updated user data to get the companyId
      const userDoc = await firestore.collection(FirestoreCollection.USERS).doc(currentUser.uid).get();
      const userData = userDoc.data();
      setCompanyId(userData?.companyId ?? null);
      setIsCompanyConfirmed(userData?.verification?.company ?? null);
    } catch (error) {
      console.error('Error adding company:', error);
    } finally {
      setFormSubmitting(false);
    }
  };

  const handleSelectCompany = async (companyId: string) => {
    setIsLoading(true);
    try {
      const updateUserCompany = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.UPDATE_USER_COMPANY_ID);
      await updateUserCompany({ companyId });
    } catch (error) {
      toastService.show(t('CONNECT_COMPANY.ERRORS.SELECT_COMPANY'), t('ERROR'), { variant: ToastVariant.ERROR });
      console.error('Error selecting company:', error);
    }
    setDisabled(true);
    setIsLoading(false);
  };

  const handleAddCompany = () => {
    setAddingCompany(true);
    const clearCompanyDetailsInUserData = firebaseFunctions.httpsCallable(
      FirebaseCallableFunctions.CLEAR_COMPANY_DETAILS_IN_USER_DATA
    );
    clearCompanyDetailsInUserData()
      .catch((error) => {
        toastService.show(t('CONNECT_COMPANY.ERRORS.CLEAR_COMPANY_DETAILS'), t('ERROR'), {
          variant: ToastVariant.ERROR,
        });
        console.error('Error clearing company details:', error);
      })
      .finally(() => {
        setShowFormToLinkCompany(true);
        setDisabled(false);
        setAddingCompany(false);
      });
  };

  const handleDeleteWorker = (record: { uid: string; verification: Verification }) => {
    setDeletingWorker(true);
    const deleteUserFromCompany = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.DELETE_USER_FROM_COMPANY);
    deleteUserFromCompany({ uid: record.uid, companyId: companyId })
      .then(() => {
        toastService.show(t('CONNECT_COMPANY.SUCCESS.DELETE_USER'), t('SUCCESS'), { variant: ToastVariant.SUCCESS });
      })
      .catch((error) => {
        toastService.show(t('CONNECT_COMPANY.ERRORS.DELETE_USER'), t('ERROR'), { variant: ToastVariant.ERROR });
        console.error('Error deleting user:', error);
      })
      .finally(() => {
        setDeletingWorker(false);
      });
  };

  const isOwner = filteredCompany.length > 0;

  const handleDeleteCompany = () => {
    setDeletingCompany(true);
    const deleteCompany = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.DELETE_COMPANY);
    deleteCompany()
      .then(() => {
        toastService.show(t('CONNECT_COMPANY.SUCCESS.DELETE_COMPANY'), t('SUCCESS'), { variant: ToastVariant.SUCCESS });
      })
      .catch((error) => {
        switch (error.code) {
          case 'unauthenticated':
            toastService.show(t('CONNECT_COMPANY.ERRORS.UNAUTHENTICATED'), t('ERROR'), { variant: ToastVariant.ERROR });
            break;
          case 'internal':
            toastService.show(t('CONNECT_COMPANY.ERRORS.INTERNAL'), t('ERROR'), { variant: ToastVariant.ERROR });
            break;
          case 'invalid-argument':
            toastService.show(t('CONNECT_COMPANY.ERRORS.INVALID_ARGUMENT'), t('ERROR'), {
              variant: ToastVariant.ERROR,
            });
            break;
          case 'aborted':
            toastService.show(t('CONNECT_COMPANY.ERRORS.ABORTED'), t('ERROR'), {
              variant: ToastVariant.ERROR,
            });
            break;
          case 'not-found':
            toastService.show(t('CONNECT_COMPANY.ERRORS.NOT_FOUND'), t('ERROR'), { variant: ToastVariant.ERROR });
            break;
          case 'permission-denied':
            toastService.show(t('CONNECT_COMPANY.ERRORS.PERMISSION_DENIED'), t('ERROR'), {
              variant: ToastVariant.ERROR,
            });
            break;
          case 'failed-precondition':
            toastService.show(t('CONNECT_COMPANY.ERRORS.FAILED_PRECONDITION'), t('ERROR'), {
              variant: ToastVariant.ERROR,
            });
            break;
          default:
            toastService.show(t('CONNECT_COMPANY.ERRORS.UNKNOWN'), t('ERROR'), { variant: ToastVariant.ERROR });
            break;
        }
        console.error('Error deleting company:', error);
      })
      .finally(() => {
        setDeletingCompany(false);
      });
  };

  const getUser = async (uid: string) => {
    try {
      const doc = await firestore.collection(FirestoreCollection.USERS).doc(uid).get();
      const data = doc.data();
      SHOW_LOGS && console.log(data);
      if (data) {
        setUsers((prevUsers) => ({
          ...prevUsers,
          [uid]: `${data.firstName} ${data.lastName}`,
        }));
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    // Pobierz dane użytkowników, jeśli są potrzebne
    if (filteredCompany.length > 0) {
      filteredCompany[0].users.forEach((user) => getUser(user.uid));
    }
  }, [companiesData]);

  const workersColumns = [
    {
      title: t('CONNECT_COMPANY.WORKER'),
      dataIndex: 'uid',
      key: 'uid',
      render: (uid: string) => users[uid] || uid,
    },
    {
      title: t('CONNECT_COMPANY.VERIFICATION'),
      dataIndex: 'verification',
      key: 'verification',
      render: (status: Verification) => t(`VERIFICATION.${status}`),
    },
    {
      title: t('DELETE'),
      key: 'delete',
      render: (_: any, record: { uid: string; verification: Verification }) => (
        <DeleteOutlined className="flex justify-center hover:text-red-500" onClick={() => handleDeleteWorker(record)} />
      ),
    },
  ];

  const columns = [
    {
      title: t('FORMS.CONNECT_COMPANY.COMPANY_NAME'),
      dataIndex: 'companyNazwa',
      key: 'companyNazwa',
      render: (text: string) => <div className="hover:underline">{text}</div>,
    },
  ];

  return (
    <App>
      <div className="flex">
        <MenuWrapper>
          <MenuItems
            onClick={handleMenuClick}
            defaultSelectedKeys={[AppRoutes.MOJA_TABLICA_LINK_COMPANY]}
            defaultOpenKeys={['sub2', 'sub3']}
          />
        </MenuWrapper>
        {isLoading ? (
          <Space direction="vertical" style={{ margin: 50, minWidth: 600 }}>
            <Skeleton
              active
              paragraph={{
                rows: 1,
              }}
            />
            <Skeleton
              active
              className="mt-12"
              paragraph={{
                rows: 6,
              }}
            />
            <Skeleton
              active
              className="mt-12"
              paragraph={{
                rows: 2,
              }}
            />
          </Space>
        ) : (
          <Space direction="vertical" style={{ margin: 50, minWidth: 600 }}>
            {!loading && renderVerificationAlert(isCompanyConfirmed, t)}
            {showLinkCompany ? (
              <>
                {companiesData.length > 0 && (
                  <>
                    <Table
                      className="cursor-pointer"
                      columns={columns}
                      dataSource={transformedCompaniesData}
                      loading={loading}
                      rowKey="id"
                      pagination={{ pageSize: 5 }}
                      onRow={(record) => ({
                        onClick: () => {
                          handleSelectCompany(record.id);
                        },
                      })}
                    />
                    {filteredCompany.length > 0 && (
                      <>
                        <h3>{t('CONNECT_COMPANY.COMPANY_OWNER_DETAILS')}</h3>
                        {deletingWorker ? (
                          <Skeleton />
                        ) : (
                          <Table dataSource={filteredCompany[0]?.users} columns={workersColumns} rowKey="id" />
                        )}
                      </>
                    )}
                  </>
                )}
                {companiesData.length > 0 && (
                  <div className="w-full justify-between flex my-8">
                    {isOwner ? (
                      <Button onClick={handleDeleteCompany} loading={deletingCompany}>
                        {t('CONNECT_COMPANY.DELETE')}
                      </Button>
                    ) : (
                      <div style={{ display: 'none' }}></div>
                    )}
                    <Button type="primary" onClick={handleAddCompany} loading={addingCompany}>
                      {t('CONNECT_COMPANY.ADD_COMPANY')}
                    </Button>
                  </div>
                )}
                {showFormToLinkCompany && (
                  <FormToLinkCompany
                    initial={company}
                    isCompanyConfirmed={isCompanyConfirmed}
                    onFinish={handleFormFinish}
                    disabled={disabled}
                    setDisabled={setDisabled}
                    companyId={companyId}
                    formSubmitting={formSubmitting}
                  />
                )}
                <AuthorityDragger
                  show={showLinkCompany && company?.nip?.toString()?.length > 0}
                  companyId={companyId}
                  companyName={company?.nazwa}
                />
              </>
            ) : (
              !loading && (
                <Alert
                  message={t('CONNECT_COMPANY_INITIAL.HEADING')}
                  description={t('CONNECT_COMPANY_INITIAL.DESCRIPTION')}
                  type="error"
                  showIcon
                  style={{ width: 600 }}
                  action={
                    <Button type="primary" danger onClick={() => push(AppRoutes.MOJA_TABLICA_CONFIRM_IDENTITY)}>
                      {t('CONFIRM')}
                    </Button>
                  }
                />
              )
            )}
          </Space>
        )}
      </div>
    </App>
  );
};

const renderVerificationAlert = (status: Verification | null, t: any) => {
  const alertProps = {
    [Verification.ACCEPTED]: {
      message: t('CONNECT_COMPANY_ACCEPTED.HEADING'),
      description: t('CONNECT_COMPANY_ACCEPTED.DESCRIPTION'),
      type: 'success',
    },
    [Verification.REJECTED]: {
      message: t('CONNECT_COMPANY_REJECTED.HEADING'),
      description: t('CONNECT_COMPANY_REJECTED.DESCRIPTION'),
      type: 'error',
    },
    [Verification.CHECKING]: {
      message: t('CONNECT_COMPANY_CHECKING.HEADING'),
      description: (
        <LocalizedLabelWithMultipleLinks
          i18nKey="CONFIRM_CHECKING.ADDITIONAL_INFO"
          linkPaths={{ 1: '/offers', 22: '/add-offer' }}
        />
      ),
      type: 'warning',
    },
  };

  const alertConfig = alertProps[status];
  if (!alertConfig) return null;

  return (
    <Alert
      message={alertConfig.message}
      description={alertConfig.description}
      type={alertConfig.type}
      showIcon
      style={{ marginBottom: '24px' }}
    />
  );
};

// AlertMessage.tsx
import { Alert, Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ToastVariant } from '../../../../../components/Toast/toast.model';
import { Verification } from '../../../../../models/offers.model';
import { LocalizedLabelWithMultipleLinks } from '../../../../OfferAdd/utils/LocalizedLabelWithMultipleLinks';

interface AlertMessageProps {
  showEmailAlert: boolean;
  identity: Verification;
  sendVerificationEmail: () => Promise<any>;
  t: (key: string) => string;
  toastService: any;
}

export const AlertMessage: React.FC<AlertMessageProps> = ({
  showEmailAlert,
  identity,
  sendVerificationEmail,
  t,
  toastService,
}) => {
  if (showEmailAlert) {
    const { t } = useTranslation();
    return (
      <Alert
        style={{ width: '400px' }}
        message={t('CONFIRM_EMAIL_ADDRESS')}
        description={t('ACCESS_FUNCTIONALITY')}
        type="error"
        showIcon
        action={
          <Button
            type="primary"
            danger
            style={{ backgroundColor: 'red', color: 'white' }}
            onClick={() => {
              sendVerificationEmail()
                .then((response) => {
                  if (response.status === 'SUCCESS') {
                    toastService.show(t('FEATURES.AUTH.SIGN_UP.VERIFICATION_EMAIL_SENT'), 'Potwierdź email', {
                      variant: ToastVariant.SUCCESS,
                    });
                  } else {
                    toastService.show(t(response.message), t('ERROR'), { variant: ToastVariant.ERROR });
                  }
                })
                .catch((e) => {
                  toastService.show(t(e.message || t('ERROR_UNEXPECTED')), t('ERROR'), {
                    variant: ToastVariant.ERROR,
                  });
                });
            }}>
            Potwierdź
          </Button>
        }
      />
    );
  } else {
    switch (identity) {
      case Verification.INITIAL:
        return (
          <>
            <Alert
              message={t('CONFIRM_INITIAL.HEADING')}
              description={t('CONFIRM_INITIAL.DESCRIPTION')}
              type="info"
              showIcon
              className="mb-4"
            />
          </>
        );
      case Verification.ACCEPTED:
        return (
          <Alert
            message={t('CONFIRM_ACCEPTED.HEADING')}
            description={t('CONFIRM_ACCEPTED.DESCRIPTION')}
            type="success"
            showIcon
          />
        );
      case Verification.CHECKING:
        return (
          <Alert
            message={t('CONFIRM_CHECKING.HEADING')}
            description={
              <>
                <p>{t('CONFIRM_CHECKING.DESCRIPTION')}</p>
                <p>
                  <LocalizedLabelWithMultipleLinks
                    i18nKey="CONFIRM_CHECKING.ADDITIONAL_INFO"
                    linkPaths={{
                      1: '/offers', // Normalny link
                      22: '/add-offer', // Link obsługiwany przez onAddOfferPush
                    }}
                  />
                </p>
              </>
            }
            type="warning"
            showIcon
          />
        );
      case Verification.REJECTED:
        return (
          <Alert
            message={t('CONFIRM_REJECTED.HEADING')}
            description={t('CONFIRM_REJECTED.DESCRIPTION')}
            type="error"
            showIcon
          />
        );
      default:
        return null;
    }
  }
};

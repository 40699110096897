import { useEffect, useState } from 'react';

import { firebaseStorage } from '../firebase/firebase';

export function useGetOfferImages(id: string, imageNo?: number, small?: boolean): string[] {
  const [imagesUrl, setImagesUrl] = useState<string[]>();

  useEffect(() => {
    let isMounted = true;

    if (!id) return;

    (async () => {
      try {
        const listResult = (
          await firebaseStorage.ref(small ? `offers/${id}/images/small` : `offers/${id}/images`).listAll()
        ).items;

        const urls = imageNo !== undefined ? await getImage(listResult, imageNo) : await getAllImages(listResult);

        if (isMounted) {
          setImagesUrl(urls);
        }
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [id]);

  return imagesUrl;
}

async function getAllImages(listResult: any[]): Promise<string[]> {
  const images: string[] = [];

  for await (const imageRef of listResult) {
    const url = await imageRef.getDownloadURL();
    images.push(url);
  }

  return images;
}

async function getImage(listResult: any[], imageNo: number): Promise<string[]> {
  if (!listResult.length) return [];
  const url = await listResult[imageNo].getDownloadURL();
  return [url];
}

import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { Language } from './i18n.model';
import commonEN from './locales/en/common.json';
import offerCardHorizontalEN from './locales/en/components/offerCardHorizontal.json';
import commonPL from './locales/pl/common.json';
import offerCardHorizontalPL from './locales/pl/components/offerCardHorizontal.json';
// import translationEN from './locales/en/translation.json';
// import translationPL from './locales/pl/translation.json';
import accountEN from './locales/en/account.json';
import authEN from './locales/en/auth.json';
import fixedMenuEN from './locales/en/components/fixed-menu.json';
import footerEN from './locales/en/components/footer.json';
import howWorksEN from './locales/en/components/how-works.json';
import workspaceMenuEN from './locales/en/components/workspace-menu.json';
import faqEN from './locales/en/faq.json';
import formsEN from './locales/en/forms.json';
import landingPageEN from './locales/en/landing-page.json';
import linkCompanyEN from './locales/en/link-company.json';
import offerAddEN from './locales/en/offer-add.json';
import offerEN from './locales/en/offer.json';
import portfolioEN from './locales/en/portfolio.json';
import protocolEN from './locales/en/protocol.json';
import recruitmentEN from './locales/en/recruitment.json';
import supportEN from './locales/en/support.json';
import validationEN from './locales/en/validation.json';
import accountPL from './locales/pl/account.json';
import authPL from './locales/pl/auth.json';
import fixedMenuPL from './locales/pl/components/fixed-menu.json';
import footerPL from './locales/pl/components/footer.json';
import howWorksPL from './locales/pl/components/how-works.json';
import workspaceMenuPL from './locales/pl/components/workspace-menu.json';
import faqPL from './locales/pl/faq.json';
import formsPL from './locales/pl/forms.json';
import landingPagePL from './locales/pl/landing-page.json';
import linkCompanyPL from './locales/pl/link-company.json';
import offerAddPL from './locales/pl/offer-add.json';
import offerPL from './locales/pl/offer.json';
import portfolioPL from './locales/pl/portfolio.json';
import protocolPL from './locales/pl/protocol.json';
import recruitmentPL from './locales/pl/recruitment.json';
import supportPL from './locales/pl/support.json';
import validationPL from './locales/pl/validation.json';

const LOCAL_STORAGE_I18N_LANGUAGE_KEY = 'i18nextLng';

const resources = {
  [Language.EN]: {
    translation: {
      ...offerCardHorizontalEN,
      ...commonEN,
      ...fixedMenuEN,
      ...footerEN,
      ...landingPageEN,
      ...offerEN,
      ...formsEN,
      ...validationEN,
      ...howWorksEN,
      ...workspaceMenuEN,
      ...portfolioEN,
      ...accountEN,
      ...authEN,
      ...recruitmentEN,
      ...protocolEN,
      ...supportEN,
      ...offerAddEN,
      ...linkCompanyEN,
      ...faqEN,
    },
  },
  [Language.PL]: {
    translation: {
      ...offerCardHorizontalPL,
      ...commonPL,
      ...fixedMenuPL,
      ...footerPL,
      ...landingPagePL,
      ...offerPL,
      ...formsPL,
      ...validationPL,
      ...howWorksPL,
      ...workspaceMenuPL,
      ...portfolioPL,
      ...accountPL,
      ...authPL,
      ...recruitmentPL,
      ...protocolPL,
      ...supportPL,
      ...offerAddPL,
      ...linkCompanyPL,
      ...faqPL,
    },
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    resources,
    lng: localStorage.getItem(LOCAL_STORAGE_I18N_LANGUAGE_KEY) ?? Language.PL,
    fallbackLng: Language.PL,
    detection: {
      order: ['localStorage', 'navigator'],
      lookupQuerystring: 'lng',
      lookupLocalStorage: LOCAL_STORAGE_I18N_LANGUAGE_KEY,
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

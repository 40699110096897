import { useMemo } from 'react';
import { useTranslation } from 'react-i18next'; // Importing the translation hook

import { SelectOption } from '../../../../models/main.model';

export function useIsulationType(): SelectOption[] {
  const { t } = useTranslation(); // Adding translation

  return useMemo(
    () => [
      { label: t('INSULATION.STYROFOAM'), value: 'Styropian' },
      { label: t('INSULATION.WOOL'), value: 'Wełna' },
      { label: t('INSULATION.FOAM'), value: 'Piana' },
      { label: t('NONE'), value: 'Brak' },
    ],
    [t]
  );
}

import { DefaultTheme } from 'styled-components';
import { createGlobalStyle } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    textPrimary: string;
    textSecondary: string;
    textAccent: string;
    textGreen: string;
    textAccentSecondary: string;
    textHeaders: string;
    textDarkPrimary: string;
    textDarkSecondary: string;
    primary: string;
    green: string;
    backgroundGray: string;
    backgroundDarkBlue: string;
    backgroundBrokenWhite: string;
    backgroundOrange: string;

    gap: (a: number, b?: number, c?: number, d?: number) => string;

    breakpoints: {
      xsMobile: string;
      tablet: string;
      desktop: string;
      lgDesktop: string;
    };

    transition: (cssRule?: string, duration?: string, fn?: string) => string;
  }
}

export const gap = 8;

export const theme: DefaultTheme = {
  textPrimary: '#616e96',
  textSecondary: '#616e96',
  textDarkPrimary: 'rgba(255, 255, 255, 0.9)',
  textDarkSecondary: '#858b9f',
  textAccent: '#1266e3',
  textGreen: '#4caf50',
  textHeaders: '#2d3954',
  primary: '#2640a2',
  green: '#4caf50',
  textAccentSecondary: '#4e5c79',
  backgroundGray: '#eef1f9',
  backgroundDarkBlue: '#1b2132',
  backgroundBrokenWhite: '#f5faff',
  backgroundOrange: '#fff6f5',

  gap: (a = 1, b?: number, c?: number, d?: number) => {
    if (typeof d === 'number' && typeof c === 'number' && typeof b === 'number') {
      return `${a * gap}px ${b * gap}px ${c * gap}px ${d * gap}px`;
    } else if (typeof c === 'number' && typeof b === 'number') {
      return `${a * gap}px ${b * gap}px ${c * gap}px`;
    } else if (typeof b === 'number') {
      return `${a * gap}px ${b * gap}px`;
    } else {
      return `${a * gap}px`;
    }
  },

  breakpoints: {
    xsMobile: '360px',
    tablet: '768px',
    desktop: '992px',
    lgDesktop: '1020px',
  },

  transition: (cssRule = 'all', duration = '0.3', fn = 'ease') => {
    return `${cssRule} ${duration}s ${fn}`;
  },
};

export enum Breakpoints {
  XS_MOBILE = 360,
  TABLET = 768,
  DESKTOP = 992,
  LG_DESKTOP = 1020,
}

export const GlobalStyle = createGlobalStyle`
  body {
  color: ${({ theme }) => theme.textPrimary};;
  }
`;

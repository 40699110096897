import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'rsuite';
import styled from 'styled-components';

import { PanelContent, PanelWhite } from '../../../components/Layout/styles';
import { Typography } from '../../../components/Typography/Typography';

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

interface Props {
  onGoToOfferSection: () => void;
}
export const GoFurther: React.FC<Props> = ({ onGoToOfferSection }) => {
  const { t } = useTranslation();
  return (
    <PanelWhite bodyFill>
      <PanelContent>
        <Typography.H3>{t('GO_FURTHER.HEADING')}</Typography.H3>
        <Typography.H6>{t('GO_FURTHER.SUBHEADING')}</Typography.H6>
        <StyledButton appearance="primary" size={'lg'} block onClick={onGoToOfferSection}>
          {t('GO_FURTHER.VARIANTS')}
        </StyledButton>
      </PanelContent>
    </PanelWhite>
  );
};

import { LatLngBoundsLiteral } from 'leaflet';
import * as React from 'react';
import { useMemo } from 'react';
import { MapContainer, MapContainerProps, TileLayer } from 'react-leaflet';
import styled from 'styled-components';

const stylesElement = document.createElement('link');
stylesElement.setAttribute('rel', 'stylesheet');
stylesElement.setAttribute('href', 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.css');
document.getElementsByTagName('head')[0].appendChild(stylesElement);

const StyledMapContainer = styled(MapContainer)`
  height: 42vh;
`;

export const INITIAL_ZOOM = 13;
export const INITIAL_BOUNDS: LatLngBoundsLiteral = [
  [47.398254, 6.326566],
  [56.492749, 34.0231],
];

interface Props {
  mapContainer?: MapContainerProps;
  className?: string;
}
export const LeafletMap: React.FC<Props> = ({ className, children, mapContainer }) => {
  const containerProps = useMemo((): MapContainerProps => {
    if (!mapContainer) {
      return {
        bounds: INITIAL_BOUNDS,
        zoom: INITIAL_ZOOM,
        scrollWheelZoom: true,
      };
    }
  }, []);

  return (
    <StyledMapContainer {...containerProps} className={className} style={{ zIndex: 1 }}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {children}
    </StyledMapContainer>
  );
};

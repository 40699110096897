import { Dropdown, MenuProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next'; // Import i18next hook
import styled from 'styled-components';

import { Offer, OfferStatusState, PostSigningStatus } from '../../../models/offers.model';

const StyledDiv = styled.div`
  position: relative !important;
  /* top: 12px;
  right: 12px; */
  opacity: 0.9;
`;

const baseHostname = window.location.hostname;
const port = baseHostname === 'localhost' ? ':3000' : '';
const httpOrHttps = port ? 'http' : 'https';

interface Props {
  showModal: () => void;
  offer: Offer;
  state: OfferStatusState;
  duplicate: () => void;
  showDocuments: () => void;
  isLandlord: boolean;
  onRejectProtocol: () => void;
  onShowOffer: () => void;
}

export const DropdownButton: React.FC<Props> = ({
  showModal,
  offer,
  state,
  duplicate,
  showDocuments,
  isLandlord,
  onRejectProtocol,
  onShowOffer,
}) => {
  const { t } = useTranslation(); // Use the translation hook

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <a onClick={duplicate}>{t('OFFER.DROPDOWN.DUPLICATE_PROJECT')}</a>,
    },
    {
      key: '3',
      label: <a onClick={showDocuments}>{t('OFFER.DROPDOWN.DOCUMENTS')}</a>,
    },
  ];

  if (state === OfferStatusState.UNSIGNED) {
    items.push({
      key: '1.5',
      label: <a onClick={onShowOffer}>{t('OFFER.DROPDOWN.VIEW_OFFER_PREVIEW')}</a>,
    });
    items.push({
      key: '2',
      label: <a onClick={showModal}>{t('OFFER.DROPDOWN.DELETE')}</a>,
    });
  }
  if (
    !isLandlord &&
    state === OfferStatusState.SIGNED &&
    offer.offerStatus.postSigningStatus !== PostSigningStatus.PROTOCOL_CONFIRMED
  ) {
    items.push({
      key: '4',
      label: <a onClick={onRejectProtocol}>{t('OFFER.DROPDOWN.REJECT_PROTOCOL')}</a>,
    });
  }
  if (
    offer.offerStatus.postSigningStatus === PostSigningStatus.DEPOSIT_CONFIRMED ||
    offer.offerStatus.postSigningStatus === PostSigningStatus.NONE ||
    offer.offerStatus.postSigningStatus === PostSigningStatus.PROTOCOL_ATTACHED
  ) {
    items.push({
      key: '5',
      label: (
        <a href={`${httpOrHttps}://${baseHostname}${port}/protocol/${offer.id}/`}>{t('OFFER.DROPDOWN.PROTOCOL')}</a>
      ),
    });
  }

  return (
    <StyledDiv onClick={(e) => e.stopPropagation()}>
      <Dropdown menu={{ items }} placement="bottomRight" arrow>
        <a
          className="text-sm"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          {t('OFFER.DROPDOWN.MORE')}
        </a>
      </Dropdown>
    </StyledDiv>
  );
};

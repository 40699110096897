export function mergeArrays(initial: any[], initialValues: any[] | any): any[] {
  const merged = [...initial];

  console.log(initialValues);

  if (!initialValues || initialValues === 'Brak') {
    return merged;
  }

  initialValues.forEach((value) => {
    const index = merged.findIndex((item) => item.name === value.name);
    if (index !== -1) {
      merged[index] = { ...merged[index], ...value };
    } else {
      merged.push(value);
    }
  });

  return merged;
}

import { Collapse } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizedLabelWithMultipleLinks } from '../../OfferAdd/utils/LocalizedLabelWithMultipleLinks';

import { Title } from './FAQ';

const { Panel } = Collapse;

export const Account: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Zakładanie konta i logowanie */}
      <div>
        <Title>{t('FAQ.ACCOUNT.TITLE')}</Title>
        <Collapse>
          <Panel header={t('FAQ.ACCOUNT.CAN_CHANGE_EMAIL_HEADER')} key="8">
            <p>{t('FAQ.ACCOUNT.CAN_CHANGE_EMAIL_TEXT')}</p>
          </Panel>
          <Panel header={t('FAQ.ACCOUNT.RESET_PASSWORD_HEADER')} key="9">
            <p>
              <LocalizedLabelWithMultipleLinks
                i18nKey="FAQ.ACCOUNT.RESET_PASSWORD_LINK"
                linkPaths={{
                  '1': '/moja-tablica/account/security',
                }}
              />
            </p>
          </Panel>
          <Panel header={t('FAQ.ACCOUNT.DOCUMENT_VALIDATION_TIME_HEADER')} key="10">
            <p>
              {t('FAQ.ACCOUNT.DOCUMENT_VALIDATION_TIME_TEXT')}
              <LocalizedLabelWithMultipleLinks
                i18nKey="FAQ.ACCOUNT.DOCUMENT_VALIDATION_EMAIL_LINK"
                linkPaths={{
                  '1': 'mailto:kontakt@najmuje.com',
                }}
              />
            </p>
          </Panel>
          <Panel header={t('FAQ.ACCOUNT.BANK_ACCOUNT_NUMBER_HEADER')} key="11">
            <p>{t('FAQ.ACCOUNT.BANK_ACCOUNT_NUMBER_TEXT')}</p>
          </Panel>
          <Panel header={t('FAQ.ACCOUNT.SIGNED_TERMS_HEADER')} key="12">
            <p>
              <LocalizedLabelWithMultipleLinks
                i18nKey="FAQ.ACCOUNT.SIGNED_TERMS_TEXT"
                linkPaths={{
                  '1': 'https://www.gov.pl/web/profilzaufany',
                }}
              />
            </p>
          </Panel>
          <Panel header={t('FAQ.ACCOUNT.COMPANY_ACCOUNT_HEADER')} key="13">
            <p>{t('FAQ.ACCOUNT.COMPANY_ACCOUNT_TEXT')}</p>
          </Panel>
          <Panel header={t('FAQ.ACCOUNT.SIGN_TERMS_HEADER')} key="14">
            <p>
              <LocalizedLabelWithMultipleLinks
                i18nKey="FAQ.ACCOUNT.SIGN_TERMS_TEXT"
                linkPaths={{
                  '1': 'https://epuap.gov.pl',
                  '2': 'https://www.youtube.com/watch?v=YoTwOnfnRLk',
                }}
              />
            </p>
          </Panel>
        </Collapse>
      </div>
    </>
  );
};

import { Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';

import { firebaseFunctions } from '../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../firebase/firebase.models';

export const ViewEmail: React.FC<{ emailId: string; accessToken: string }> = ({ emailId, accessToken }) => {
  const [emailContent, setEmailContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchEmailContent = async () => {
      try {
        // Wywołanie funkcji chmurowej onCall
        const viewEmail = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.VIEW_EMAIL);
        const response = await viewEmail({ emailId, accessToken });

        setEmailContent(response.data.content);
      } catch (err) {
        setError('Nie udało się pobrać treści e-maila. Sprawdź poprawność linku.');
      } finally {
        setLoading(false);
      }
    };

    fetchEmailContent();
  }, [emailId, accessToken]);

  if (loading) {
    return (
      <Space
        direction="vertical"
        style={{
          width: '100%',
          height: '50vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Spin size="large" />
      </Space>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return <iframe srcDoc={emailContent} style={{ width: '100%', height: '100vh', border: 'none' }}></iframe>;
};

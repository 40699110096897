import type { MenuProps } from 'antd';
import { Space } from 'antd';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { App } from '../../../App/App';
import { useAuth } from '../../../Auth/AuthContext';
import { AppRoutes } from '../../../Routing/routing.model';
import { MenuWrapper } from '../../components/MenuWrapper';
import { additionalNavigation } from '../../Menu/AdditionalNavigation';
import { MenuItems } from '../../Menu/Menu';

import { BasicInfo } from './BasicInfo';
import { DeleteAccount } from './DeleteAccount/DeleteAccount';
import { EmailInfo } from './EmailInfo';

const Wrapper = styled.div`
  flex-wrap: wrap;
  margin-left: 20px;
  margin-top: 40px;
`;

export const AccountInfo: React.FC = () => {
  const { isAuthenticating, isAuth } = useAuth();
  return <App>{!isAuthenticating && isAuth ? <WhenAuth /> : <WhenNotAuth />}</App>;
};

const WhenAuth: React.FC = () => {
  const { push } = useHistory();
  const onClick: MenuProps['onClick'] = (e) => {
    additionalNavigation(e.key);
    push(e.key);
  };

  return (
    <div className="flex">
      <MenuWrapper>
        <MenuItems
          onClick={onClick}
          defaultSelectedKeys={[AppRoutes.MOJA_TABLICA_ACCOUNT_INFO]}
          defaultOpenKeys={['sub2']}
        />
      </MenuWrapper>
      <Space direction="vertical">
        <Wrapper>
          <BasicInfo />
        </Wrapper>
        <Wrapper>
          <EmailInfo />
        </Wrapper>
        <Wrapper>
          <DeleteAccount />
        </Wrapper>
      </Space>
    </div>
  );
};

const WhenNotAuth: React.FC = () => {
  const { showSignInModal, closeModal } = useAuth();

  useEffect(() => {
    showSignInModal();
    return () => closeModal();
  }, []);

  return <></>;
};

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input } from 'antd';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ModalConfig, useAuth } from '../../AuthContext';
import { SignInFormValues } from '../../auth.model';
import { useOnSubmit } from '../hooks/useOnSubmit.hool';

import { GoogleSignInButton } from './GoogleSignInButton';

export const SignInForm: React.FC<Pick<ModalConfig, 'onSuccess'>> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const { signInWithGoogle } = useAuth();
  const onSubmit = useOnSubmit(onSuccess);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('AUTH.SIGN_IN.INPUT_EMAIL_VALIDATION_INVALID'))
      .required(t('AUTH.SIGN_IN.INPUT_EMAIL_VALIDATION_REQUIRED')),
    password: Yup.string()
      .min(6, t('AUTH.SIGN_IN.INPUT_PASSWORD_VALIDATION_MIN'))
      .max(255, t('AUTH.SIGN_IN.INPUT_PASSWORD_VALIDATION_MAX'))
      .required(t('AUTH.SIGN_IN.INPUT_PASSWORD_VALIDATION_REQUIRED')),
  });

  const initialValues: SignInFormValues = { email: '', password: '' };

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit, handleChange, values, errors, touched, handleBlur, isSubmitting, isValid }) => (
          <Form onFinish={handleSubmit}>
            <Form.Item
              validateStatus={touched.email && errors.email ? 'error' : ''}
              help={touched.email && errors.email ? errors.email : ''}>
              <Input
                name="email"
                prefix={<UserOutlined />}
                placeholder={t('AUTH.SIGN_IN.INPUT_EMAIL_PLACEHOLDER')}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>

            <Form.Item
              validateStatus={touched.password && errors.password ? 'error' : ''}
              help={touched.password && errors.password ? errors.password : ''}>
              <Input.Password
                name="password"
                prefix={<LockOutlined />}
                placeholder={t('AUTH.SIGN_IN.INPUT_PASSWORD_PLACEHOLDER')}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>

            <Form.Item className="flex justify-center items-center ">
              <Button
                type="primary"
                htmlType="submit"
                size={'large'}
                disabled={isSubmitting || !isValid}
                className="mt-4 px-12">
                {t('AUTH.SIGN_IN.BUTTON_SUBMIT')}
              </Button>
            </Form.Item>

            <Divider plain>{t('OR')}</Divider>
          </Form>
        )}
      </Formik>
      <div className="w-full flex justify-center items-center h-full">
        <GoogleSignInButton onClick={signInWithGoogle} />
      </div>
    </>
  );
};

import { EyeOutlined } from '@ant-design/icons';
import { List, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDocumentModal } from '../../../features/Documents/DocumentModalProvider';
import { FilePaths } from '../../../features/OfferAdd/Documents/documents.models';
import { firebaseStorage } from '../../../firebase/firebase';
import { Offer } from '../../../models/offers.model';

interface Props {
  isModalOpen: boolean;
  toggleModal: () => void;
  offer: Offer;
  isLandlord: boolean;
}

export const DocumentsOfferModal: React.FC<Props> = ({ isModalOpen, toggleModal, offer, isLandlord }) => {
  const [pdfFiles, setPdfFiles] = useState<string[]>([]);
  const { showDocumentModal } = useDocumentModal();
  const { t } = useTranslation();
  const fetchPdfFiles = async () => {
    const folderRef = firebaseStorage.ref(`offers/${offer.id}`);
    const result = await folderRef.listAll();
    const pdfNames = result.items.filter((item) => item.name.endsWith('.pdf')).map((item) => item.name);

    // Ukrywanie faktury
    const filteredItems = pdfNames.filter(
      (item) => !((item === FilePaths.INVOICE_L && !isLandlord) || (item === FilePaths.INVOICE_T && isLandlord))
    );

    setPdfFiles(filteredItems);
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchPdfFiles();
    }
  }, [isModalOpen, offer.id]);

  const handleShowFile = async (fileName: string) => {
    const fileRef = firebaseStorage.ref(`offers/${offer.id}/${fileName}`);
    const downloadUrl = await fileRef.getDownloadURL();
    showDocumentModal(downloadUrl);
  };

  return (
    <Modal
      title={t('OFFER.OFFER_DOCUMENTS_HEADING')}
      open={isModalOpen}
      onOk={toggleModal}
      onCancel={toggleModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}>
      <List
        bordered
        dataSource={pdfFiles}
        renderItem={(item) => (
          <List.Item style={{ display: 'flex', justifyContent: 'space-between' }}>
            <>
              <span>{item}</span>
              <div>
                <a onClick={() => handleShowFile(item)}>
                  {
                    <EyeOutlined className="text-xl opacity-50 hover:opacity-70 hover:scale-110 transition-transform duration-300" />
                  }
                </a>
              </div>
            </>
          </List.Item>
        )}
      />
    </Modal>
  );
};

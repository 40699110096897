import firebase from 'firebase';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ToastVariant } from '../../components/Toast/toast.model';
import { toastService } from '../../components/Toast/toast.service';
import { firebaseAuth, firebaseFunctions, googleProvider } from '../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../firebase/firebase.models';
import { gtm } from '../../reportGTM';

import { AuthModal } from './AuthModal';
import { AuthDialog, ExtendedSignUpFormValues, SignInFormValues } from './auth.model';
export interface ModalConfig {
  title?: string;
  onSuccess?: () => any;
}
export interface ModalProps extends ModalConfig {
  content?: AuthDialog;
}

interface AuthContextValues {
  isAdmin: boolean;
  isAuth: boolean;
  isAuthenticating: boolean;
  currentUser: firebase.User;
  signUp: (values: ExtendedSignUpFormValues) => Promise<firebase.auth.UserCredential>;
  signIn: (values: SignInFormValues) => Promise<firebase.auth.UserCredential>;
  signInWithGoogle: () => void;
  signOut: () => Promise<void>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  sendVerificationEmail: (email?: string) => Promise<{ status: string; message: string }>;
  showSignInModal: (config?: ModalConfig) => void;
  showSignUpModal: (config?: ModalConfig) => void;
  showForgotPasswordModal: () => void;
  closeModal: () => void;
  updateInformation: (name: string) => void;
  updateEmail: (email: string) => Promise<void>;
}

const AuthContext = React.createContext<AuthContextValues>(null);
const onNewUser = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_NEW_USER);

export const AuthProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<firebase.User>();
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);
  const [dialogContent, setDialogContent] = useState<ModalProps>();
  const [showDialog, setShowDialog] = useState(false);
  const { t } = useTranslation();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  async function checkIfEmailExists(email: string): Promise<boolean> {
    const usersRef = firebase.firestore().collection('users');
    const querySnapshot = await usersRef.where('email', '==', email).get();
    return !querySnapshot.empty;
  }

  async function signUp({
    email,
    password,
    firstName,
    lastName,
    marketingConsent,
  }: ExtendedSignUpFormValues): Promise<firebase.auth.UserCredential> {
    const exists = await checkIfEmailExists(email);
    if (exists) {
      toastService.show(t('AUTH.SIGN_UP.EMAIL_ALREADY_IN_USE'), t('ERROR'), { variant: ToastVariant.ERROR });
      return null;
    }

    try {
      const userCredential = await firebaseAuth.createUserWithEmailAndPassword(email, password);
      const user = userCredential.user;

      if (user) {
        await onNewUser({
          firstName,
          lastName,
          email: user.email,
          uid: user.uid,
          marketingConsent,
        });
        gtm('sign_up', {
          method: 'Email',
        });
      }

      return userCredential;
    } catch (error) {
      console.error('Error creating user in Firebase Authentication:', error);
      toastService.show(t('AUTH.SIGN_UP.AUTH_ERROR'), t('ERROR'), { variant: ToastVariant.ERROR });
      return null;
    }
  }

  function signIn({ email, password }: SignInFormValues): Promise<firebase.auth.UserCredential> {
    return firebaseAuth.signInWithEmailAndPassword(email, password).then((v) => {
      setShowDialog(false);
      gtm('login', {
        method: 'Email',
      });
      return v;
    });
  }

  function handleSuccessfulLogin(method) {
    gtm('login', {
      method: method,
    });
  }

  function signInWithGoogle() {
    return firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then((v) => {
        if (v.additionalUserInfo.isNewUser) {
          gtm('sign_up', {
            method: 'Google',
          });
          showNonEmailSignUpModal();
        } else setShowDialog(false);
        handleSuccessfulLogin('Google');
        return v;
      });
  }

  function signOut(): Promise<void> {
    return firebaseAuth.signOut();
  }
  function sendPasswordResetEmail(email: string): Promise<void> {
    return firebaseAuth.sendPasswordResetEmail(email);
  }

  async function sendVerificationEmail(email?: string): Promise<{ status: string; message: string }> {
    showVerifyEmailModal();
    const sendEmailVerificationCode = firebaseFunctions.httpsCallable(
      FirebaseCallableFunctions.SEND_EMAIL_VERIFICATION_CODE
    );
    try {
      const response = await sendEmailVerificationCode({ email: email ? email : currentUser.email });
      return response.data;
    } catch (error: any) {
      console.error('Error sending verification email:', error);
      return { status: 'ERROR', message: error.message || 'Wystąpił błąd podczas wysyłania e-maila.' };
    }
  }

  function showSignInModal(config: ModalConfig): void {
    setDialogContent({ ...config, content: AuthDialog.SIGN_IN });
    setShowDialog(true);
  }
  function showSignUpModal(config: ModalConfig): void {
    setDialogContent({ ...config, content: AuthDialog.SIGN_UP });
    setShowDialog(true);
  }
  function showNonEmailSignUpModal(): void {
    setDialogContent({ content: AuthDialog.NON_EMAIL_SIGN_UP });
    setShowDialog(true);
  }
  function showForgotPasswordModal(): void {
    setDialogContent({ content: AuthDialog.FORGOT_PASSWORD });
    setShowDialog(true);
  }
  function showVerifyEmailModal(): void {
    setDialogContent({ content: AuthDialog.VERIFY_EMAIL, title: t('AUTH.EMAIL.CONFIRMATION_TITLE') });
    setShowDialog(true);
  }
  function closeModal(): void {
    setShowDialog(false);
  }
  function updateInformation(name: string) {
    firebase.auth().currentUser.updateProfile({ displayName: name });
  }
  function updateEmail(email: string): Promise<void> {
    return firebaseAuth.currentUser.updateEmail(email);
  }

  useEffect(() => {
    setIsAuthenticating(true);
    const unsubscribe = firebaseAuth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        const tokenResult = await user.getIdTokenResult();
        setIsAdmin(!!tokenResult.claims.admin);
      } else {
        setIsAdmin(false);
      }
      setIsAuthenticating(false);
    });
    return () => unsubscribe();
  }, []);

  const values: AuthContextValues = {
    isAdmin: isAdmin,
    isAuth: !!currentUser?.email,
    isAuthenticating,
    currentUser,
    signUp,
    signIn,
    signInWithGoogle,
    signOut,
    sendPasswordResetEmail,
    sendVerificationEmail,
    showSignInModal,
    showSignUpModal,
    showForgotPasswordModal,
    closeModal,
    updateInformation,
    updateEmail,
  };

  return (
    <AuthContext.Provider value={values}>
      {children}
      <AuthModal dialogConfig={dialogContent} showDialog={showDialog} />
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextValues => {
  return useContext(AuthContext);
};

import { Typography } from 'antd';
import * as React from 'react';
import { Trans } from 'react-i18next';

import { ModalConfig, useAuth } from '../AuthContext';
import { AdditionalLink } from '../styles';

import { SignInForm } from './component/SignInForm';

export const SignIn: React.FC<ModalConfig> = ({ onSuccess }) => {
  const { showForgotPasswordModal, showSignUpModal } = useAuth();
  return (
    <>
      <SignInForm onSuccess={onSuccess} />

      <AdditionalLink className="mt-8">
        <Trans
          i18nKey="AUTH.SIGN_IN.FORGOT_PASSWORD"
          components={{
            link1: (
              <Typography.Link
                className="text-blue-500"
                onClick={(e) => {
                  e.preventDefault();
                  showForgotPasswordModal();
                }}
              />
            ),
          }}
        />
      </AdditionalLink>
      <AdditionalLink>
        <Trans
          i18nKey="AUTH.SIGN_IN.DONT_HAVE_ACCOUNT"
          components={{
            link1: (
              <Typography.Link
                className="text-blue-500"
                onClick={(e) => {
                  e.preventDefault();
                  showSignUpModal({ onSuccess });
                }}
              />
            ),
          }}
        />
      </AdditionalLink>
    </>
  );
};

import firebase from 'firebase';
import { useEffect, useState } from 'react';

import { SHOW_LOGS } from '../../features/App/App';
import { Offer, Verification } from '../../models/offers.model';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

import { useProcessDates } from './useProcessDates.hook';

export function useGetOffersByUid(uid: string): [Offer[], Offer[], boolean, firebase.FirebaseError | null] {
  const [error, setError] = useState<firebase.FirebaseError | null>(null);
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState<Offer[]>([]);
  const [favorites, setFavorites] = useState<Offer[]>([]);

  useEffect(() => {
    setLoading(true);
    setError(null);

    const userDocRef = firestore.collection(FirestoreCollection.USERS).doc(uid);
    const unsubscribeUserDoc = userDocRef.onSnapshot(
      async (userDoc) => {
        if (!userDoc.exists) {
          console.error('User document not found');
          setLoading(false);
          return;
        }

        const userData = userDoc.data();
        if (userData?.verification?.company === Verification.ACCEPTED && userData?.companyId) {
          const companyDocRef = firestore.collection(FirestoreCollection.COMPANIES).doc(userData.companyId);
          const unsubscribeCompanyDoc = companyDocRef.onSnapshot(async (companyDoc) => {
            if (!companyDoc.exists) {
              SHOW_LOGS && console.error('Company data not found');
              setLoading(false);
              return;
            }

            const companyData = companyDoc.data();
            const favoritesId = companyData?.favorites || [];
            const companyOffers = await fetchOffersByIds(companyData?.offers || []);
            setOffers(companyOffers);
            setFavorites(await fetchOffersByIds(favoritesId));
            setLoading(false);
          });

          return () => unsubscribeCompanyDoc();
        } else {
          const offersId = userData?.offers || [];
          const favoritesId = userData?.favorites || [];
          const [fetchedOffers, fetchedFavorites] = await Promise.all([
            fetchOffersByIds(offersId),
            fetchOffersByIds(favoritesId),
          ]);
          setOffers(fetchedOffers);
          setFavorites(fetchedFavorites);
          setLoading(false);
        }
      },
      (error) => {
        console.error('Error getting user document:', error);
        setError(error as firebase.FirebaseError);
        setLoading(false);
      }
    );

    return () => unsubscribeUserDoc();
  }, [uid]);

  const fetchOffersByIds = async (ids: string[]): Promise<Offer[]> => {
    const snapshots = await Promise.all(
      ids.map((id) => firestore.collection(FirestoreCollection.OFFERS).doc(id).get())
    );
    return snapshots.map(mapOffer);
  };

  const mapOffer = (doc: firebase.firestore.DocumentSnapshot): Offer => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      ...useProcessDates({
        availability: data?.availability,
        availabilityTo: data?.availabilityTo,
        offerValidTo: data?.offerValidTo,
      }),
    } as Offer;
  };

  return [offers, favorites, loading, error];
}

import { firebaseFunctions } from '../firebase/firebase';
import { FirebaseCallableFunctions } from '../firebase/firebase.models';

export const getPlaceId = async (lat: number, lng: number): Promise<string | null> => {
  const getPlaceIdCallable = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.GET_PLACE_ID);

  try {
    const result = await getPlaceIdCallable({ lat, lng });

    return result.data.place_id;
  } catch (error) {
    console.error('Error fetching place ID:', error);
    return null;
  }
};

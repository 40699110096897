import { Alert, Space } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const InfoAboutSignature: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Space direction="vertical" size={12} style={{ marginBottom: 16 }}>
      <Alert type="info" message={t('SIGNATURE_INFO.MESSAGE')} />
    </Space>
  );
};

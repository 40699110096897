import styled from 'styled-components';

export const SlideWrapper = styled.div`
  margin: 3px;
  overflow: hidden;
`;
export const SlideImg = styled.img`
  height: 37vh;
  width: 100%;
  object-fit: cover;
  margin: 0 3px;
`;

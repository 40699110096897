import { Formik } from 'formik';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Button, DatePicker, Form, FormGroup, InputNumber, InputPicker, SelectPicker } from 'rsuite';
import styled from 'styled-components';

import { FormInput } from '../../../components/FormInputs/FormInput';
import { PageHeadingPanel } from '../../../components/Layout/styles';
import { LeafletMap } from '../../../components/LeafletMap/LeafletMap';
import { LeafletEsriGeocodeByClick } from '../../../components/LeafletMap/components/LeafletEsriGeocodeByClick';
import { MapMarkers } from '../../../components/LeafletMap/components/MapMarkers';
import { GeocodeSearchItem } from '../../../components/LeafletMap/leaflet.model';
import { Offer, SearchFormFilters } from '../../../models/offers.model';
import { gtm } from '../../../reportGTM';
import { usePremisesPurposeSelectOptions } from '../../OfferAdd/components/FormOptions/usePremisesPurposeSelectOptions.hook';
import { useProvinceOptions } from '../../OfferAdd/components/FormOptions/useProvince.hook';
import { InputsBox } from '../../OfferAdd/components/OfferInputs';

const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.backgroundBrokenWhite};
  padding-bottom: 80px;
`;
const LocalisationSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 150px;
  grid-gap: 16px;
`;

const FormFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  onSearchOffers: (values: any) => any;
  isSearchPending: boolean;
  offers: Offer[];
  selectedOfferId: string;
  initialValues: SearchFormFilters; // Nowy prop do przekazania wartości z URL
}

export const OffersForm: React.FC<Props> = ({
  onSearchOffers,
  isSearchPending,
  offers,
  selectedOfferId,
  initialValues,
}) => {
  const [, setCookie] = useCookies(['userFilters']);
  const domNodeRef = useRef<HTMLDivElement>(null);
  const [domNode, setDomNode] = useState<HTMLDivElement>();
  const premisesPurposeOptions = usePremisesPurposeSelectOptions();
  const provinceOptions = useProvinceOptions();
  const [searchResult, setSearchResult] = useState<GeocodeSearchItem>();
  const { t } = useTranslation();

  useEffect(() => {
    domNodeRef.current && setDomNode(domNodeRef.current);
  }, [domNodeRef.current]);

  // const handleSearch = (values: SearchFormFilters) => {
  //   const encodedFilters = encodeURIComponent(JSON.stringify(values));
  //   setCookie('userFilters', encodedFilters, { path: '/' });
  //   onSearchOffers(values); // Wywołanie funkcji wyszukiwania z wartościami formularza
  //   Object.keys(values).forEach((key) => {
  //     // const contentType = values[key] || 'Unknown content';
  //     const contentType = `${key}: ${values[key] || 'Unknown content'}`;
  //     gtm('select_content', { content_type: contentType });
  //   });
  // };

  const handleSearch = (values: SearchFormFilters) => {
    const processedValues: SearchFormFilters = { ...values };
    if (processedValues.freeFrom instanceof Date) {
      const formattedDate = processedValues.freeFrom.toISOString().split('T')[0];
      const encodedFilters = encodeURIComponent(JSON.stringify({ ...processedValues, freeFrom: formattedDate }));
      setCookie('userFilters', encodedFilters, { path: '/' });
      onSearchOffers(processedValues);
    } else {
      const encodedFilters = encodeURIComponent(JSON.stringify(processedValues));
      setCookie('userFilters', encodedFilters, { path: '/' });
      onSearchOffers(processedValues);
    }

    Object.keys(processedValues).forEach((key) => {
      const contentType = `${key}: ${processedValues[key] || 'Unknown content'}`;
      gtm('select_content', { content_type: contentType });
    });
  };

  console.log(initialValues);

  useEffect(() => {
    const encodedFilters = encodeURIComponent(JSON.stringify(initialValues));
    setCookie('userFilters', encodedFilters, { path: '/' });
  }, [initialValues]);

  return (
    <Wrapper>
      <LeafletMap>
        <LeafletEsriGeocodeByClick
          domNode={domNode}
          searchResult={searchResult}
          setSearchResult={setSearchResult}
          initialAddress={''}
        />
        {offers && <MapMarkers offerData={offers} selectedOfferId={selectedOfferId} />}
      </LeafletMap>

      <PageHeadingPanel shaded shorten={!!offers}>
        <Formik
          key={JSON.stringify(initialValues)} // Klucz, który zmienia się przy zmianie initialValues
          initialValues={initialValues}
          onSubmit={handleSearch}>
          {({ submitForm }) => (
            <Form onSubmit={onSearchOffers}>
              <LocalisationSection>{/* Możliwe dodatkowe pola */}</LocalisationSection>
              <InputsBox style={{ marginTop: '8px' }}>
                <FormInput
                  label={t('ADDRESS.PROVINCE')}
                  name={'province'}
                  inputAccepter={SelectPicker}
                  data={provinceOptions}
                />
                <FormInput label={t('ADDRESS.COUNTY')} name={'county'} />
                <FormInput label={t('ADDRESS.DISTRICT')} name={'district'} />
              </InputsBox>
              <InputsBox>
                <FormInput label={t('ADDRESS.ESTATE')} name={'estate'} />
                <FormInput label={t('ADDRESS.STREET')} name={'street'} />
                <FormInput
                  name={'maxPrice'}
                  inputAccepter={InputNumber}
                  label={t('FORMS.OFFER_SEARCH.MAX_RENT')}
                  postfix="PLN"
                  step={100}
                  min={1}
                />
              </InputsBox>
              <InputsBox>
                <FormInput
                  name={'premisesPurpose'}
                  inputAccepter={InputPicker}
                  label={t('FORMS.OFFER_SEARCH.PURPOSE_OF_PREMISES')}
                  data={premisesPurposeOptions}
                />
                <FormInput
                  name={'freeFrom'}
                  inputAccepter={DatePicker}
                  label={t('FORMS.OFFER_SEARCH.AVAILABLE_FROM')}
                  isoWeek={true}
                />
                <FormInput
                  name={'minArea'}
                  inputAccepter={InputNumber}
                  label={t('FORMS.OFFER_SEARCH.MIN_PREMISES_AREA')}
                  postfix="m²"
                  step={10}
                  min={0}
                />
              </InputsBox>
              <FormGroup>
                <FormFooterWrapper style={{ float: 'right' }}>
                  <Button
                    appearance="primary"
                    loading={isSearchPending}
                    onClick={submitForm}
                    style={{ marginBottom: 16 }}>
                    {t('FORMS.OFFER_SEARCH.SEARCH')}
                  </Button>
                </FormFooterWrapper>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </PageHeadingPanel>
    </Wrapper>
  );
};

import { Modal } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

import { AppRoutes } from '../../features/Routing/routing.model';

interface Props {
  isIdentityModalOpen: boolean;
}

export const IdentityModal: React.FC<Props> = ({ isIdentityModalOpen }) => {
  const { push } = useHistory();

  const handleIdentityOk = () => {
    push(AppRoutes.MOJA_TABLICA_CONFIRM_IDENTITY);
  };
  return (
    <Modal
      title="Twoje konto ma niepotwierdzoną tożsamość"
      open={isIdentityModalOpen}
      onOk={handleIdentityOk}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={false}>
      <p>Zanim wystawisz ofertę musisz potwierdzić swoją tożsamość</p>
    </Modal>
  );
};

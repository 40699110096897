import { ReactChild, ReactNode } from 'react';

export enum ToastVariant {
  SUCCESS = 'success',
  ERROR = 'error',
  WARN = 'warning',
  INFO = 'info',
  OPEN = 'open',
}

export type ToastContentType = ReactChild | ReactNode | (() => void) | string | null;

export interface ToastConfig {
  variant?: ToastVariant;
  duration?: number;
}

export interface ToastEntity {
  content: ToastContentType;
  config: ToastConfig;
}

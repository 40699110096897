import { Alert, Space } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Loader } from 'rsuite';

import { CompanyModal } from '../../components/Alerts/CompanyModal';
import { IdentityModal } from '../../components/Alerts/IdentityModal';
import { ToastVariant } from '../../components/Toast/toast.model';
import { toastService } from '../../components/Toast/toast.service';
import { useGetOffer } from '../../firebase/hooks/getOfferById.hook';
import { useGetProjectOffer } from '../../firebase/hooks/getProjectOfferById.hook';
import { Offer, OfferStatusState } from '../../models/offers.model';
import { App } from '../App/App';
import { useAuth } from '../Auth/AuthContext';
import { AppRoutes } from '../Routing/routing.model';

import { HowItWorks } from './components/HowItWorks';
import { OfferForm } from './components/OfferForm';
import { handleOnOfferAdd } from './hooks/handleOnOfferAdd.hook';
import useSessionTracker from './hooks/useSessionTracker.hook';
import { ImageVerificationModal } from './ImageVerificationModal';

interface Props {
  offerId: string;
}

interface PropsWhenAuth {
  offerId: string;
  initial: Offer;
  error: string;
  currentUser: any;
}

export const OfferAdd: React.FC<Props> = ({ offerId }) => {
  const { isAuthenticating, isAuth, currentUser } = useAuth();
  const { offer, error } = useGetProjectOffer(offerId);
  useSessionTracker(offer?.id);

  return (
    <App>
      {!isAuthenticating && isAuth && (
        <WhenAuth offerId={offerId} initial={offer} error={error} currentUser={currentUser} />
      )}
      {isAuthenticating && !isAuth && <WhenNotAuth />}
    </App>
  );
};

const WhenAuth: React.FC<PropsWhenAuth> = ({ offerId, initial, error, currentUser }) => {
  const { push } = useHistory();
  const [loading, setLoading] = useState<boolean>();
  const [isIdentityModalOpen, setIsIdentityModalOpen] = useState(false);
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [offer] = useGetOffer(offerId);
  const [imagesVerificationResults, setImagesVerificationResults] = useState<string | null>(null);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const { t } = useTranslation();

  const onOfferAdd = async (e: React.FormEvent) => {
    e.preventDefault();
    const { success, description } = await handleOnOfferAdd(
      currentUser,
      offer,
      setIsIdentityModalOpen,
      setIsCompanyModalOpen,
      setLoading,
      setImagesVerificationResults,
      push
    );

    toastService.show(description, success ? t('SUCCESS') : t('ERROR_DESCRIPTION'), {
      variant: success ? ToastVariant.SUCCESS : ToastVariant.ERROR,
    });

    if (imagesVerificationResults) {
      setShowVerificationModal(true);
    }
  };

  const handleCloseModal = () => setShowVerificationModal(false);

  if (error) {
    return (
      <>
        <Space direction="vertical" style={{ width: '100%', marginTop: '100px', marginBottom: '60%', padding: '50px' }}>
          <Alert showIcon message={t('NO_ACCESS_TO_FORM')} description={error} type="error" />
        </Space>
      </>
    );
  } else if (initial === undefined) {
    return (
      <>
        <HowItWorks />
        <Loader
          style={{ position: 'absolute', top: '108%', left: '50%', transform: 'translate(-50%, -50%)' }}
          size="md"
          center
          content="loading..."
        />
      </>
    );
  } else if (offer.offerStatus.state !== OfferStatusState.UNSIGNED) {
    return <WhenOfferSigned />;
  } else {
    return (
      <>
        <OfferForm offerId={offerId} initial={initial} onAddOffers={onOfferAdd} isAddingPending={loading} />
        <IdentityModal isIdentityModalOpen={isIdentityModalOpen} />
        <CompanyModal isCompanyModalOpen={isCompanyModalOpen} />
        <ImageVerificationModal
          show={showVerificationModal}
          onClose={handleCloseModal}
          verificationResults={imagesVerificationResults}
          onChangeImage={() => push(AppRoutes.ADD_OFFER.replace(':id', offer.id))}
        />
        <HowItWorks />
      </>
    );
  }
};

const WhenNotAuth: React.FC = () => {
  const { showSignInModal, closeModal } = useAuth();

  useEffect(() => {
    showSignInModal();
    return () => closeModal();
  }, []);

  return <></>;
};

const WhenOfferSigned: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Space direction="vertical" style={{ width: '100%', marginTop: '100px', marginBottom: '60%', padding: '50px' }}>
        <Alert showIcon message={t('ERROR')} description={t('OFFER_NOT_EDITABLE')} type="error" />
      </Space>
    </>
  );
};

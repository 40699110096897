import * as React from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';

const SliderContainer = styled.div`
  width: 100%;

  @media (max-width: 900px) {
    width: 90%;
  }
`;

const defaultSettings: Settings = {
  // autoplay: true,
  className: 'center',
  centerMode: true,
  infinite: true,
  centerPadding: '160px',
  slidesToShow: 2,
  speed: 500,
};

interface Props {
  settings?: Settings;
  className?: string;
}
export const SlickCarousel: React.FC<Props> = ({ className, settings, children }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <SliderContainer>
        <Slider className={className} {...(settings ?? defaultSettings)}>
          {children}
        </Slider>
      </SliderContainer>
    </div>
  );
};

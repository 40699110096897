import { useHistory, useLocation } from 'react-router';
import { useCallback } from 'react';

import { AppRoutes } from '../../Routing/routing.model';

function generateRandomString(length = 20) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useOnAddOfferPush = () => {
  const { push } = useHistory();
  const randomString = generateRandomString();
  const { pathname } = useLocation();

  return useCallback(() => {
    // jeśli jesteś już na /add-offer/ to nowy formularz otwórz w nowej karcie
    if (pathname.indexOf(AppRoutes.ADD_OFFER.replace('/:id', '')) > -1) {
      window.open(`/add-offer/${generateRandomString()}`, '_blank');
    }

    push(AppRoutes.ADD_OFFER.replace(':id', randomString));
  }, [push]);
};

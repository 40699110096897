export enum ItemListTypes {
  SEARCH_OFFERS = 1,
  PROJECTS = 2,
  OFFERS = 3,
  CONTRACTS = 4,
  FAVORITES = 5,
  RESERVED = 6,
  INTERESTING_OFFERS = 7,
  WAREHOUSES_NEAR_CITY = 8,
  BUSINESS_SPACE = 9,
  FIND_OFFICE = 10,
}

export const ItemListTypeNames: { [key in keyof typeof ItemListTypes]: string } = {
  SEARCH_OFFERS: 'Wyszukiwarka ofert',
  PROJECTS: 'MT - Projekty',
  OFFERS: 'MT - Oferty',
  CONTRACTS: 'MT - Umowy',
  FAVORITES: 'MT - Polubione',
  RESERVED: 'MT - Zarezerwowane',
  INTERESTING_OFFERS: 'LP - Interesujące oferty',
  WAREHOUSES_NEAR_CITY: 'LP - Magazynowo-przemysłowe',
  BUSINESS_SPACE: 'LP - Handlowo-usługowe',
  FIND_OFFICE: 'LP - Biurowe',
};

import type { MenuProps } from 'antd';
import { Alert, Button, Space } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

import { toastService } from '../../../../components/Toast/toast.service';
import { App } from '../../../App/App';
import { useAuth } from '../../../Auth/AuthContext';
import { AppRoutes } from '../../../Routing/routing.model';
import { additionalNavigation } from '../../Menu/AdditionalNavigation';
import { MenuItems } from '../../Menu/Menu';
import { MenuWrapper } from '../../components/MenuWrapper';

export const Security: React.FC = () => {
  const { push } = useHistory();
  const onClick: MenuProps['onClick'] = (e) => {
    additionalNavigation(e.key);
    push(e.key);
  };

  const { sendPasswordResetEmail, currentUser } = useAuth();

  return (
    <App>
      <MenuWrapper>
        <MenuItems
          onClick={onClick}
          defaultSelectedKeys={[AppRoutes.MOJA_TABLICA_SECURITY]}
          defaultOpenKeys={['sub2']}
        />
      </MenuWrapper>
      <Space direction="vertical" style={{ margin: 50 }}>
        <Alert
          message="Zmiana hasła"
          description="Po kliknięciu w przycisk zostanie wysłany na twój e-mail adres link do zmiany hasła"
          type="info"
          showIcon
        />
        <Button
          type="primary"
          style={{ marginTop: 25 }}
          onClick={() => {
            sendPasswordResetEmail(currentUser.email);
            toastService.show('Wysłano email z linkiem do zmiany hasła', 'success');
          }}>
          Zmień hasło
        </Button>
      </Space>
    </App>
  );
};
